import { FC, useEffect } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import Select2 from "../../../../../hope-ui/components/select-2";
import TextareaComponent from "../../../../../hope-ui/components/textarea-input";
import { useQueryResponse as useQueryResponseKelompokBInggris } from "../../../../../provider/query-response/absensi-b-inggris/kelompok-b-inggris/kelola-kelompok";
import { useQueryResponse as useQueryResponseAnggotaKelompokBInggris } from "../../../../../provider/query-response/absensi-b-inggris/kelompok-b-inggris/kelola-anggota-kelompok";
import { useAuth } from "../../../../../provider/core/auth";
import {
  useQueryResponse,
  useQueryResponsePagination,
} from "../../../../../provider/query-response/absensi-b-inggris/kelompok-b-inggris/pindah-tutor";
import { useQueryRequest } from "../../../../../provider/query-request/absensi-b-inggris/kelompok-b-inggris/pindah-tutor";
import { DataTable } from "../../../../../hope-ui/components/table/DataTable";
import { Columns } from "./list/columns/column";
import Toolbar from "./toolbar";
import FetchLoading from "../../../../../hope-ui/components/fetch-loading";

type Props = {
  activeTab: number;
};

const PindahTutor: FC<Props> = ({ activeTab }) => {
  const { currentUser } = useAuth();
  const {
    isLoadingPostPindahTutorBInggris,
    formikPindahTutorBInggris,
    setSubmitting,
    submitting,
    handleGetTutorBInggris,
    isLoadingGetTutorBInggris,
    allTutorBInggris,
    allKelompokTutorBInggrisTujuan,
    setAllKelompokTutorBInggrisTujuan,
    handleGetAllKelompokBInggrisTujuan,
    isLoadingGetAllKelompokBInggrisTujuan,
    isLoadingFetchData,
    listData,
  } = useQueryResponse();

  const {
    allKelompokBInggris,
    handleGetAllKelompokBInggris,
    isLoadingGetAllKelompokBInggris,
    handleGetAnggotaKelompokBInggris,
    isLoadingGetAnggotaKelompokBInggris,
    anggotaKelompokBInggris,
  } = useQueryResponseAnggotaKelompokBInggris();

  useEffect(() => {
    if (
      currentUser &&
      currentUser.data &&
      activeTab === 3 &&
      handleGetAllKelompokBInggris &&
      handleGetTutorBInggris
    ) {
      const nik: any = currentUser.data.username;
      handleGetAllKelompokBInggris(nik);
      handleGetTutorBInggris();
    }
  }, [
    activeTab,
    handleGetAllKelompokBInggris,
    handleGetTutorBInggris,
    currentUser,
  ]);

  let optionsKelompokBInggris: any = allKelompokBInggris.map((val) => {
    return {
      value: val.id,
      label: val.nama_kelompok_b_inggris,
    };
  });

  let optionsAnggotaKelompokBInggris: any = anggotaKelompokBInggris?.map(
    (val) => {
      return {
        value: val.id,
        label: `${val.nik_gtk} - ${val.gtk.nama_gtk}`,
      };
    }
  );

  let optionsTutorBInggris: any = allTutorBInggris.map((val) => {
    return {
      value: val.nik_gtk,
      label: `${val.nik_gtk} - ${val.nama_gtk}`,
    };
  });

  let optionsKelompokTutorBInggrisTujuan: any =
    allKelompokTutorBInggrisTujuan.map((val) => {
      return {
        value: val.id,
        label: `${val.nama_kelompok_b_inggris}`,
      };
    });

  return (
    <Col
      style={{
        display: activeTab === 3 ? "block" : "none",
      }}
      className="p-4"
    >
      {isLoadingPostPindahTutorBInggris && <FetchLoading />}
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          setSubmitting(true);
          formikPindahTutorBInggris?.submitForm();
        }}
      >
        <Row className="gy-4 mb-4">
          <h6>Pilih Anggota Kelompok</h6>
          <hr className="bg-light mb-0" />
          <Col lg="6">
            <Form.Group>
              <Select2
                isRequired
                label="Kelompok EMC"
                placeholder="Pilih Kelompok EMC"
                options={optionsKelompokBInggris}
                isLoading={isLoadingGetAllKelompokBInggris}
                onChange={(e: any) => {
                  const id_kelompok_b_inggris = e?.value;
                  if (handleGetAnggotaKelompokBInggris) {
                    handleGetAnggotaKelompokBInggris(id_kelompok_b_inggris);
                    formikPindahTutorBInggris?.setFieldValue(
                      "id_anggota_kelompok_b_inggris",
                      null
                    );
                  }
                  formikPindahTutorBInggris?.setFieldValue(
                    "id_kelompok_b_inggris",
                    id_kelompok_b_inggris
                  );
                }}
                value={
                  formikPindahTutorBInggris?.getFieldProps(
                    "id_kelompok_b_inggris"
                  ).value ?? null
                }
                isClearable
              />
              {submitting &&
                formikPindahTutorBInggris?.errors.id_kelompok_b_inggris && (
                  <div className="text-danger mt-2">
                    {formikPindahTutorBInggris?.errors.id_kelompok_b_inggris}
                  </div>
                )}
            </Form.Group>
          </Col>

          <Col lg="6">
            <Form.Group>
              <Select2
                label="Anggota Kelompok EMC"
                placeholder="Pilih Anggota Kelompok EMC"
                isRequired
                isLoading={isLoadingGetAnggotaKelompokBInggris}
                options={optionsAnggotaKelompokBInggris}
                onChange={(e: any) => {
                  const id_anggota_kelompok_b_inggris: any = e?.value;
                  formikPindahTutorBInggris?.setFieldValue(
                    "id_anggota_kelompok_b_inggris",
                    id_anggota_kelompok_b_inggris
                  );
                }}
                value={
                  formikPindahTutorBInggris?.getFieldProps(
                    "id_anggota_kelompok_b_inggris"
                  ).value ?? null
                }
              />
              {submitting &&
                formikPindahTutorBInggris?.errors
                  .id_anggota_kelompok_b_inggris && (
                  <div className="text-danger mt-2">
                    {
                      formikPindahTutorBInggris?.errors
                        .id_anggota_kelompok_b_inggris
                    }
                  </div>
                )}
            </Form.Group>
          </Col>

          <h6 className="mt-5">Tutor & Kelompok EMC Tujuan</h6>
          <hr className="bg-light mb-0" />

          <Col lg="6">
            <Form.Group>
              <Select2
                label="Tutor EMC Tujuan"
                placeholder="Pilih Tutor EMC Tujuan"
                isRequired
                isLoading={isLoadingGetTutorBInggris}
                onChange={(e: any) => {
                  const nik_gtk = e?.value;
                  if (handleGetAllKelompokBInggrisTujuan) {
                    handleGetAllKelompokBInggrisTujuan(nik_gtk, "tujuan");
                  }
                  formikPindahTutorBInggris?.setFieldValue(
                    "nik_tutor_b_inggris_tujuan",
                    nik_gtk
                  );
                }}
                value={
                  formikPindahTutorBInggris?.getFieldProps(
                    "nik_tutor_b_inggris_tujuan"
                  ).value ?? null
                }
                options={optionsTutorBInggris}
              />
            </Form.Group>
            {submitting &&
              formikPindahTutorBInggris?.errors.nik_tutor_b_inggris_tujuan && (
                <div className="text-danger mt-2">
                  {formikPindahTutorBInggris?.errors.nik_tutor_b_inggris_tujuan}
                </div>
              )}
          </Col>

          <Col lg="6">
            <Form.Group>
              <Select2
                label="Kelompok EMC Tujuan"
                placeholder="Pilih Kelompok EMC Tujuan"
                isRequired
                options={optionsKelompokTutorBInggrisTujuan}
                isLoading={isLoadingGetAllKelompokBInggrisTujuan}
                onChange={(e: any) => {
                  const id_kelompok_b_inggris_tujuan: any = e?.value;
                  formikPindahTutorBInggris?.setFieldValue(
                    "id_kelompok_b_inggris_tujuan",
                    id_kelompok_b_inggris_tujuan
                  );
                }}
                value={
                  formikPindahTutorBInggris?.getFieldProps(
                    "id_kelompok_b_inggris_tujuan"
                  ).value ?? null
                }
              />
            </Form.Group>
            {submitting &&
              formikPindahTutorBInggris?.errors
                .id_kelompok_b_inggris_tujuan && (
                <div className="text-danger mt-2">
                  {
                    formikPindahTutorBInggris?.errors
                      .id_kelompok_b_inggris_tujuan
                  }
                </div>
              )}
          </Col>

          <Col lg="12">
            <Form.Group>
              <TextareaComponent
                label="Keterangan"
                placeholder="Masukkan Keterangan"
                rows={3}
                value={
                  formikPindahTutorBInggris?.getFieldProps(`keterangan`)
                    .value ?? ""
                }
                formik={formikPindahTutorBInggris?.getFieldProps(`keterangan`)}
              />
            </Form.Group>
          </Col>
        </Row>

        <Card.Footer className={`p-3 d-flex flex-row justify-content-end`}>
          <button type="submit" className={`btn btn-primary btn-sm `}>
            <i className={`far fa-floppy-disk me-2`}></i>
            Simpan
          </button>
        </Card.Footer>

        <Col className="mt-4">
          <h6>History Pindah Tutor EMC</h6>
          <hr className="bg-light" />
          <Toolbar />
          <DataTable
            isLoading={isLoadingFetchData}
            data={listData?.data}
            header={Columns}
            pagination={useQueryResponsePagination}
            queryRequest={useQueryRequest}
          />
        </Col>
      </Form>
    </Col>
  );
};

export default PindahTutor;
