import { Dispatch, SetStateAction } from "react";
import { Response } from "../../global";
import { FormAnggotaKelompokBInggrisPayload } from "../anggota-kelompok-b-inggris/_queryResponse";
import { FormikProps } from "formik";
import { FormSpeakingFocusPayload } from "../../speaking-focus/_queryResponse";

export type FormAbsensiBInggrisPayload = {
  id: number | null;
  id_absensi_b_inggris: number | null;
  id_riwayat_b_inggris: string | null;
  nik_gtk: string | null;
  status_absen: string | null;
  keterangan: string | null;
};

export type FormRiwayatBInggrisPayload = {
  id: number | null;
  riwayat_tanggal: Date | null;
  tempat: string | null;
  nik_gtk_tutor: string | null;
  id_kelompok_b_inggris: number | null;
  kehadiran_b_inggris: Array<FormAbsensiBInggrisPayload>;
  id_speaking_focus: number | null;
};

export type QueryResponseContextProps<T> = {
  query?: string;
  listData?: Response<Array<T>>;
  isLoadingFetchData: boolean;
  formikBuatAbsensi?: FormikProps<FormRiwayatBInggrisPayload>;
  formikBuatAbsensiValidationSchema?: any;
  currentOffset: number;
  setCurrentOffset: Dispatch<SetStateAction<number>>;
  submitting: boolean;
  setSubmitting: Dispatch<SetStateAction<boolean>>;
  handlePostBuatAbsensiBInggris?: (payload: FormData) => void;
  isLoadingPostBuatAbsensiBInggris: boolean;
  resetForm: boolean;
  setResetForm: Dispatch<SetStateAction<boolean>>;
  handleGetDetailAbsensiBInggris: (id: string) => void;
  isLoadingGetDetailAbsensiBInggris: boolean;
  detailAbsensiBInggris: FormRiwayatBInggrisPayload;
  setDetailAbsensiBInggris: Dispatch<
    SetStateAction<FormRiwayatBInggrisPayload>
  >;
  handleGetTotalInfaq?: (id_kelompok_b_inggris: string) => void;
  isLoadingGetTotalInfaq: boolean;
  totalInfaq: number;
  setTotalInfaq: Dispatch<SetStateAction<number>>;
  handleGetAllSpeakingFocus: () => void;
  isLoadingGetAllSpeakingFocus: boolean;
  allSpeakingFocus: Array<FormSpeakingFocusPayload>;
  setAllSpeakingFocus: Dispatch<
    SetStateAction<Array<FormSpeakingFocusPayload>>
  >;
};

export const initialQueryResponse: QueryResponseContextProps<any> = {
  isLoadingFetchData: false,
  currentOffset: 0,
  setCurrentOffset: () => {},
  submitting: false,
  setSubmitting: () => {},
  isLoadingPostBuatAbsensiBInggris: false,
  resetForm: false,
  setResetForm: () => {},
  handleGetDetailAbsensiBInggris: () => {},
  isLoadingGetDetailAbsensiBInggris: false,
  detailAbsensiBInggris: {
    id: null,
    riwayat_tanggal: null,
    tempat: "",
    id_speaking_focus: null,
    nik_gtk_tutor: "",
    id_kelompok_b_inggris: null,
    kehadiran_b_inggris: [],
  },
  setDetailAbsensiBInggris: () => {},
  isLoadingGetTotalInfaq: false,
  totalInfaq: 0,
  setTotalInfaq: () => {},
  handleGetAllSpeakingFocus: () => {},
  isLoadingGetAllSpeakingFocus: false,
  allSpeakingFocus: [],
  setAllSpeakingFocus: () => {},
};
