import { Dispatch, SetStateAction } from "react";
import { Response } from "../../global";

export type QueryResponseContextProps<T> = {
  query?: string;
  listData?: Response<Array<T>>;
  refetchListData?: () => void;
  isLoadingFetchData: boolean;
  bulan?: number | string | null;
  setBulan: Dispatch<SetStateAction<number | string | null>>;
  tahun?: number | null;
  setTahun: Dispatch<SetStateAction<number | null>>;
  idKelompokBInggris?: Array<any> | null;
  setIdKelompokBInggris: Dispatch<SetStateAction<Array<any>>>;
  handleDeleteAbsensiBInggris?: (id: string) => void;
  isLoadingDeleteAbsensiBInggris: boolean;
};

export const initialQueryResponse: QueryResponseContextProps<any> = {
  isLoadingFetchData: false,
  setBulan: () => {},
  setTahun: () => {},
  setIdKelompokBInggris: () => {},
  isLoadingDeleteAbsensiBInggris: false,
};
