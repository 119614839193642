import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { WithChildren } from "../../../../../helpers/react18MigrationHelpers";
import { useQueryRequest } from "../../../../query-request/absensi-b-inggris/kelompok-b-inggris/pindah-tutor";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { stringifyRequestQuery } from "../../../../../helpers/helpers";
import { QUERIES } from "../../../../../helpers/crud-helpers/const";
import { initialQueryState } from "../../../../../models/global";
import { SweetAlert } from "../../../../../hope-ui/components/sweet-alert";

import {
  FormPindahTutorBInggrisPayload,
  TutorBInggris,
  initialQueryResponse,
} from "../../../../../models/absensi-b-inggris/pindah-tutor/_queryResponse";
import {
  getAllKelompokBInggris,
  getPindahTutorBInggris,
  getTutorBInggris,
  postPindahTutorBInggris,
} from "../../../../../api/absensi-b-inggris/_request";

import { useQueryResponse as useQueryResponseAbsensiBInggris } from "../../../absensi-b-inggris";
import { useAuth } from "../../../../core/auth";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FormKelompokBInggrisPayload } from "../../../../../models/absensi-b-inggris/kelompok-b-inggris/_queryResponse";
import { useQueryResponse as useQueryResponseAnggotaKelompok } from "../kelola-anggota-kelompok";

const QueryResponseContext = createContext(initialQueryResponse);

const QueryResponseProvider: FC<WithChildren> = ({ children }) => {
  const refetchListDataAnggotaKelompok =
    useQueryResponseAnggotaKelompok().refetchListData;
  const { state } = useQueryRequest();
  state.col_header = `["nik_gtk", "nik_tutor_lama", "nik_tutor_tujuan"]`;
  const { activeTab } = useQueryResponseAbsensiBInggris();
  const { currentUser } = useAuth();
  const queryClient = useQueryClient();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [allTutorBInggris, setAllTutorBInggris] = useState<
    Array<TutorBInggris>
  >(initialQueryResponse.allTutorBInggris);

  const [allKelompokTutorBInggrisTujuan, setAllKelompokTutorBInggrisTujuan] =
    useState<Array<FormKelompokBInggrisPayload>>(
      initialQueryResponse.allKelompokTutorBInggrisTujuan
    );

  const nik_tutor_b_inggris_lama: any = currentUser?.data?.username;

  const [resetForm, setResetForm] = useState<boolean>(false);

  const {
    mutateAsync: PostPindahTutorBInggris,
    isLoading: isLoadingPostPindahTutorBInggris,
  } = useMutation({
    mutationKey: `${QUERIES.POST_PINDAH_TUTOR_B_INGGRIS}`,
    mutationFn: ({ payload }: { payload: FormData }) =>
      postPindahTutorBInggris(payload),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([
          `${QUERIES.POST_PINDAH_TUTOR_B_INGGRIS}`,
        ]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handlePostPindahTutorBInggris = useCallback(
    async (payload: any) => {
      await PostPindahTutorBInggris({ payload })
        .then((res) => {
          SweetAlert({
            icon: res.success ? "success" : "warning",
            title: "Info",
            text: res.message,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
              if (refetchListData && refetchListDataAnggotaKelompok) {
                refetchListData();
                refetchListDataAnggotaKelompok();
                setResetForm(true);
              }
            }
          });
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [PostPindahTutorBInggris]
  );

  const {
    mutateAsync: GetTutorBInggris,
    isLoading: isLoadingGetTutorBInggris,
  } = useMutation({
    mutationKey: `${QUERIES.GET_TUTOR_B_INGGRIS}`,
    mutationFn: () => getTutorBInggris(nik_tutor_b_inggris_lama),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([`${QUERIES.GET_TUTOR_B_INGGRIS}`]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleGetTutorBInggris = useCallback(async () => {
    await GetTutorBInggris()
      .then((res) => {
        setAllTutorBInggris(res.data);
      })
      .catch((error) => {
        SweetAlert({
          icon: "error",
          title: "Terjadi Kesalahan",
          text: error.data ? error.data.message : error,
          allowOutsideClick: false,
        }).then((res: any) => {
          if (res.isConfirmed) {
          }
        });
      });
  }, [GetTutorBInggris]);

  const formikPindahTutorBInggrisValidationSchema = Yup.object().shape({
    id_kelompok_b_inggris: Yup.string().required("Kelompok EMC Boleh Kosong"),
    id_anggota_kelompok_b_inggris: Yup.string().required(
      "Anggota Kelompok EMC Tidak Boleh Kosong"
    ),
    nik_tutor_b_inggris_tujuan: Yup.string().required(
      "Tutor EMC Tujuan Tidak Boleh Kosong"
    ),
    id_kelompok_b_inggris_tujuan: Yup.string().required(
      "Kelompok EMC Tujuan Tidak Boleh Kosong"
    ),
  });

  const formikPindahTutorBInggris = useFormik<FormPindahTutorBInggrisPayload>({
    initialValues: {
      id: null,
      id_anggota_kelompok_b_inggris: null,
      id_kelompok_b_inggris: null,
      id_kelompok_b_inggris_tujuan: null,
      nik_tutor_b_inggris_lama,
      nik_tutor_b_inggris_tujuan: null,
      keterangan: null,
    },
    enableReinitialize: false,
    validationSchema: formikPindahTutorBInggrisValidationSchema,
    onSubmit: (values: any) => {
      const payload = new FormData();
      Object.keys(values).map((key, idx) => {
        payload.append(key, values[key]);
      });
      if (handlePostPindahTutorBInggris) handlePostPindahTutorBInggris(payload);
    },
  });

  const [query, setQuery] = useState<string>(
    stringifyRequestQuery(state, "kelompok-b-inggris")
  );
  const updatedQuery = useMemo(
    () => stringifyRequestQuery(state, "kelompok-b-inggris"),
    [state]
  );

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery);
    }
  }, [query, updatedQuery]);

  useEffect(() => {
    if (resetForm) {
      setSubmitting(false);
      formikPindahTutorBInggris.setValues({
        id: null,
        id_anggota_kelompok_b_inggris: null,
        id_kelompok_b_inggris: null,
        id_kelompok_b_inggris_tujuan: null,
        nik_tutor_b_inggris_lama,
        nik_tutor_b_inggris_tujuan: null,
        keterangan: null,
      });
      setResetForm(false);
    }
  }, [resetForm]);

  const {
    data: listData,
    isFetching: isLoadingFetchData,
    refetch: refetchListData,
  } = useQuery(
    `${QUERIES.GET_PINDAH_TUTOR_B_INGGRIS}-${query}`,
    () => getPindahTutorBInggris(query),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onError: (err) => {
        console.error(err);
      },
    }
  );

  const {
    mutateAsync: GetAllKelompokBInggrisTujuan,
    isLoading: isLoadingGetAllKelompokBInggrisTujuan,
  } = useMutation({
    mutationKey: `${QUERIES.GET_ALL_KELOMPOK_B_INGGRIS}`,
    mutationFn: ({ nik_gtk }: { nik_gtk: string }) =>
      getAllKelompokBInggris(nik_gtk),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([
          `${QUERIES.GET_ALL_KELOMPOK_B_INGGRIS}`,
        ]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleGetAllKelompokBInggrisTujuan = useCallback(
    async (nik_gtk: string, type?: string) => {
      await GetAllKelompokBInggrisTujuan({ nik_gtk })
        .then((res) => {
          if (type && type === "tujuan") {
            return setAllKelompokTutorBInggrisTujuan(res.data);
          }
          setAllKelompokTutorBInggrisTujuan(res.data);
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [GetAllKelompokBInggrisTujuan]
  );

  const value = useMemo(
    () => ({
      listData,
      isLoadingFetchData,
      refetchListData,
      formikPindahTutorBInggris,
      formikPindahTutorBInggrisValidationSchema,
      handlePostPindahTutorBInggris,
      isLoadingPostPindahTutorBInggris,
      submitting,
      setSubmitting,
      handleGetTutorBInggris,
      isLoadingGetTutorBInggris,
      allTutorBInggris,
      setAllTutorBInggris,
      allKelompokTutorBInggrisTujuan,
      setAllKelompokTutorBInggrisTujuan,
      handleGetAllKelompokBInggrisTujuan,
      isLoadingGetAllKelompokBInggrisTujuan,
      resetForm,
      setResetForm,
    }),
    [
      listData,
      isLoadingFetchData,
      refetchListData,
      formikPindahTutorBInggris,
      formikPindahTutorBInggrisValidationSchema,
      handlePostPindahTutorBInggris,
      isLoadingPostPindahTutorBInggris,
      submitting,
      setSubmitting,
      handleGetTutorBInggris,
      isLoadingGetTutorBInggris,
      allTutorBInggris,
      setAllTutorBInggris,
      allKelompokTutorBInggrisTujuan,
      setAllKelompokTutorBInggrisTujuan,
      handleGetAllKelompokBInggrisTujuan,
      isLoadingGetAllKelompokBInggrisTujuan,
      resetForm,
      setResetForm,
    ]
  );

  return (
    <QueryResponseContext.Provider value={value}>
      {children}
    </QueryResponseContext.Provider>
  );
};

const useQueryResponse = () => useContext(QueryResponseContext);

const useQueryResponsePagination = () => {
  const defaultPaginationState = {
    ...initialQueryState,
  };

  const { listData } = useQueryResponse();

  return listData ?? defaultPaginationState;
};

export { QueryResponseProvider, useQueryResponse, useQueryResponsePagination };
