import { Card, Col, Form, Modal, Row } from "react-bootstrap";
import { useQueryResponse } from "../../../../provider/query-response/absensi-lt3q";
import NumberInputComponent from "../../../../hope-ui/components/number-input";
import TextareaComponent from "../../../../hope-ui/components/textarea-input";
import Select2 from "../../../../hope-ui/components/select-2";
import { useQueryResponse as useQueryResponseAnggotaKelompokLT3Q } from "../../../../provider/query-response/absensi-lt3q/kelompok-lt3q/kelola-anggota-kelompok";
import { useEffect, useState } from "react";
import { useAuth } from "../../../../provider/core/auth";
import { useQueryRequest as useQueryRequestBuatAbsensi } from "../../../../provider/query-request/absensi-lt3q/buat-absensi";
import {
  useQueryResponse as useQueryResponseBuatAbsensi,
  useQueryResponsePagination,
} from "../../../../provider/query-response/absensi-lt3q/buat-absensi";
import Toolbar from "./list/toolbar";
import { DataTable } from "../../../../hope-ui/components/table/DataTable";
import { Columns } from "./list/column";
import TextInputComponent from "../../../../hope-ui/components/text-input/TextInputComponent";
import FetchLoading from "../../../../hope-ui/components/fetch-loading";
import Datepicker from "../../../../hope-ui/components/datepicker";
import dayjs from "dayjs";
import { initialQueryResponse } from "../../../../models/absensi-lt3q/buat-absensi/_queryResponse";

const ModalBuatAbsensi = () => {
  const { setIsShowModalBuatAbsensi, isShowModalBuatAbsensi } =
    useQueryResponse();

  const { updateState } = useQueryRequestBuatAbsensi();
  const {
    handleGetAllKelompokLT3Q,
    allKelompokLT3Q,
    isLoadingGetAllKelompokLT3Q,
  } = useQueryResponseAnggotaKelompokLT3Q();

  const {
    listData,
    isLoadingFetchData,
    formikBuatAbsensi,
    submitting,
    setSubmitting,
    isLoadingPostBuatAbsensiLT3Q,
    isLoadingGetDetailAbsensiLT3Q,
    detailAbsensiLT3Q,
    setResetForm,
    resetForm,
    setDetailAbsensiLT3Q,
  } = useQueryResponseBuatAbsensi();
  const Data_table = listData?.data;
  const { currentUser } = useAuth();

  useEffect(() => {
    if (currentUser && currentUser.data && isShowModalBuatAbsensi) {
      const nik_gtk: any = currentUser.data.username;
      if (handleGetAllKelompokLT3Q) handleGetAllKelompokLT3Q(nik_gtk);
    }
  }, [handleGetAllKelompokLT3Q, currentUser, isShowModalBuatAbsensi]);

  let optionsKelompokLT3Q: any = allKelompokLT3Q.map((val) => {
    return {
      value: val.id,
      label: val.nama_kelompok_lt3q,
    };
  });
  const [query, setQuery] = useState<string>();
  const [nikTutorLT3Q, setNikTutorLT3Q] = useState<string | null>();
  const [idKelompokLT3Q, setIdKelompokLT3Q] = useState<string | null>();
  const [idRiwayatLT3Q, setIdRiwayatLT3Q] = useState<string | null>();

  useEffect(() => {
    if (listData && listData.data) {
      let data: any = listData.data.map((val) => {
        return {
          id_kelompok_lt3q: val.id_kelompok_lt3q,
          level_lt3q: val.level_lt3q,
          nik_gtk: val.nik_gtk,
          nik_tutor_lt3q: val.nik_tutor_lt3q,
        };
      });

      if (detailAbsensiLT3Q) {
        detailAbsensiLT3Q.kehadiran_lt3q.map((val) => {
          const find = data.find((element) => element.nik_gtk === val.nik_gtk);
          if (find) data = detailAbsensiLT3Q.kehadiran_lt3q;
        });
      }

      formikBuatAbsensi?.setFieldValue("kehadiran_lt3q", data);
    }
  }, [listData, detailAbsensiLT3Q]);

  useEffect(() => {
    if (
      currentUser &&
      currentUser.data &&
      formikBuatAbsensi &&
      formikBuatAbsensi?.getFieldProps("id_kelompok_lt3q").value &&
      isShowModalBuatAbsensi
    ) {
      const nik_tutor_lt3q: any = currentUser.data.username;
      const id_kelompok_lt3q =
        formikBuatAbsensi?.getFieldProps("id_kelompok_lt3q").value ??
        idKelompokLT3Q;
      setQuery(
        `nik_tutor_lt3q=${nik_tutor_lt3q}&id_kelompok_lt3q=${id_kelompok_lt3q}`
      );
      setNikTutorLT3Q(nik_tutor_lt3q);
      setIdKelompokLT3Q(id_kelompok_lt3q);
    } else {
      if (!detailAbsensiLT3Q) {
        setQuery("");
        setNikTutorLT3Q("");
        setIdKelompokLT3Q("");
      }
    }
  }, [
    currentUser,
    formikBuatAbsensi,
    isShowModalBuatAbsensi,
    detailAbsensiLT3Q,
    idKelompokLT3Q,
  ]);

  useEffect(() => {
    if (idKelompokLT3Q && nikTutorLT3Q) {
      const obj = {
        size: 999999999999,
        nik_tutor_lt3q: nikTutorLT3Q,
        id_kelompok_lt3q: idKelompokLT3Q,
      };

      if (idRiwayatLT3Q) obj["id_riwayat_lt3q"] = idRiwayatLT3Q;
      updateState(obj);
      if (!formikBuatAbsensi?.getFieldProps("nik_gtk_tutor").value)
        formikBuatAbsensi?.setFieldValue("nik_gtk_tutor", nikTutorLT3Q);
    } else {
      updateState({
        nik_tutor_lt3q: null,
        id_kelompok_lt3q: null,
        id_riwayat_lt3q: null,
      });
    }
  }, [idKelompokLT3Q, nikTutorLT3Q, idRiwayatLT3Q]);

  useEffect(() => {
    if (
      !isShowModalBuatAbsensi &&
      !isLoadingGetDetailAbsensiLT3Q &&
      detailAbsensiLT3Q &&
      detailAbsensiLT3Q.id
    ) {
      const id_kelompok_lt3q: any = detailAbsensiLT3Q.id_kelompok_lt3q;
      const nik_gtk_tutor: any = detailAbsensiLT3Q.nik_gtk_tutor;
      const id_riwayat_lt3q: any = detailAbsensiLT3Q.id;
      setIdKelompokLT3Q(id_kelompok_lt3q);
      setNikTutorLT3Q(nik_gtk_tutor);
      setIdRiwayatLT3Q(id_riwayat_lt3q);
      updateState({
        size: 999999999999,
        nik_tutor_lt3q: nik_gtk_tutor,
        id_kelompok_lt3q: id_kelompok_lt3q,
        id_riwayat_lt3q,
      });
      if (!resetForm) {
        setIsShowModalBuatAbsensi(true);
        formikBuatAbsensi?.setValues(detailAbsensiLT3Q);
      }
    }
  }, [
    isShowModalBuatAbsensi,
    isLoadingGetDetailAbsensiLT3Q,
    detailAbsensiLT3Q,
  ]);

  useEffect(() => {
    if (resetForm) {
      setSubmitting(false);
      setIdKelompokLT3Q("0");
      setIsShowModalBuatAbsensi(false);
      setDetailAbsensiLT3Q(initialQueryResponse.detailAbsensiLT3Q);
      setIdKelompokLT3Q(null);
      setIdRiwayatLT3Q(null);
    }
  }, [resetForm]);

  //console.log(formikBuatAbsensi?.values);

  return (
    <Modal
      backdrop="static"
      show={isShowModalBuatAbsensi}
      onBackdropClick={() => {
        setIsShowModalBuatAbsensi(false);
        setResetForm(true);
      }}
      size="xl"
      onHide={() => {
        setIsShowModalBuatAbsensi(false);
        setResetForm(true);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {detailAbsensiLT3Q && detailAbsensiLT3Q.id ? "Edit" : "Tambah"}{" "}
          Notulensi LT3Q
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoadingPostBuatAbsensiLT3Q && <FetchLoading />}
        <Form
          onSubmit={(e) => {
            setSubmitting(true);
            formikBuatAbsensi?.submitForm();
            e.preventDefault();
          }}
          className="px-4 py-2"
        >
          <Card.Header className="pb-1 d-flex flex-row justify-content-end">
            <button type="submit" className="btn btn-sm btn-primary">
              <i className="fas fa-clipboard-user me-2"></i>
              Simpan
            </button>
          </Card.Header>
          <Row className="gy-3 ">
            <Col lg="6">
              <Form.Group>
                <Datepicker
                  label="Tanggal"
                  required
                  value={dayjs(
                    formikBuatAbsensi?.getFieldProps("riwayat_tanggal").value
                  ).format("YYYY-MM-DD")}
                  onChange={(e: any) => {
                    const value = dayjs(e).format("YYYY-MM-DD");
                    formikBuatAbsensi?.setFieldValue("riwayat_tanggal", value);
                  }}
                />
                {submitting && formikBuatAbsensi?.errors.riwayat_tanggal && (
                  <div className="text-danger mt-2">
                    {formikBuatAbsensi?.errors.riwayat_tanggal}
                  </div>
                )}
              </Form.Group>
            </Col>
            <Col lg="6">
              <Form.Group>
                <Select2
                  label="Kelompok TTQ"
                  isRequired
                  placeholder="Pilih Kelompok TTQ"
                  options={optionsKelompokLT3Q}
                  isLoading={isLoadingGetAllKelompokLT3Q}
                  onChange={(e: any) => {
                    const value = e?.value;
                    formikBuatAbsensi?.setFieldValue(
                      "id_kelompok_lt3q",
                      value ?? "0"
                    );
                  }}
                  value={
                    formikBuatAbsensi?.getFieldProps("id_kelompok_lt3q").value
                  }
                />
                {submitting && formikBuatAbsensi?.errors.id_kelompok_lt3q && (
                  <div className="text-danger mt-2">
                    {formikBuatAbsensi?.errors.id_kelompok_lt3q}
                  </div>
                )}
              </Form.Group>
            </Col>
            <Col lg="6">
              <Form.Group>
                <TextInputComponent
                  label="Tempat"
                  isRequired
                  placeholder="Masukkan Tempat"
                  formik={formikBuatAbsensi?.getFieldProps("tempat")}
                  value={formikBuatAbsensi?.getFieldProps("tempat").value ?? ""}
                />
                {submitting && formikBuatAbsensi?.errors.tempat && (
                  <div className="text-danger mt-2">
                    {formikBuatAbsensi?.errors.tempat}
                  </div>
                )}
              </Form.Group>
            </Col>
            <Col lg="6">
              <Form.Group>
                <NumberInputComponent
                  label="Batas Tilawah Halaman"
                  required
                  placeholder="Masukkan Batas Tilawah Halaman"
                  formik={formikBuatAbsensi?.getFieldProps(
                    "batas_tilawah_halaman"
                  )}
                  value={
                    formikBuatAbsensi?.getFieldProps("batas_tilawah_halaman")
                      .value ?? ""
                  }
                />
                {submitting &&
                  formikBuatAbsensi?.errors.batas_tilawah_halaman && (
                    <div className="text-danger mt-2">
                      {formikBuatAbsensi?.errors.batas_tilawah_halaman}
                    </div>
                  )}
              </Form.Group>
            </Col>
            <Col lg="12">
              <Form.Group>
                <TextareaComponent
                  label="Materi"
                  required
                  placeholder="Masukkan Materi"
                  formik={formikBuatAbsensi?.getFieldProps("materi")}
                  value={formikBuatAbsensi?.getFieldProps("materi").value ?? ""}
                />
                {submitting && formikBuatAbsensi?.errors.materi && (
                  <div className="text-danger mt-2">
                    {formikBuatAbsensi?.errors.materi}
                  </div>
                )}
              </Form.Group>
            </Col>
          </Row>

          <Col className="mt-4">
            <h6>Kehadiran LT3Q</h6>
            <hr className="bg-light" />
            <Toolbar />
            <DataTable
              isLoading={isLoadingFetchData}
              data={Data_table}
              header={Columns}
              pagination={useQueryResponsePagination}
              queryRequest={useQueryRequestBuatAbsensi}
              isPagination={false}
            />
          </Col>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ModalBuatAbsensi;
