import { Card } from "react-bootstrap";
import {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponsePagination,
} from "../../provider/query-response/speaking-focus";
import {
  QueryRequestProvider,
  useQueryRequest,
} from "../../provider/query-request/speaking-focus";
import { DataTable } from "../../hope-ui/components/table/DataTable";
import { Columns } from "./list/columns";
import ModalSpeakingFocus from "./entry";
import Toolbar from "./list/toolbar";

const SpeakingFocusList = () => {
  const { isLoadingFetchData, listData } = useQueryResponse();
  return (
    <>
      <Toolbar />
      <ModalSpeakingFocus />
      <Card.Body className="pt-0">
        <DataTable
          isLoading={isLoadingFetchData}
          header={Columns}
          data={listData?.data}
          pagination={useQueryResponsePagination}
          queryRequest={useQueryRequest}
        />
      </Card.Body>
    </>
  );
};

export const SpeakingFocusListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <SpeakingFocusList />
    </QueryResponseProvider>
  </QueryRequestProvider>
);
