import { Dispatch, SetStateAction } from "react";
import { Response } from "../global";
import { FormikProps } from "formik";

export type FormDendaPresensiGTKPayload = {
  id: string | null;
  jenis_denda: string | null;
  maksimal: number | string | null;
  minimal: number | string | null;
  jumlah_nominal: number | string | null;
  keterangan: string | null;
  parameter: string | null;
};

export type QueryResponseContextProps<T> = {
  listData?: Response<Array<T>>;
  isLoadingFetchData: boolean;
  refetchListData?: () => void;
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  formikDendaPresensiGTK?: FormikProps<FormDendaPresensiGTKPayload>;
  formikDendaPresensiGTKValidationSchema?: any;
  submitting: boolean;
  setSubmitting: Dispatch<SetStateAction<boolean>>;
  handlePostDendaPresensiGTK: (data: FormData) => void;
  isLoadingPostDendaPresensiGTK: boolean;
  handleGetDetailDendaPresensiGTK: (id: string) => void;
  isLoadingGetDetailDendaPresensiGTK: boolean;
  detailDendaPresensiGTK: FormDendaPresensiGTKPayload;
  setDetailDendaPresensiGTK: Dispatch<
    SetStateAction<FormDendaPresensiGTKPayload>
  >;
  resetForm: boolean;
  setResetForm: Dispatch<SetStateAction<boolean>>;
  handleDeleteDendaPresensiGTK: (id: string) => void;
  isLoadingDeleteDendaPresensiGTK: boolean;
};

export const initialQueryResponse: QueryResponseContextProps<any> = {
  isLoadingFetchData: false,
  showModal: false,
  setShowModal: () => {},
  detailDendaPresensiGTK: {
    id: null,
    jenis_denda: null,
    jumlah_nominal: null,
    keterangan: null,
    maksimal: null,
    minimal: null,
    parameter: null,
  },
  setDetailDendaPresensiGTK: () => {},
  handleDeleteDendaPresensiGTK: () => {},
  isLoadingDeleteDendaPresensiGTK: false,
  handleGetDetailDendaPresensiGTK: () => {},
  isLoadingGetDetailDendaPresensiGTK: false,
  handlePostDendaPresensiGTK: () => {},
  isLoadingPostDendaPresensiGTK: false,
  resetForm: false,
  setResetForm: () => {},
  submitting: false,
  setSubmitting: () => {},
};
