import { Card } from "react-bootstrap";
import { SearchInputDT } from "../../../../hope-ui/components/table/SearchInput";
import { ShowLimitDT } from "../../../../hope-ui/components/table/ShowLimit";
import { useQueryRequest } from "../../../../provider/query-request/speaking-focus";
import { useQueryResponse } from "../../../../provider/query-response/speaking-focus";

const Toolbar = () => {
  const { state, updateState } = useQueryRequest();
  const { setShowModal } = useQueryResponse();
  return (
    <>
      <Card.Header className="d-flex justify-content-end gap-3 py-3 border border-b-1 overflow-auto">
        <button
          type="button"
          className="btn btn-sm btn-primary"
          onClick={() => {
            setShowModal(true);
          }}
        >
          <i className="far fa-plus me-1"></i> Tambah Speaking Focus
        </button>
      </Card.Header>
      <div className="row pt-3 px-4 mb-0 pb-0">
        <div className="col-md-6 col-12">
          <ShowLimitDT
            onChange={(e) => updateState({ size: parseInt(e.target.value) })}
            limit={state.size}
          />
        </div>
        <div className="col-md-6 col-12 ">
          <SearchInputDT
            state={(keyword: string) => {
              updateState({ search: keyword });
            }}
            value={state.search ? state.search : ""}
          />
        </div>
      </div>
    </>
  );
};

export default Toolbar;
