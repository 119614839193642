import { Dispatch, SetStateAction } from "react";
import { Response } from "../global";

export type FormMemoPayload = {
  id: number | null;
  id_pesan: string | null;
  nik_pengirim: string | null;
  nik_tujuan: string | null;
  judul_pesan: string | null;
  isi_pesan: string | null;
  status_pesan: HTMLElement | string | null;
  updatedAt: string | null;
  gtk_pengirim?: any;
  createdAt?: string | null;
};

export type QueryResponseContextProps<T> = {
  query?: string;
  listData?: Response<Array<T>>;
  isLoadingFetchData: boolean;
  isShowModalMemo?: boolean;
  setShowModalMemo: Dispatch<SetStateAction<boolean>>;
  handlePostMemo?: (data: FormData) => void;
  isLoadingPostMemo: boolean;
  resetForm: boolean;
  setResetForm: Dispatch<SetStateAction<boolean>>;
  detailMemo: FormMemoPayload;
  setDetailMemo: Dispatch<SetStateAction<FormMemoPayload>>;
  handleGetDetailMemo?: (id: number) => void;
  isLoadingGetDetailMemo: boolean;
  isShowDetailMemo?: boolean;
  setShowDetailMemo: Dispatch<SetStateAction<boolean>>;
};

export const initialQueryResponse: QueryResponseContextProps<any> = {
  isLoadingFetchData: false,
  isLoadingPostMemo: false,
  resetForm: false,
  isLoadingGetDetailMemo: false,
  setShowModalMemo: () => {},
  setResetForm: () => {},
  setDetailMemo: () => {},
  setShowDetailMemo: () => {},
  detailMemo: {
    id: null,
    id_pesan: null,
    nik_pengirim: null,
    nik_tujuan: null,
    judul_pesan: null,
    isi_pesan: null,
    status_pesan: null,
    updatedAt: null,
    gtk_pengirim: null,
    createdAt: null,
  },
};
