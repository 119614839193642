import { Card, Col, Form, Modal, Row } from "react-bootstrap";
import { useQueryResponse } from "../../../../provider/query-response/absensi-b-inggris";
import NumberInputComponent from "../../../../hope-ui/components/number-input";
import TextareaComponent from "../../../../hope-ui/components/textarea-input";
import Select2 from "../../../../hope-ui/components/select-2";
import { useQueryResponse as useQueryResponseAnggotaKelompokBInggris } from "../../../../provider/query-response/absensi-b-inggris/kelompok-b-inggris/kelola-anggota-kelompok";
import { useEffect, useState } from "react";
import { useAuth } from "../../../../provider/core/auth";
import { useQueryRequest as useQueryRequestBuatAbsensi } from "../../../../provider/query-request/absensi-b-inggris/buat-absensi";
import {
  useQueryResponse as useQueryResponseBuatAbsensi,
  useQueryResponsePagination,
} from "../../../../provider/query-response/absensi-b-inggris/buat-absensi";
import Toolbar from "./list/toolbar";
import { DataTable } from "../../../../hope-ui/components/table/DataTable";
import { Columns } from "./list/column";
import TextInputComponent from "../../../../hope-ui/components/text-input/TextInputComponent";
import FetchLoading from "../../../../hope-ui/components/fetch-loading";
import Datepicker from "../../../../hope-ui/components/datepicker";
import dayjs from "dayjs";
import { initialQueryResponse } from "../../../../models/absensi-b-inggris/buat-absensi/_queryResponse";

const ModalBuatAbsensi = () => {
  const { setIsShowModalBuatAbsensi, isShowModalBuatAbsensi } =
    useQueryResponse();

  const { updateState } = useQueryRequestBuatAbsensi();
  const {
    handleGetAllKelompokBInggris,
    allKelompokBInggris,
    isLoadingGetAllKelompokBInggris,
  } = useQueryResponseAnggotaKelompokBInggris();

  const {
    listData,
    isLoadingFetchData,
    formikBuatAbsensi,
    submitting,
    setSubmitting,
    isLoadingPostBuatAbsensiBInggris,
    isLoadingGetDetailAbsensiBInggris,
    detailAbsensiBInggris,
    setResetForm,
    resetForm,
    setDetailAbsensiBInggris,
    handleGetAllSpeakingFocus,
    isLoadingGetAllSpeakingFocus,
    allSpeakingFocus,
  } = useQueryResponseBuatAbsensi();
  const Data_table = listData?.data;
  const { currentUser } = useAuth();

  useEffect(() => {
    if (currentUser && currentUser.data && isShowModalBuatAbsensi) {
      const nik_gtk: any = currentUser.data.username;
      if (handleGetAllKelompokBInggris) handleGetAllKelompokBInggris(nik_gtk);
    }
  }, [handleGetAllKelompokBInggris, currentUser, isShowModalBuatAbsensi]);

  let optionsKelompokBInggris: any = allKelompokBInggris.map((val) => {
    return {
      value: val.id,
      label: val.nama_kelompok_b_inggris,
    };
  });

  useEffect(() => {
    if (handleGetAllSpeakingFocus) {
      handleGetAllSpeakingFocus();
    }
  }, [handleGetAllSpeakingFocus]);

  let optionSpeakingFocus: any = allSpeakingFocus.map((val) => {
    return {
      value: val.id,
      label: val.nama,
    };
  });

  const [query, setQuery] = useState<string>();
  const [nikTutorBInggris, setNikTutorBInggris] = useState<string | null>();
  const [idKelompokBInggris, setIdKelompokBInggris] = useState<string | null>();
  const [idRiwayatBInggris, setIdRiwayatBInggris] = useState<string | null>();

  useEffect(() => {
    if (listData && listData.data) {
      let data: any = listData.data.map((val) => {
        return {
          id_kelompok_b_inggris: val.id_kelompok_b_inggris,
          nik_gtk: val.nik_gtk,
          nik_tutor_b_inggris: val.nik_tutor_b_inggris,
        };
      });

      if (detailAbsensiBInggris) {
        detailAbsensiBInggris.kehadiran_b_inggris.map((val) => {
          const find = data.find((element) => element.nik_gtk === val.nik_gtk);
          if (find) data = detailAbsensiBInggris.kehadiran_b_inggris;
        });
      }

      formikBuatAbsensi?.setFieldValue("kehadiran_b_inggris", data);
    }
  }, [listData, detailAbsensiBInggris]);

  useEffect(() => {
    if (
      currentUser &&
      currentUser.data &&
      formikBuatAbsensi &&
      formikBuatAbsensi?.getFieldProps("id_kelompok_b_inggris").value &&
      isShowModalBuatAbsensi
    ) {
      const nik_tutor_b_inggris: any = currentUser.data.username;
      const id_kelompok_b_inggris =
        formikBuatAbsensi?.getFieldProps("id_kelompok_b_inggris").value ??
        idKelompokBInggris;
      setQuery(
        `nik_tutor_b_inggris=${nik_tutor_b_inggris}&id_kelompok_b_inggris=${id_kelompok_b_inggris}`
      );
      setNikTutorBInggris(nik_tutor_b_inggris);
      setIdKelompokBInggris(id_kelompok_b_inggris);
    } else {
      if (!detailAbsensiBInggris) {
        setQuery("");
        setNikTutorBInggris("");
        setIdKelompokBInggris("");
      }
    }
  }, [
    currentUser,
    formikBuatAbsensi,
    isShowModalBuatAbsensi,
    detailAbsensiBInggris,
    idKelompokBInggris,
  ]);

  useEffect(() => {
    if (idKelompokBInggris && nikTutorBInggris) {
      const obj = {
        size: 999999999999,
        nik_tutor_b_inggris: nikTutorBInggris,
        id_kelompok_b_inggris: idKelompokBInggris,
      };

      if (idRiwayatBInggris) obj["id_riwayat_b_inggris"] = idRiwayatBInggris;
      updateState(obj);
      if (!formikBuatAbsensi?.getFieldProps("nik_gtk_tutor").value)
        formikBuatAbsensi?.setFieldValue("nik_gtk_tutor", nikTutorBInggris);
    } else {
      updateState({
        nik_tutor_b_inggris: null,
        id_kelompok_b_inggris: null,
        id_riwayat_b_inggris: null,
      });
    }
  }, [idKelompokBInggris, nikTutorBInggris, idRiwayatBInggris]);

  useEffect(() => {
    if (
      !isShowModalBuatAbsensi &&
      !isLoadingGetDetailAbsensiBInggris &&
      detailAbsensiBInggris &&
      detailAbsensiBInggris.id
    ) {
      const id_kelompok_b_inggris: any =
        detailAbsensiBInggris.id_kelompok_b_inggris;
      const nik_gtk_tutor: any = detailAbsensiBInggris.nik_gtk_tutor;
      const id_riwayat_b_inggris: any = detailAbsensiBInggris.id;
      setIdKelompokBInggris(id_kelompok_b_inggris);
      setNikTutorBInggris(nik_gtk_tutor);
      setIdRiwayatBInggris(id_riwayat_b_inggris);
      updateState({
        size: 999999999999,
        nik_tutor_b_inggris: nik_gtk_tutor,
        id_kelompok_b_inggris: id_kelompok_b_inggris,
        id_riwayat_b_inggris,
      });
      if (!resetForm) {
        setIsShowModalBuatAbsensi(true);
        formikBuatAbsensi?.setValues(detailAbsensiBInggris);
      }
    }
  }, [
    isShowModalBuatAbsensi,
    isLoadingGetDetailAbsensiBInggris,
    detailAbsensiBInggris,
  ]);

  useEffect(() => {
    if (resetForm) {
      setSubmitting(false);
      setIdKelompokBInggris("0");
      setIsShowModalBuatAbsensi(false);
      setDetailAbsensiBInggris(initialQueryResponse.detailAbsensiBInggris);
      setIdKelompokBInggris(null);
      setIdRiwayatBInggris(null);
    }
  }, [resetForm]);

  return (
    <Modal
      backdrop="static"
      show={isShowModalBuatAbsensi}
      onBackdropClick={() => {
        setIsShowModalBuatAbsensi(false);
        setResetForm(true);
      }}
      size="xl"
      onHide={() => {
        setIsShowModalBuatAbsensi(false);
        setResetForm(true);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {detailAbsensiBInggris && detailAbsensiBInggris.id
            ? "Edit"
            : "Tambah"}{" "}
          Notulensi EMC
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoadingPostBuatAbsensiBInggris && <FetchLoading />}
        <Form
          onSubmit={(e) => {
            setSubmitting(true);
            formikBuatAbsensi?.submitForm();
            e.preventDefault();
          }}
          className="px-4 py-2"
        >
          <Card.Header className="pb-1 d-flex flex-row justify-content-end">
            <button type="submit" className="btn btn-sm btn-primary">
              <i className="fas fa-clipboard-user me-2"></i>
              Simpan
            </button>
          </Card.Header>
          <Row className="gy-3 ">
            <Col lg="6">
              <Form.Group>
                <Datepicker
                  label="Tanggal"
                  required
                  value={dayjs(
                    formikBuatAbsensi?.getFieldProps("riwayat_tanggal").value
                  ).format("YYYY-MM-DD")}
                  onChange={(e: any) => {
                    const value = dayjs(e).format("YYYY-MM-DD");
                    formikBuatAbsensi?.setFieldValue("riwayat_tanggal", value);
                  }}
                />
                {submitting && formikBuatAbsensi?.errors.riwayat_tanggal && (
                  <div className="text-danger mt-2">
                    {formikBuatAbsensi?.errors.riwayat_tanggal}
                  </div>
                )}
              </Form.Group>
            </Col>
            <Col lg="6">
              <Form.Group>
                <Select2
                  label="Kelompok EMC"
                  isRequired
                  placeholder="Pilih Kelompok EMC"
                  options={optionsKelompokBInggris}
                  isLoading={isLoadingGetAllKelompokBInggris}
                  onChange={(e: any) => {
                    const value = e?.value;
                    formikBuatAbsensi?.setFieldValue(
                      "id_kelompok_b_inggris",
                      value ?? "0"
                    );
                    setIdKelompokBInggris(value);
                  }}
                  value={
                    formikBuatAbsensi?.getFieldProps("id_kelompok_b_inggris")
                      .value
                  }
                  disabled={formikBuatAbsensi?.getFieldProps("id").value}
                />
                {submitting &&
                  formikBuatAbsensi?.errors.id_kelompok_b_inggris && (
                    <div className="text-danger mt-2">
                      {formikBuatAbsensi?.errors.id_kelompok_b_inggris}
                    </div>
                  )}
              </Form.Group>
            </Col>
            <Col lg="6">
              <Form.Group>
                <TextInputComponent
                  label="Tempat"
                  isRequired
                  placeholder="Masukkan Tempat"
                  formik={formikBuatAbsensi?.getFieldProps("tempat")}
                  value={formikBuatAbsensi?.getFieldProps("tempat").value ?? ""}
                />
                {submitting && formikBuatAbsensi?.errors.tempat && (
                  <div className="text-danger mt-2">
                    {formikBuatAbsensi?.errors.tempat}
                  </div>
                )}
              </Form.Group>
            </Col>
            <Col lg="6">
              <Form.Group>
                <Select2
                  label="Speaking Focus"
                  isRequired
                  placeholder="Pilih Speaking Focus"
                  options={optionSpeakingFocus}
                  isLoading={isLoadingGetAllSpeakingFocus}
                  onChange={(e: any) => {
                    const value = e?.value;
                    formikBuatAbsensi?.setFieldValue(
                      "id_speaking_focus",
                      value ?? "0"
                    );
                  }}
                  value={
                    formikBuatAbsensi?.getFieldProps("id_speaking_focus").value
                  }
                />
                {submitting && formikBuatAbsensi?.errors.id_speaking_focus && (
                  <div className="text-danger mt-2">
                    {formikBuatAbsensi?.errors.id_speaking_focus}
                  </div>
                )}
              </Form.Group>
            </Col>
          </Row>

          <Col className="mt-4">
            <h6>Kehadiran EMC</h6>
            <hr className="bg-light" />
            <Toolbar />
            <DataTable
              isLoading={isLoadingFetchData}
              data={Data_table}
              header={Columns}
              pagination={useQueryResponsePagination}
              queryRequest={useQueryRequestBuatAbsensi}
              isPagination={false}
            />
          </Col>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ModalBuatAbsensi;
