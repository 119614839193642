import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { WithChildren } from "../../../../helpers/react18MigrationHelpers";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { stringifyRequestQuery } from "../../../../helpers/helpers";
import { QUERIES } from "../../../../helpers/crud-helpers/const";
import { initialQueryState } from "../../../../models/global";
import { SweetAlert } from "../../../../hope-ui/components/sweet-alert";
import { useQueryRequest } from "../../../query-request/absensi-b-inggris/buat-absensi";
import {
  FormRiwayatBInggrisPayload,
  initialQueryResponse,
} from "../../../../models/absensi-b-inggris/buat-absensi/_queryResponse";
import {
  deleteAbsensiBInggris,
  getDetailAbsensiBInggris,
  getKehadiranBInggris,
  getTotalInfaq,
  postBuatAbsensiBInggris,
} from "../../../../api/absensi-b-inggris/_request";
import { FormAnggotaKelompokBInggrisPayload } from "../../../../models/absensi-b-inggris/anggota-kelompok-b-inggris/_queryResponse";
import { useQueryResponse as useQueryResponseAbsesnsiBInggris } from "../../absensi-b-inggris";
import { useQueryResponse as useQueryResponseDaftarAbsensi } from "../../absensi-b-inggris/daftar-absensi";
import { useAuth } from "../../../core/auth";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FormSpeakingFocusPayload } from "../../../../models/speaking-focus/_queryResponse";
import { getAllSpeakingFocus } from "../../../../api/speaking-focus/_request";

const QueryResponseContext = createContext(initialQueryResponse);

const QueryResponseProvider: FC<WithChildren> = ({ children }) => {
  const queryClient = useQueryClient();
  const { state } = useQueryRequest();
  state.col_header = `["riwayat_b_inggris.kelompok_b_inggris.nama_kelompok_b_inggris", "nik_gtk", "gtk.nama_gtk", "anggota_kelompok_b_inggris.nik_tutor_b_inggris"]`;
  const { currentUser } = useAuth();
  state.nik_tutor_b_inggris = currentUser?.data?.username;

  const { isShowModalBuatAbsensi } = useQueryResponseAbsesnsiBInggris();
  const refetchListDataAbsensi =
    useQueryResponseDaftarAbsensi().refetchListData;

  const [query, setQuery] = useState<string>(
    stringifyRequestQuery(state, "kehadiran-b-inggris")
  );
  const updatedQuery = useMemo(
    () => stringifyRequestQuery(state, "kehadiran-b-inggris"),
    [state]
  );

  const [currentOffset, setCurrentOffset] = useState<number>(0);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [detailAbsensiBInggris, setDetailAbsensiBInggris] =
    useState<FormRiwayatBInggrisPayload>(
      initialQueryResponse.detailAbsensiBInggris
    );

  const [allSpeakingFocus, setAllSpeakingFocus] = useState<
    Array<FormSpeakingFocusPayload>
  >([]);

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery);
    }
  }, [query, updatedQuery]);

  const [resetForm, setResetForm] = useState<boolean>(false);

  const [totalInfaq, setTotalInfaq] = useState<number>(
    initialQueryResponse.totalInfaq
  );

  const {
    mutateAsync: GetDetailAbsensiBInggris,
    isLoading: isLoadingGetDetailAbsensiBInggris,
  } = useMutation({
    mutationKey: `${QUERIES.GET_DETAIL_ABSENSI_B_INGGRIS}`,
    mutationFn: ({ id }: { id: string }) => getDetailAbsensiBInggris(id),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([
          `${QUERIES.GET_DETAIL_ABSENSI_B_INGGRIS}`,
        ]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleGetDetailAbsensiBInggris = useCallback(
    async (id: string) => {
      await GetDetailAbsensiBInggris({ id })
        .then((res) => {
          if (res.success) {
            setDetailAbsensiBInggris(res.data);
          } else {
            SweetAlert({
              icon: res.success ? "success" : "warning",
              title: "Info",
              html: res.message,
              allowOutsideClick: false,
            });
            return;
          }
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [GetDetailAbsensiBInggris]
  );

  const { mutateAsync: GetTotalInfaq, isLoading: isLoadingGetTotalInfaq } =
    useMutation({
      mutationKey: `${QUERIES.GET_TOTAL_INFAQ}`,
      mutationFn: ({ id }: { id: string }) => getTotalInfaq(id),
      onSuccess: async (res: any, variables) => {
        const response = res;
        try {
          await queryClient.invalidateQueries([`${QUERIES.GET_TOTAL_INFAQ}`]);
          return response;
        } catch (err) {
          console.log(err);
        }
      },
    });

  const handleGetTotalInfaq = useCallback(
    async (id: string) => {
      await GetTotalInfaq({ id })
        .then((res) => {
          const total_infaq = res.data;
          setTotalInfaq(total_infaq);
          formikBuatAbsensi?.setFieldValue("total_infaq", total_infaq);
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [GetTotalInfaq]
  );

  const {
    data: listData,
    isFetching: isLoadingFetchData,
    refetch: refetchListData,
  } = useQuery(
    `${QUERIES.GET_KEHADIRAN_B_INGGRIS}-${query}`,
    () => getKehadiranBInggris(query),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onError: (err) => {
        console.error(err);
      },
      enabled: isShowModalBuatAbsensi,
    }
  );
  useEffect(() => {
    if (listData) {
      setCurrentOffset(listData.offset);
    }
  }, [listData]);

  const formikBuatAbsensiValidationSchema = Yup.object().shape({
    riwayat_tanggal: Yup.string().required("Tanggal Tidak Boleh Kosong"),
    id_kelompok_b_inggris: Yup.string().required(
      "Kelompok EMC Tidak Boleh Kosong"
    ),
    nik_gtk_tutor: Yup.string().required("NIK GTK Tutor Tidak Boleh Kosong"),
    tempat: Yup.string().required("Tempat Tidak Boleh Kosong"),
    id_speaking_focus: Yup.string().required(
      "Speaking Focus Tidak Boleh Kosong"
    ),
    kehadiran_b_inggris: Yup.array().of(
      Yup.object().shape({
        nik_gtk: Yup.string().required("GTK Tidak Boleh Kosong"),
        status_absen: Yup.string().required("Kehadiran EMC Tidak Boleh Kosong"),
      })
    ),
  });

  const formikBuatAbsensi = useFormik<FormRiwayatBInggrisPayload>({
    initialValues: {
      id: null,
      id_kelompok_b_inggris: null,
      id_speaking_focus: null,
      riwayat_tanggal: null,
      nik_gtk_tutor: null,
      tempat: null,
      kehadiran_b_inggris: [],
    },
    enableReinitialize: false,
    validationSchema: formikBuatAbsensiValidationSchema,
    onSubmit: (values: any) => {
      const payload = new FormData();
      Object.keys(values).map((key, idx) => {
        if (key === "kehadiran_b_inggris") {
          console.log(values[key]);
          payload.append(key, JSON.stringify(values[key]));
        } else payload.append(key, values[key]);
      });
      if (handlePostBuatAbsensiBInggris) handlePostBuatAbsensiBInggris(payload);
    },
  });

  useEffect(() => {
    if (resetForm) {
      formikBuatAbsensi.setValues(initialQueryResponse.detailAbsensiBInggris);
      setDetailAbsensiBInggris(initialQueryResponse.detailAbsensiBInggris);
      setResetForm(false);
    }
  }, [resetForm]);

  const {
    mutateAsync: PostBuatAbsensiBInggris,
    isLoading: isLoadingPostBuatAbsensiBInggris,
  } = useMutation({
    mutationKey: `${QUERIES.POST_BUAT_ABSENSI_B_INGGRIS}`,
    mutationFn: ({ payload }: { payload: FormData }) =>
      postBuatAbsensiBInggris(payload),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([
          `${QUERIES.POST_BUAT_ABSENSI_B_INGGRIS}`,
        ]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handlePostBuatAbsensiBInggris = useCallback(
    async (payload: FormData) => {
      await PostBuatAbsensiBInggris({ payload })
        .then((res) => {
          SweetAlert({
            icon: res.success ? "success" : "warning",
            title: "Info",
            html: res.message,
            allowOutsideClick: false,
          }).then((swal: any) => {
            if (swal.isConfirmed && res.success) {
              setResetForm(true);
              if (refetchListDataAbsensi) refetchListDataAbsensi();
            }
          });
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [PostBuatAbsensiBInggris]
  );

  const {
    mutateAsync: GetAllSpeakingFocus,
    isLoading: isLoadingGetAllSpeakingFocus,
  } = useMutation({
    mutationKey: `${QUERIES.GET_ALL_SPEAKING_FOCUS}`,
    mutationFn: () => getAllSpeakingFocus(),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([
          `${QUERIES.GET_ALL_SPEAKING_FOCUS}`,
        ]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleGetAllSpeakingFocus = useCallback(async () => {
    await GetAllSpeakingFocus()
      .then((res) => {
        if (res.success) {
          setAllSpeakingFocus(res.data);
        } else {
          SweetAlert({
            icon: res.success ? "success" : "warning",
            title: "Info",
            html: res.message,
            allowOutsideClick: false,
          });
          return;
        }
      })
      .catch((error) => {
        SweetAlert({
          icon: "error",
          title: "Terjadi Kesalahan",
          text: error.data ? error.data.message : error,
          allowOutsideClick: false,
        }).then((res: any) => {
          if (res.isConfirmed) {
          }
        });
      });
  }, [GetAllSpeakingFocus]);

  const value = useMemo(
    () => ({
      listData,
      isLoadingFetchData,
      formikBuatAbsensi,
      formikBuatAbsensiValidationSchema,
      currentOffset,
      setCurrentOffset,
      submitting,
      setSubmitting,
      handlePostBuatAbsensiBInggris,
      isLoadingPostBuatAbsensiBInggris,
      resetForm,
      setResetForm,
      handleGetDetailAbsensiBInggris,
      isLoadingGetDetailAbsensiBInggris,
      detailAbsensiBInggris,
      setDetailAbsensiBInggris,
      handleGetTotalInfaq,
      isLoadingGetTotalInfaq,
      totalInfaq,
      setTotalInfaq,
      handleGetAllSpeakingFocus,
      isLoadingGetAllSpeakingFocus,
      allSpeakingFocus,
      setAllSpeakingFocus,
    }),
    [
      listData,
      isLoadingFetchData,
      formikBuatAbsensi,
      formikBuatAbsensiValidationSchema,
      currentOffset,
      setCurrentOffset,
      submitting,
      setSubmitting,
      handlePostBuatAbsensiBInggris,
      isLoadingPostBuatAbsensiBInggris,
      resetForm,
      setResetForm,
      handleGetDetailAbsensiBInggris,
      isLoadingGetDetailAbsensiBInggris,
      detailAbsensiBInggris,
      setDetailAbsensiBInggris,
      handleGetTotalInfaq,
      isLoadingGetTotalInfaq,
      totalInfaq,
      setTotalInfaq,
      handleGetAllSpeakingFocus,
      isLoadingGetAllSpeakingFocus,
      allSpeakingFocus,
      setAllSpeakingFocus,
    ]
  );

  return (
    <QueryResponseContext.Provider value={value}>
      {children}
    </QueryResponseContext.Provider>
  );
};

const useQueryResponse = () => useContext(QueryResponseContext);

const useQueryResponsePagination = () => {
  const defaultPaginationState = {
    ...initialQueryState,
  };

  const { listData } = useQueryResponse();

  return listData ?? defaultPaginationState;
};

export { QueryResponseProvider, useQueryResponse, useQueryResponsePagination };
