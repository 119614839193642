import { SearchInputDT } from "../../../hope-ui/components/table/SearchInput";
import { ShowLimitDT } from "../../../hope-ui/components/table/ShowLimit";
import { useQueryRequest } from "../../../provider/query-request/denda-persensi-gtk";

const Toolbar = () => {
  const { state, updateState } = useQueryRequest();
  return (
    <>
      <div className="row pt-4 px-4 mb-0 pb-0" style={{ marginTop: "35px" }}>
        <div className="col-md-6 col-12">
          <ShowLimitDT
            onChange={(e) => updateState({ size: parseInt(e.target.value) })}
            limit={state.size}
          />
        </div>
        <div className="col-md-6 col-12 ">
          <SearchInputDT
            state={(keyword: string) => {
              updateState({ search: keyword });
            }}
            value={state.search ? state.search : ""}
          />
        </div>
      </div>
    </>
  );
};

export default Toolbar;
