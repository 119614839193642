import { FC } from "react";
import { Button, ButtonGroup, Dropdown, DropdownButton } from "react-bootstrap";
import { useQueryResponse } from "../../../../provider/query-response/raport-hasil-kinerja";

type Props = {
  data: any;
};

const AksiCell: FC<Props> = ({ data }) => {
  const { setShowModal, formikRaportHasilKinerja } = useQueryResponse();
  return (
    <button
      type="button"
      className="btn btn-sm btn-info"
      onClick={() => {
        setShowModal(true);
        formikRaportHasilKinerja?.setFieldValue("nik_gtk", data.nik_gtk);
      }}
    >
      Kelola <i className="fa fa-gear"></i>
    </button>
  );
};

export default AksiCell;
