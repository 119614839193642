import { FC, useEffect, useState } from "react";
import { Tab, Table } from "react-bootstrap";
import TextInputComponent from "../../../../hope-ui/components/text-input/TextInputComponent";
import { useQueryResponse } from "../../../../provider/query-response/raport-hasil-kinerja";
import { initialQueryResponse } from "../../../../models/raport-hasil-kinerja/_queryResponse";

const ApprovalRaportHasilKinerja = () => {
  const { formikRaportHasilKinerja, submitting } = useQueryResponse();
  const data = [1, 2];

  return (
    <div className=" p-3">
      <h5>Approval</h5>
      <hr className="bg-light mb-0" />
      <Table responsive bordered className="fs-6 mt-3">
        <thead>
          <tr>
            <th className="text-center">No.</th>
            <th className="text-center">Nama Lengkap</th>
            <th className="text-center">Jabatan</th>
            <th className="text-center">NIY</th>
          </tr>
        </thead>
        <tbody>
          {data.map((val, idx) => {
            return (
              <tr>
                <td>{idx + 1}</td>
                <td style={{ minWidth: 300 }}>
                  <TextInputComponent
                    isRequired
                    placeholder="Masukkan Nama Lengkap"
                    formik={formikRaportHasilKinerja?.getFieldProps(
                      `approval[${idx}].nama_lengkap`
                    )}
                    value={
                      formikRaportHasilKinerja?.getFieldProps(
                        `approval[${idx}].nama_lengkap`
                      ).value ?? ""
                    }
                    disabled={
                      formikRaportHasilKinerja?.getFieldProps("status_dokumen")
                        .value === 2
                    }
                  />
                  {submitting &&
                    formikRaportHasilKinerja?.errors.approval &&
                    formikRaportHasilKinerja?.errors.approval[idx] &&
                    formikRaportHasilKinerja?.errors.approval[idx][
                      "nama_lengkap"
                    ] && (
                      <div className="fs-6 text-danger mt-2">
                        {
                          formikRaportHasilKinerja?.errors.approval[idx][
                            "nama_lengkap"
                          ]
                        }
                      </div>
                    )}
                </td>
                <td style={{ minWidth: 300 }}>
                  <TextInputComponent
                    isRequired
                    placeholder="Masukkan Jabatan"
                    formik={formikRaportHasilKinerja?.getFieldProps(
                      `approval[${idx}].jabatan`
                    )}
                    value={
                      formikRaportHasilKinerja?.getFieldProps(
                        `approval[${idx}].jabatan`
                      ).value ?? ""
                    }
                    disabled={
                      formikRaportHasilKinerja?.getFieldProps("status_dokumen")
                        .value === 2
                    }
                  />
                  {submitting &&
                    formikRaportHasilKinerja?.errors.approval &&
                    formikRaportHasilKinerja?.errors.approval[idx] &&
                    formikRaportHasilKinerja?.errors.approval[idx][
                      "jabatan"
                    ] && (
                      <div className="fs-6 text-danger mt-2">
                        {
                          formikRaportHasilKinerja?.errors.approval[idx][
                            "jabatan"
                          ]
                        }
                      </div>
                    )}
                </td>
                <td style={{ minWidth: 300 }}>
                  <TextInputComponent
                    isRequired
                    placeholder="Masukkan NIY"
                    formik={formikRaportHasilKinerja?.getFieldProps(
                      `approval[${idx}].niy`
                    )}
                    value={
                      formikRaportHasilKinerja?.getFieldProps(
                        `approval[${idx}].niy`
                      ).value ?? ""
                    }
                    disabled={
                      formikRaportHasilKinerja?.getFieldProps("status_dokumen")
                        .value === 2
                    }
                  />
                  {/* {submitting && !val.niy?.length && (
                      <div className="fs-6 text-danger mt-2">
                        NIY Tidak Boleh Kosong
                      </div>
                    )} */}
                </td>
              </tr>
            );
          })}
          {formikRaportHasilKinerja?.getFieldProps(`approval`).value.length ===
            0 && (
            <tr>
              <td colSpan={4}>
                <label className="text-danger w-100 text-center">
                  Belum Ada Approval
                </label>
              </td>
            </tr>
          )}
        </tbody>
        {/* <tfoot>
          <tr>
            <td colSpan={4}>
              <div className="w-100 d-flex flex-row justify-content-end p-0">
                <button
                  type="button"
                  className="btn btn-sm btn-info"
                  onClick={handleAddApproval}
                >
                  Tambah Approval <i className="fa fa-plus-circle ms-2"></i>
                </button>
              </div>
            </td>
          </tr>
        </tfoot> */}
      </Table>
    </div>
  );
};

export default ApprovalRaportHasilKinerja;
