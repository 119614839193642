import { FC } from "react";
import { Spinner } from "react-bootstrap";
import NamaGTKCell from "../../../../../../hope-ui/components/table/NamaGTKCell";

type Props = {
  rows?: Array<any>;
  allowed_columns: Array<any>;
  isLoading?: boolean;
  offset?: any;
};

const TableBody: FC<Props> = ({ rows, allowed_columns, isLoading, offset }) => {
  if (rows) {
    rows = rows.map((val, idx) => {
      return {
        no: offset + idx + 1,
        nik_gtk: val.nik_gtk,
        gtk: val.gtk,
        tutor_b_inggris:
          val.riwayat_b_inggris.kelompok_b_inggris.tutor_b_inggris?.nama_gtk,
        kelompok_b_inggris:
          val.riwayat_b_inggris.kelompok_b_inggris.nama_kelompok_b_inggris,
        hadir: val.hadir,
        sakit: val.sakit,
        izin: val.izin,
        alfa: val.alfa,
      };
    });
  }
  return (
    <tbody>
      {!isLoading &&
        rows?.map((row, i) => {
          let keys = Object.keys(row);
          return (
            <tr className="fs-7 border-bottom border-gray-300 table-row">
              {keys.map((key, idx) => {
                const validate_column = allowed_columns.find((e) => e === key);
                return (
                  validate_column && (
                    <td
                      className={`table-cell 
                        ${
                          validate_column === "hadir" &&
                          row[validate_column] > 0 &&
                          "bg-success text-white"
                        } 

                        ${
                          validate_column === "sakit" &&
                          row[validate_column] > 0 &&
                          "bg-yellow text-white"
                        } 

                        ${
                          validate_column === "izin" &&
                          row[validate_column] > 0 &&
                          "bg-danger text-white"
                        } 

                        ${
                          validate_column === "alfa" &&
                          row[validate_column] > 0 &&
                          "bg-dark text-white"
                        } 
                      `}
                    >
                      {" "}
                      <div
                        style={{ fontSize: 14 }}
                        className="text-center w-100 table-cell"
                      >
                        {validate_column === "gtk" ? (
                          <NamaGTKCell
                            id={row["nik_gtk"]}
                            label={row[validate_column]["nama_gtk"]}
                          />
                        ) : (
                          row[validate_column]
                        )}
                      </div>
                    </td>
                  )
                );
              })}
            </tr>
          );
        })}

      {isLoading && (
        <tr>
          <td colSpan={allowed_columns.length}>
            <div className="d-flex flex-row justify-content-center my-0">
              <Spinner
                animation="border"
                variant="success"
                style={{
                  width: 50,
                  height: 50,
                }}
              />
            </div>
          </td>
        </tr>
      )}

      {rows && rows.length === 0 && !isLoading && (
        <tr>
          <td colSpan={allowed_columns.length}>
            <label className="fs-6 text-danger w-100 text-center">
              Tidak Ada Data Ditemukan
            </label>
          </td>
        </tr>
      )}

      {rows && rows.length === 1 && !isLoading && (
        <>
          <tr className="border-0">
            <td className="border-0" colSpan={allowed_columns.length}></td>
          </tr>

          <tr className="border-0">
            <td className="border-0" colSpan={allowed_columns.length}></td>
          </tr>
        </>
      )}
    </tbody>
  );
};

export default TableBody;
