import { FC, useEffect, useState } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import Select2 from "../../../../../hope-ui/components/select-2";
import { DataTable } from "../../../../../hope-ui/components/table/DataTable";
import { useQueryRequest } from "../../../../../provider/query-request/absensi-b-inggris/kelompok-b-inggris/kelola-anggota-kelompok";
import {
  useQueryResponse,
  useQueryResponsePagination,
} from "../../../../../provider/query-response/absensi-b-inggris/kelompok-b-inggris/kelola-anggota-kelompok";
import { Columns } from "./list/columns/column";
import Toolbar from "./toolbar";
import { useFormik } from "formik";
import { FormAnggotaKelompokBInggrisPayload } from "../../../../../models/absensi-b-inggris/anggota-kelompok-b-inggris/_queryResponse";
import * as Yup from "yup";
import { useAuth } from "../../../../../provider/core/auth";
import { useQueryResponse as useQueryResponseAbsensiBInggris } from "../../../../../provider/query-response/absensi-b-inggris";
import { useQueryResponse as useQueryResponseDataGTK } from "../../../../../provider/query-response/data-gtk";
import FetchLoading from "../../../../../hope-ui/components/fetch-loading";

type Props = {
  activeTab: number;
};

const KelolaAnggotaKelompokBInggris: FC<Props> = ({ activeTab }) => {
  const listDataGTK = useQueryResponseDataGTK().listData;
  let optionsDataGTK: any = listDataGTK?.data?.map((val) => {
    return {
      value: val.nik_gtk,
      label: `${val.nik_gtk} - ${val.nama_gtk}`,
    };
  });
  const isLoadingFetchDataDataGTK =
    useQueryResponseDataGTK().isLoadingFetchData;

  const { isShowModalTentukanKelompok } = useQueryResponseAbsensiBInggris();
  const {
    isLoadingFetchData,
    listData,
    allKelompokBInggris,
    handleGetAllKelompokBInggris,
    isLoadingGetAllKelompokBInggris,
    handlePostAnggotaKelompokBInggris,
    isLoadingPostAnggotaKelompokBInggris,
    resetForm,
    setResetForm,
    resetDetailAnggotaKelompokBInggris,
    detailAnggotaKelompokBInggris,
    isLoadingGetDetailAnggotaKelompokBInggris,
  } = useQueryResponse();

  let optionsKelompokBInggris: any = allKelompokBInggris.map((val) => {
    return {
      value: val.id,
      label: val.nama_kelompok_b_inggris,
    };
  });

  const Data_table = listData?.data;
  const [submitting, setSubmitting] = useState(false);
  const { currentUser } = useAuth();

  useEffect(() => {
    if (
      currentUser &&
      currentUser.data &&
      isShowModalTentukanKelompok &&
      activeTab === 2
    ) {
      const nik_gtk: any = currentUser.data.username;
      formikAnggotaKelompokBInggris.setFieldValue(
        "nik_tutor_b_inggris",
        nik_gtk
      );
      if (handleGetAllKelompokBInggris) handleGetAllKelompokBInggris(nik_gtk);
    }
  }, [
    currentUser,
    isShowModalTentukanKelompok,
    handleGetAllKelompokBInggris,
    activeTab,
  ]);

  const formikAnggotaKelompokBInggrisValidationSchema = Yup.object().shape({
    id_kelompok_b_inggris: Yup.string().required(
      "Kelompok EMC Tidak Boleh Kosong"
    ),
    nik_gtk: Yup.string().required("GTK Tidak Boleh Kosong"),
  });
  const formikAnggotaKelompokBInggris =
    useFormik<FormAnggotaKelompokBInggrisPayload>({
      initialValues: {
        id: null,
        id_kelompok_b_inggris: null,
        nik_gtk: null,
        nik_tutor_b_inggris: null,
      },
      enableReinitialize: false,
      validationSchema: formikAnggotaKelompokBInggrisValidationSchema,
      onSubmit: (values: any) => {
        const payload = new FormData();
        Object.keys(values).map((key, idx) => {
          payload.append(key, values[key]);
        });
        if (handlePostAnggotaKelompokBInggris)
          handlePostAnggotaKelompokBInggris(payload);
      },
    });

  useEffect(() => {
    if (resetForm) {
      formikAnggotaKelompokBInggris.setFieldValue("id", null);
      formikAnggotaKelompokBInggris.setFieldValue(
        "id_kelompok_b_inggris",
        null
      );
      formikAnggotaKelompokBInggris.setFieldValue("nik_gtk", null);
      formikAnggotaKelompokBInggris.setFieldValue("level_b_inggris", null);
      setSubmitting(false);
      setResetForm(false);
      resetDetailAnggotaKelompokBInggris();
    }
  }, [resetForm]);

  useEffect(() => {
    if (detailAnggotaKelompokBInggris && detailAnggotaKelompokBInggris.id) {
      formikAnggotaKelompokBInggris.setValues(detailAnggotaKelompokBInggris);
    }
  }, [detailAnggotaKelompokBInggris]);

  return (
    <Col
      style={{
        display: activeTab === 2 ? "block" : "none",
      }}
      className="p-4"
    >
      {(isLoadingPostAnggotaKelompokBInggris ||
        isLoadingGetDetailAnggotaKelompokBInggris) && <FetchLoading />}
      <Form
        onSubmit={(e) => {
          setSubmitting(true);
          formikAnggotaKelompokBInggris.submitForm();
          e.preventDefault();
        }}
      >
        <h6>Tambah Data Anggota Kelompok EMC</h6>
        <hr className="bg-light" />
        <Row className="gy-3 mb-3">
          <Col lg="6">
            <Form.Group>
              <Select2
                label="Kelompok EMC"
                placeholder="Pilih Kelompok EMC"
                isRequired
                isLoading={isLoadingGetAllKelompokBInggris}
                options={optionsKelompokBInggris}
                onChange={(e: any) => {
                  const value = e?.value;
                  formikAnggotaKelompokBInggris.setFieldValue(
                    "id_kelompok_b_inggris",
                    value
                  );
                }}
                value={
                  formikAnggotaKelompokBInggris.getFieldProps(
                    "id_kelompok_b_inggris"
                  ).value
                }
              />
              {submitting &&
                formikAnggotaKelompokBInggris.errors.id_kelompok_b_inggris && (
                  <div className="text-danger mt-2">
                    {formikAnggotaKelompokBInggris.errors.id_kelompok_b_inggris}
                  </div>
                )}
            </Form.Group>
          </Col>

          <Col lg="6">
            <Form.Group>
              <Select2
                label="GTK"
                placeholder="Pilih GTK"
                isRequired
                options={optionsDataGTK}
                isLoading={isLoadingFetchDataDataGTK}
                onChange={(e: any) => {
                  const value = e?.value;
                  formikAnggotaKelompokBInggris.setFieldValue("nik_gtk", value);
                }}
                disabled={
                  detailAnggotaKelompokBInggris &&
                  detailAnggotaKelompokBInggris.id
                    ? true
                    : false
                }
                value={
                  formikAnggotaKelompokBInggris.getFieldProps("nik_gtk").value
                }
              />
              {submitting && formikAnggotaKelompokBInggris.errors.nik_gtk && (
                <div className="text-danger mt-2">
                  {formikAnggotaKelompokBInggris.errors.nik_gtk}
                </div>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Card.Footer
          className={`p-3 d-flex flex-row justify-content-${
            detailAnggotaKelompokBInggris?.id ? "between" : "end"
          }`}
        >
          {detailAnggotaKelompokBInggris?.id && (
            <button
              type="button"
              className="btn btn-sm btn-danger"
              onClick={() => setResetForm(true)}
            >
              <i className="fas fa-undo me-2"></i>
              Reset Form
            </button>
          )}

          <button
            type="submit"
            className={`btn btn-${
              detailAnggotaKelompokBInggris?.id ? "dark" : "primary"
            } btn-sm `}
          >
            <i
              className={`far fa-${
                detailAnggotaKelompokBInggris?.id ? "edit" : "floppy-disk"
              } me-2`}
            ></i>
            {detailAnggotaKelompokBInggris?.id ? "Edit" : "Simpan"}
          </button>
        </Card.Footer>
      </Form>

      <Col className="mt-4">
        <h6>Daftar Anggota Kelompok EMC</h6>
        <hr className="bg-light" />
        <Toolbar />
        <DataTable
          isLoading={isLoadingFetchData}
          data={Data_table}
          header={Columns}
          pagination={useQueryResponsePagination}
          queryRequest={useQueryRequest}
        />
      </Col>
    </Col>
  );
};

export default KelolaAnggotaKelompokBInggris;
