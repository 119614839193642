import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { WithChildren } from "../../../../../helpers/react18MigrationHelpers";
import { useQueryRequest } from "../../../../query-request/absensi-b-inggris/kelompok-b-inggris/kelola-anggota-kelompok";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { stringifyRequestQuery } from "../../../../../helpers/helpers";
import { QUERIES } from "../../../../../helpers/crud-helpers/const";
import { initialQueryState } from "../../../../../models/global";
import { SweetAlert } from "../../../../../hope-ui/components/sweet-alert";

import {
  DetailAnggotaKelompokBInggris,
  FormAnggotaKelompokBInggrisPayload,
  initialQueryResponse,
} from "../../../../../models/absensi-b-inggris/anggota-kelompok-b-inggris/_queryResponse";
import {
  getAnggotaKelompokBInggris,
  postAnggotaKelompokBInggris,
  getDetailAnggotaKelompokBInggris,
  getAllKelompokBInggris,
  deleteAnggotaKelompokBInggris,
  getListAnggotaKelompokBInggris,
} from "../../../../../api/absensi-b-inggris/_request";

import { useQueryResponse as useQueryResponseAbsensiBInggris } from "../../../absensi-b-inggris";
import { useQueryResponse as useQueryResponsePindahTutor } from "../../../absensi-b-inggris/kelompok-b-inggris/pindah-tutor";
import { useAuth } from "../../../../core/auth";
import { FormKelompokBInggrisPayload } from "../../../../../models/absensi-b-inggris/kelompok-b-inggris/_queryResponse";

const QueryResponseContext = createContext(initialQueryResponse);

const QueryResponseProvider: FC<WithChildren> = ({ children }) => {
  const { isShowModalTentukanKelompok } = useQueryResponseAbsensiBInggris();
  const queryClient = useQueryClient();
  const { state } = useQueryRequest();
  state.col_header = `["kelompok_b_inggris.nama_kelompok_b_inggris", "nik_gtk", "gtk.nama_gtk", "nik_tutor_b_inggris", "level_b_inggris"]`;
  const { currentUser } = useAuth();
  state.nik_tutor_b_inggris = currentUser?.data?.username;

  const [resetForm, setResetForm] = useState<boolean>(false);
  const [detailAnggotaKelompokBInggris, setDetailAnggotaKelompokBInggris] =
    useState<any>();
  const [allKelompokBInggris, setAllKelompokBInggris] = useState<
    Array<FormKelompokBInggrisPayload>
  >([]);

  const [query, setQuery] = useState<string>(
    stringifyRequestQuery(state, "anggota-kelompok-b-inggris")
  );
  const updatedQuery = useMemo(
    () => stringifyRequestQuery(state, "anggota-kelompok-b-inggris"),
    [state]
  );

  const [anggotaKelompokBInggris, setAnggotaKelompokBInggris] = useState<
    Array<DetailAnggotaKelompokBInggris>
  >([]);

  const { setAllKelompokTutorBInggrisTujuan } = useQueryResponsePindahTutor();

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery);
    }
  }, [query, updatedQuery]);

  const {
    mutateAsync: PostAnggotaKelompokBInggris,
    isLoading: isLoadingPostAnggotaKelompokBInggris,
  } = useMutation({
    mutationKey: `${QUERIES.POST_KELOMPOK_B_INGGRIS}`,
    mutationFn: ({ payload }: { payload: FormData }) =>
      postAnggotaKelompokBInggris(payload),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([
          `${QUERIES.POST_KELOMPOK_B_INGGRIS}`,
        ]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handlePostAnggotaKelompokBInggris = useCallback(
    async (payload: any) => {
      await PostAnggotaKelompokBInggris({ payload })
        .then((res) => {
          SweetAlert({
            icon: res.success ? "success" : "warning",
            title: "Info",
            text: res.message,
            allowOutsideClick: false,
          }).then((swal: any) => {
            if (swal.isConfirmed && res.success) {
              if (refetchListData) {
                refetchListData();
                setResetForm(true);
              }
            }
          });
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [PostAnggotaKelompokBInggris]
  );

  const {
    mutateAsync: GetDetailAnggotaKelompokBInggris,
    isLoading: isLoadingGetDetailAnggotaKelompokBInggris,
  } = useMutation({
    mutationKey: `${QUERIES.GET_DETAIL_KELOMPOK_B_INGGRIS}`,
    mutationFn: ({ id }: { id: string }) =>
      getDetailAnggotaKelompokBInggris(id),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([
          `${QUERIES.GET_DETAIL_KELOMPOK_B_INGGRIS}`,
        ]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleGetDetailAnggotaKelompokBInggris = useCallback(
    async (id: string) => {
      await GetDetailAnggotaKelompokBInggris({ id })
        .then((res) => {
          if (res.success) setDetailAnggotaKelompokBInggris(res.data);
          else {
            SweetAlert({
              icon: res.success ? "success" : "warning",
              title: "Info",
              text: res.message,
              allowOutsideClick: false,
            }).then((swal: any) => {
              if (swal.isConfirmed) {
                //setDetailAnggotaKelompokBInggris(res.data);
              }
            });
          }
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [GetDetailAnggotaKelompokBInggris]
  );

  const {
    mutateAsync: GetAnggotaKelompokBInggris,
    isLoading: isLoadingGetAnggotaKelompokBInggris,
  } = useMutation({
    mutationKey: `${QUERIES.GET_ANGGOTA_KELOMPOK_BPI}`,
    mutationFn: ({ id }: { id: string }) => getListAnggotaKelompokBInggris(id),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([
          `${QUERIES.GET_ANGGOTA_KELOMPOK_BPI}`,
        ]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleGetAnggotaKelompokBInggris = useCallback(
    async (id: string) => {
      await GetAnggotaKelompokBInggris({ id })
        .then((res) => {
          setAnggotaKelompokBInggris(res.data);
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [GetAnggotaKelompokBInggris]
  );

  const {
    mutateAsync: DeleteAnggotaKelompokBInggris,
    isLoading: isLoadingDeleteAnggotaKelompokBInggris,
  } = useMutation({
    mutationKey: `${QUERIES.GET_DETAIL_KELOMPOK_B_INGGRIS}`,
    mutationFn: ({ id }: { id: string }) => deleteAnggotaKelompokBInggris(id),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([
          `${QUERIES.GET_DETAIL_KELOMPOK_B_INGGRIS}`,
        ]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleDeleteAnggotaKelompokBInggris = useCallback(
    async (id: string) => {
      SweetAlert({
        icon: "warning",
        title: "Info",
        text: "Apakah Anda Yakin Ingin Menghapus Data?",
        confirmButtonText: "Lanjutkan",
        cancelButtonText: "Batal",
        isShowCancelButton: true,
      }).then(async (res) => {
        if (res.isConfirmed) {
          await DeleteAnggotaKelompokBInggris({ id })
            .then((res) => {
              SweetAlert({
                icon: res.success ? "success" : "warning",
                title: "Info",
                text: res.message,
                allowOutsideClick: false,
              }).then((swal: any) => {
                if (swal.isConfirmed && res.success) {
                  if (refetchListData) refetchListData();
                }
              });
            })
            .catch((error) => {
              SweetAlert({
                icon: "error",
                title: "Terjadi Kesalahan",
                text: error.data ? error.data.message : error,
                allowOutsideClick: false,
              }).then((res: any) => {
                if (res.isConfirmed) {
                }
              });
            });
        }
      });
    },
    [DeleteAnggotaKelompokBInggris]
  );

  const {
    mutateAsync: GetAllKelompokBInggris,
    isLoading: isLoadingGetAllKelompokBInggris,
  } = useMutation({
    mutationKey: `${QUERIES.GET_ALL_KELOMPOK_B_INGGRIS}`,
    mutationFn: ({ nik_gtk }: { nik_gtk: string }) =>
      getAllKelompokBInggris(nik_gtk),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([
          `${QUERIES.GET_ALL_KELOMPOK_B_INGGRIS}`,
        ]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleGetAllKelompokBInggris = useCallback(
    async (nik_gtk: string, type?: string) => {
      await GetAllKelompokBInggris({ nik_gtk })
        .then((res) => {
          if (type && type === "tujuan") {
            return setAllKelompokTutorBInggrisTujuan(res.data);
          }
          setAllKelompokBInggris(res.data);
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [GetAllKelompokBInggris]
  );

  const {
    data: listData,
    isFetching: isLoadingFetchData,
    refetch: refetchListData,
  } = useQuery(
    `${QUERIES.GET_ANGGOTA_KELOMPOK_B_INGGRIS}-${query}`,
    () => getAnggotaKelompokBInggris(query),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onError: (err) => {
        console.error(err);
      },
      enabled: isShowModalTentukanKelompok,
    }
  );

  const resetDetailAnggotaKelompokBInggris = () => {
    setDetailAnggotaKelompokBInggris({
      id: null,
      nama_kelompok_b_inggris: null,
      nik_gtk: null,
    });
  };

  const value = useMemo(
    () => ({
      listData,
      refetchListData,
      isLoadingFetchData,
      query,
      handlePostAnggotaKelompokBInggris,
      isLoadingPostAnggotaKelompokBInggris,
      resetForm,
      setResetForm,
      handleGetDetailAnggotaKelompokBInggris,
      isLoadingGetDetailAnggotaKelompokBInggris,
      detailAnggotaKelompokBInggris,
      setDetailAnggotaKelompokBInggris,
      resetDetailAnggotaKelompokBInggris,
      handleGetAllKelompokBInggris,
      isLoadingGetAllKelompokBInggris,
      allKelompokBInggris,
      setAllKelompokBInggris,
      handleDeleteAnggotaKelompokBInggris,
      isLoadingDeleteAnggotaKelompokBInggris,
      handleGetAnggotaKelompokBInggris,
      isLoadingGetAnggotaKelompokBInggris,
      anggotaKelompokBInggris,
      setAnggotaKelompokBInggris,
    }),
    [
      listData,
      refetchListData,
      isLoadingFetchData,
      query,
      handlePostAnggotaKelompokBInggris,
      isLoadingPostAnggotaKelompokBInggris,
      resetForm,
      setResetForm,
      handleGetDetailAnggotaKelompokBInggris,
      isLoadingGetDetailAnggotaKelompokBInggris,
      detailAnggotaKelompokBInggris,
      setDetailAnggotaKelompokBInggris,
      resetDetailAnggotaKelompokBInggris,
      handleGetAllKelompokBInggris,
      isLoadingGetAllKelompokBInggris,
      allKelompokBInggris,
      setAllKelompokBInggris,
      handleDeleteAnggotaKelompokBInggris,
      isLoadingDeleteAnggotaKelompokBInggris,
      handleGetAnggotaKelompokBInggris,
      isLoadingGetAnggotaKelompokBInggris,
      anggotaKelompokBInggris,
      setAnggotaKelompokBInggris,
    ]
  );

  return (
    <QueryResponseContext.Provider value={value}>
      {children}
    </QueryResponseContext.Provider>
  );
};

const useQueryResponse = () => useContext(QueryResponseContext);

const useQueryResponsePagination = () => {
  const defaultPaginationState = {
    ...initialQueryState,
  };

  const { listData } = useQueryResponse();

  return listData ?? defaultPaginationState;
};

export { QueryResponseProvider, useQueryResponse, useQueryResponsePagination };
