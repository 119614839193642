import { Card, Col } from "react-bootstrap";
import {
  QueryResponseProvider,
  useQueryResponse,
} from "../../provider/query-response/raport-hasil-kinerja";
import Select2 from "../../hope-ui/components/select-2";
import { useEffect, useState } from "react";
import FetchLoadingCompanyLogo from "../../hope-ui/components/fetch-loading-with-company-logo";
import { useAuth } from "../../provider/core/auth";
import EvaluasiKuantitatifSaya from "./list/evaluasi-kuantitatif";
import EvaluasiKualitatifSaya from "./list/evaluasi-kualitatif";
import ApprovalSaya from "./list/approval";

const RaportSayaList = () => {
  const { currentUser } = useAuth();
  const {
    activeTab,
    setActiveTab,
    handleGetDetailRaportHasilKinerja,
    isLoadingGetDetailRaportHasilKinerja,
    detailRaportHasilKinerja,
  } = useQueryResponse();
  const tw = ["I", "II", "III", "IV"];
  let optionsTahun: any = [];
  const year = new Date().getFullYear();
  let i;
  for (i = year; i >= 2000; i--) {
    optionsTahun.push({
      value: i,
      label: `${i} / ${i + 1}`,
    });
  }

  const [tahun, setTahun] = useState();

  useEffect(() => {
    if (currentUser && currentUser.data && tahun) {
      const nik_gtk: any = currentUser.data.username;
      const _tahun: any = tahun;
      handleGetDetailRaportHasilKinerja(nik_gtk, _tahun, activeTab, 2);
    }
  }, [tahun, activeTab, currentUser]);

  return (
    <>
      <Card.Body className="pt-3">
        <Col lg="3">
          <Select2
            isRequired
            label="Tahun Pelajaran"
            options={optionsTahun}
            onChange={(e) => {
              const value: any = e ? e["value"] : null;
              setTahun(value);
            }}
            value={tahun}
          />
        </Col>

        <hr className="bg-light" />
        {tahun ? (
          <div>
            <div
              className="d-flex flex-row w-100 justify-content-center overflow-auto"
              style={{
                paddingLeft: 150,
              }}
            >
              {tw.map((val, idx) => {
                idx = idx + 1;
                return (
                  <button
                    type="button"
                    className={` ${
                      activeTab === idx
                        ? "bg-primary text-white border border-primary"
                        : "bg-white text-dark border border-white"
                    } cursor-pointer btn-md me-2 py-2 px-4 rounded-pill border border-white`}
                    onClick={() => {
                      setActiveTab(idx);
                    }}
                  >
                    Triwulan {val}
                  </button>
                );
              })}
            </div>
            {isLoadingGetDetailRaportHasilKinerja ? (
              <div className="p-4">
                <FetchLoadingCompanyLogo />
              </div>
            ) : (
              <div className="p-3">
                {detailRaportHasilKinerja?.status_dokumen === 2 ? (
                  <>
                    <EvaluasiKuantitatifSaya />
                    <EvaluasiKualitatifSaya />
                    <div
                      style={{
                        width: "100%",
                        height: "100px",
                        border: "1px solid #EEEEEE",
                        padding: "10px",
                        fontSize: "14px",
                        marginTop: 20,
                      }}
                    >
                      <b>Catatan :</b> {detailRaportHasilKinerja?.catatan}
                    </div>
                    <ApprovalSaya />
                  </>
                ) : (
                  <div
                    className="d-flex flex-row justify-content-center align-items-center h-full w-100"
                    style={{
                      height: "10vh",
                    }}
                  >
                    <label className="text-danger">Raport belum tersedia</label>
                  </div>
                )}
              </div>
            )}
          </div>
        ) : (
          <div
            className="d-flex flex-row justify-content-center align-items-center h-full w-100"
            style={{
              height: "10vh",
            }}
          >
            <label className="text-danger">
              Harap Untuk Memilih Tahun Pelajaran Terlebih Dahulu
            </label>
          </div>
        )}
      </Card.Body>
    </>
  );
};

export const RaportSayaListWrapper = () => (
  <QueryResponseProvider>
    <RaportSayaList />
  </QueryResponseProvider>
);
