import { Modal } from "react-bootstrap";
import { useQueryResponse } from "../../../../provider/query-response/absensi-b-inggris";
import { useQueryResponse as useQueryResponseKelolaKelompokBInggris } from "../../../../provider/query-response/absensi-b-inggris/kelompok-b-inggris/kelola-kelompok";
import { useQueryResponse as useQueryResponseKelolaAnggotaKelompokBInggris } from "../../../../provider/query-response/absensi-b-inggris/kelompok-b-inggris/kelola-anggota-kelompok";
import { useEffect, useState } from "react";
import KelolaKelompokBInggris from "./kelola-kelompok";
import { useQueryRequest } from "../../../../provider/query-request/absensi-b-inggris/kelompok-b-inggris/kelola-kelompok";
import { useQueryRequest as useQueryRequestAnggota } from "../../../../provider/query-request/absensi-b-inggris/kelompok-b-inggris/kelola-anggota-kelompok";
import KelolaAnggotaKelompokBInggris from "./kelola-anggota-kelompok";
import PindahTutor from "./pindah-tutor";

const ModalKelompokBInggris = () => {
  const { isShowModalTentukanKelompok, setIsShowModalTentukanKelompok } =
    useQueryResponse();
  const { setResetForm } = useQueryResponseKelolaKelompokBInggris();
  const setResetFormAnggota =
    useQueryResponseKelolaAnggotaKelompokBInggris().setResetForm;
  const [activeTab, setActiveTab] = useState(1);
  const { updateState } = useQueryRequest();
  const updateStateAnggota = useQueryRequestAnggota().updateState;

  return (
    <Modal
      backdrop="static"
      show={isShowModalTentukanKelompok}
      onBackdropClick={() => {
        setIsShowModalTentukanKelompok(false);
      }}
      size="xl"
      onHide={() => {
        setIsShowModalTentukanKelompok(false);
        updateState({
          size: 10,
          search: "",
          page: 1,
          sort_by: null,
          sort_type: "asc",
        });

        updateStateAnggota({
          size: 10,
          search: "",
          page: 1,
          sort_by: null,
          sort_type: "asc",
        });
        setResetForm(true);
        setResetFormAnggota(true);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Tentukan Kelompok EMC </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <button
          type="button"
          className={` ${
            activeTab === 1
              ? "bg-primary text-white border border-primary"
              : "bg-white text-dark border border-white"
          } cursor-pointer btn-md me-2 py-2 px-4 rounded-pill border border-white`}
          onClick={() => {
            setActiveTab(1);
          }}
        >
          Kelola Kelompok
        </button>

        <button
          type="button"
          className={` ${
            activeTab === 2
              ? "bg-primary text-white border border-primary"
              : "bg-white text-dark border border-white"
          } cursor-pointer btn-md me-2 py-2 px-4 rounded-pill border border-white`}
          onClick={() => {
            setActiveTab(2);
          }}
        >
          Kelola Anggota Kelompok
        </button>

        <button
          type="button"
          className={` ${
            activeTab === 3
              ? "bg-primary text-white border border-primary"
              : "bg-white text-dark border border-white"
          } cursor-pointer btn-md me-2 py-2 px-4 rounded-pill border border-white`}
          onClick={() => {
            setActiveTab(3);
          }}
        >
          Pindah Tutor
        </button>

        <KelolaKelompokBInggris activeTab={activeTab} />
        <KelolaAnggotaKelompokBInggris activeTab={activeTab} />
        <PindahTutor activeTab={activeTab} />
      </Modal.Body>
    </Modal>
  );
};

export default ModalKelompokBInggris;
