import { Dispatch, SetStateAction } from "react";
import { Response } from "../../global";
import { FormikProps } from "formik";
import { DetailAnggotaKelompokBInggris } from "../anggota-kelompok-b-inggris/_queryResponse";
import { FormKelompokBInggrisPayload } from "../kelompok-b-inggris/_queryResponse";

export type FormPindahTutorBInggrisPayload = {
  id: number | string | null;
  id_kelompok_b_inggris: number | string | null;
  id_anggota_kelompok_b_inggris: number | string | null;
  nik_tutor_b_inggris_lama: string | null;
  nik_tutor_b_inggris_tujuan: string | null;
  id_kelompok_b_inggris_tujuan: number | string | null;
  keterangan: string | null;
};

export type TutorBInggris = {
  nik_gtk: string | null;
  nama_gtk: string | null;
};

export type QueryResponseContextProps<T> = {
  query?: string;
  listData?: Response<Array<T>>;
  isLoadingFetchData: boolean;
  formikPindahTutorBInggris?: FormikProps<FormPindahTutorBInggrisPayload>;
  formikPindahTutorBInggrisValidationSchema?: any;
  handlePostPindahTutorBInggris?: (payload: FormData) => void;
  isLoadingPostPindahTutorBInggris: boolean;
  submitting: boolean;
  setSubmitting: Dispatch<SetStateAction<boolean>>;
  handleGetTutorBInggris?: () => void;
  isLoadingGetTutorBInggris: boolean;
  allTutorBInggris: Array<TutorBInggris>;
  setAllTutorBInggris: Dispatch<SetStateAction<Array<TutorBInggris>>>;
  allKelompokTutorBInggrisTujuan: Array<FormKelompokBInggrisPayload>;
  setAllKelompokTutorBInggrisTujuan: Dispatch<
    SetStateAction<Array<FormKelompokBInggrisPayload>>
  >;
  handleGetAllKelompokBInggrisTujuan?: (nik_gtk: string, type?: string) => void;
  isLoadingGetAllKelompokBInggrisTujuan: boolean;
  resetForm: boolean;
  setResetForm: Dispatch<SetStateAction<boolean>>;
};

export const initialQueryResponse: QueryResponseContextProps<any> = {
  isLoadingFetchData: false,
  isLoadingPostPindahTutorBInggris: false,
  submitting: false,
  setSubmitting: () => {},
  handleGetTutorBInggris: () => {},
  isLoadingGetTutorBInggris: false,
  allTutorBInggris: [],
  setAllTutorBInggris: () => {},
  allKelompokTutorBInggrisTujuan: [],
  setAllKelompokTutorBInggrisTujuan: () => {},
  isLoadingGetAllKelompokBInggrisTujuan: false,
  resetForm: false,
  setResetForm: () => {},
};
