import axios, { AxiosResponse } from "axios";
import { client, client_sso } from "../apiClient";
import { ApiConfig } from "../apiConfig";

const getListSpeakingFocus = async (query: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_LIST_SPEAKING_FOCUS_URL}?${query}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const postSpeakingFocus = async (data: FormData): Promise<any> => {
  return client
    .post(`${ApiConfig.POST_SPEAKING_FOCUS_URL}`, data)
    .then((response: AxiosResponse<any>) => response.data);
};

const getDetailSpeakingFocus = async (id: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_DETAIL_SPEAKING_FOCUS_URL}/${id}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const deleteSpeakingFocus = async (id: string): Promise<any> => {
  return client
    .delete(`${ApiConfig.DELETE_SPEAKING_FOCUS_URL}/${id}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const getAllSpeakingFocus = async (): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_ALL_SPEAKING_FOCUS_URL}`)
    .then((response: AxiosResponse<any>) => response.data);
};

export {
  getListSpeakingFocus,
  postSpeakingFocus,
  getDetailSpeakingFocus,
  deleteSpeakingFocus,
  getAllSpeakingFocus,
};
