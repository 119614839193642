import { Fragment, useEffect } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useLayout } from "../../../layout/core/LayoutProvider";
import { PresensiSayaLT3QWrapper } from "../../../modules/presensi-saya/presensi-lt3q";

const PresensiLT3QSaya = () => {
  const { setSetting } = useLayout();
  useEffect(() => {
    document.title =
      "Sistem Informasi Bina Ilmi | Presensi Saya | Presensi TTQ";
    setSetting((prevState) => {
      let jasper = Object.assign({}, prevState);
      jasper.page_breadcrumbs = "Presensi Saya | Presensi TTQ";
      return jasper;
    });
  }, []);

  return (
    <Fragment>
      <Row>
        <Col md="12" lg="12">
          <Card>
            <PresensiSayaLT3QWrapper />
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default PresensiLT3QSaya;
