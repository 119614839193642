import { Alert, Card, Col, Form, Row } from "react-bootstrap";
import TextInputComponent from "../../../../hope-ui/components/text-input/TextInputComponent";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { useEffect, useRef, useState } from "react";
import AutoCompleteInputComponent from "../../../../hope-ui/components/text-input/AutoCompleteInputComponent";
import Datepicker from "../../../../hope-ui/components/datepicker";
import dayjs from "dayjs";
import Select2 from "../../../../hope-ui/components/select-2";
import { useQueryRequest } from "../../../../provider/query-request/absensi-lt3q/nilai-tahsin";
import { useQueryResponse } from "../../../../provider/query-response/absensi-lt3q/nilai-tahsin";
import { useQueryResponse as useQueryResponseAbsensiLT3Q } from "../../../../provider/query-response/absensi-lt3q";
import { useQueryResponse as useQueryResponseKelompokLT3Q } from "../../../../provider/query-response/absensi-lt3q/kelompok-lt3q/kelola-kelompok";
import { useQueryResponse as useQueryResponseAnggotaKelompokLT3Q } from "../../../../provider/query-response/absensi-lt3q/kelompok-lt3q/kelola-anggota-kelompok";
import { useQueryResponse as useQueryResponseDetailGTK } from "../../../../provider/query-response/detail-gtk";
import { useAuth } from "../../../../provider/core/auth";
import { DataTable } from "../../../../hope-ui/components/table/DataTable";
import { Columns } from "./list/columns/column";
import Toolbar from "./list/toolbar";
import { FormNilaiTahsinLT3QDetailPayload } from "../../../../models/absensi-lt3q/nilai-tahsin/_queryResponse";
import FetchLoading from "../../../../hope-ui/components/fetch-loading";

const NilaiTahsin = () => {
  const { isLoadingGetDetailGTK } = useQueryResponseDetailGTK();
  const { currentUser } = useAuth();
  const {
    allKelompokLT3Q,
    handleGetAllKelompokLT3Q,
    isLoadingGetAllKelompokLT3Q,
    formikNilaiTahsinLT3Q,
    isLoadingFetchData,
    listData,
    refetchListData,
    handleGetAllNilaiTahsin,
    isLoadingGetAllNilaiTahsin,
    allNilaiTahsin,
    submitting,
    setSubmitting,
    isLoadingPostNilaiTahsin,
  } = useQueryResponse();
  const { updateState } = useQueryRequest();
  const { activeTab } = useQueryResponseAbsensiLT3Q();
  const [idKelompokLT3Q, setIdKelompokLT3Q] = useState();

  const resetFormKelompokLT3Q = useQueryResponseKelompokLT3Q().resetForm;
  const resetFormAnggotaKelompokLT3Q =
    useQueryResponseAnggotaKelompokLT3Q().resetForm;

  useEffect(() => {
    if (activeTab === 3 && handleGetAllKelompokLT3Q && currentUser) {
      const nik_tutor_lt3q: any = currentUser.data?.username;
      handleGetAllKelompokLT3Q(nik_tutor_lt3q);
    }
  }, [activeTab, handleGetAllKelompokLT3Q, currentUser, resetFormKelompokLT3Q]);

  useEffect(() => {
    if (resetFormAnggotaKelompokLT3Q && refetchListData) {
      refetchListData();
    }
  }, [refetchListData, resetFormAnggotaKelompokLT3Q]);

  useEffect(() => {
    if (handleGetAllNilaiTahsin) {
      handleGetAllNilaiTahsin("nama_ujian");
    }
  }, [handleGetAllNilaiTahsin]);

  const optionsKelompokLT3Q = allKelompokLT3Q.map((val) => {
    return {
      value: val.id,
      label: val.nama_kelompok_lt3q,
    };
  });

  const optionsNilaiTahsin = allNilaiTahsin.map((val) => {
    return val.nama_ujian;
  });

  useEffect(() => {
    if (
      listData &&
      listData.data &&
      formikNilaiTahsinLT3Q?.getFieldProps("detail").value.length === 0
    ) {
      const obj: Array<FormNilaiTahsinLT3QDetailPayload> = listData.data.map(
        (val) => {
          return {
            id: null,
            id_nilai_tahsin_lt3q: null,
            nik_gtk: val.nik_gtk,
            nilai_teori: null,
            nilai_tilawah: null,
            keterangan: null,
            is_ujian: false,
            nama_gtk: val.gtk.nama_gtk,
          };
        }
      );
      formikNilaiTahsinLT3Q?.setFieldValue("detail", obj);
    }
  }, [listData]);

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        setSubmitting(true);
        formikNilaiTahsinLT3Q?.submitForm();
      }}
    >
      {(isLoadingPostNilaiTahsin || isLoadingGetDetailGTK) && <FetchLoading />}
      <Card.Body className="pt-4">
        <hr className="bg-light" />
        <h6>Form Tambah Ujian / Remidi Tahsin TTQ</h6>
        <hr className="bg-light" />
        <Row className="gy-4">
          <Col lg={6}>
            <Autocomplete
              value={
                formikNilaiTahsinLT3Q?.getFieldProps("nama_ujian").value ?? ""
              }
              onChange={(event, newValue) => {
                formikNilaiTahsinLT3Q?.setFieldValue("nama_ujian", newValue);
              }}
              loading={isLoadingGetAllNilaiTahsin}
              id="free-solo-dialog-demo"
              options={optionsNilaiTahsin}
              selectOnFocus
              handleHomeEndKeys
              renderOption={(props, option) => {
                const { key, ...optionProps } = props;
                return (
                  <li key={key} {...optionProps}>
                    {option}
                  </li>
                );
              }}
              sx={{ width: 300 }}
              freeSolo
              renderInput={(params) => (
                <AutoCompleteInputComponent
                  params={params}
                  isRequired
                  placeholder="Masukkan Nama Ujian / Remidi"
                  label="Nama Ujian / Remidi"
                  formik={formikNilaiTahsinLT3Q?.getFieldProps("nama_ujian")}
                />
              )}
            />
            {submitting && formikNilaiTahsinLT3Q?.errors.nama_ujian && (
              <div className="text-danger mt-2">
                {formikNilaiTahsinLT3Q?.errors.nama_ujian}
              </div>
            )}
          </Col>
          <Col lg={6}>
            <Datepicker
              label="Tanggal"
              required
              value={
                formikNilaiTahsinLT3Q?.getFieldProps("tanggal").value ?? null
              }
              onChange={(e: any) => {
                const value = dayjs(e).format("YYYY-MM-DD");
                formikNilaiTahsinLT3Q?.setFieldValue("tanggal", value);
              }}
            />
            {submitting && formikNilaiTahsinLT3Q?.errors.tanggal && (
              <div className="text-danger mt-2">
                {formikNilaiTahsinLT3Q?.errors.tanggal}
              </div>
            )}
          </Col>
          <Col lg={6}>
            <Select2
              label="Kelompok TTQ"
              isRequired
              placeholder="Pilih Kelompok TTQ"
              options={optionsKelompokLT3Q}
              isLoading={isLoadingGetAllKelompokLT3Q}
              onChange={(e: any) => {
                const value = e?.value;
                updateState({
                  id_kelompok_lt3q: value,
                  size: 999999999999,
                });
                formikNilaiTahsinLT3Q?.setFieldValue("id_kelompok_lt3q", value);
                formikNilaiTahsinLT3Q?.setFieldValue("detail", []);
                setIdKelompokLT3Q(value);
              }}
              value={
                formikNilaiTahsinLT3Q?.getFieldProps("id_kelompok_lt3q")
                  .value ?? null
              }
            />
            {submitting && formikNilaiTahsinLT3Q?.errors.id_kelompok_lt3q && (
              <div className="text-danger mt-2">
                {formikNilaiTahsinLT3Q?.errors.id_kelompok_lt3q}
              </div>
            )}
          </Col>
        </Row>

        {!formikNilaiTahsinLT3Q?.getFieldProps("id_kelompok_lt3q").value ? (
          <Alert key={"danger"} variant="danger" className="fs-6 mt-4">
            <i className="fa fa-warning me-2"></i>
            SILAHKAN PILIH KELOMPOK LT3Q TERLEBIH DAHULU
          </Alert>
        ) : (
          <>
            <hr className="bg-light mt-4" />
            <h6 className="">Kelola Nilai Tahsin Anggota Kelompok TTQ</h6>
            <hr className="bg-light" />
            <Toolbar />
            <DataTable
              isLoading={isLoadingFetchData}
              header={Columns}
              data={listData?.data && idKelompokLT3Q ? listData?.data : []}
              queryRequest={useQueryRequest}
              isPagination={false}
            />
          </>
        )}
      </Card.Body>

      <Card.Footer className="d-flex justify-content-end align-items-end">
        <button type="submit" className="btn btn-primary btn-sm">
          <i className="fa fa-floppy-disk me-2"></i>
          Simpan
        </button>
      </Card.Footer>
    </Form>
  );
};

export default NilaiTahsin;
