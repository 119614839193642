import { Dispatch, SetStateAction } from "react";
import { Response } from "../global";
import { FormikProps } from "formik";

export type FormSpeakingFocusPayload = {
  id: string | number | null;
  nama: string | null;
};

export type QueryResponseContextProps<T> = {
  listData?: Response<Array<T>>;
  isLoadingFetchData: boolean;
  refetchListData?: () => void;
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  formikSpeakingFocus?: FormikProps<FormSpeakingFocusPayload>;
  formikSpeakingFocusValidationSchema?: any;
  submitting: boolean;
  setSubmitting: Dispatch<SetStateAction<boolean>>;
  handlePostSpeakingFocus: (data: FormData) => void;
  isLoadingPostSpeakingFocus: boolean;
  handleGetDetailSpeakingFocus: (id: string) => void;
  isLoadingGetDetailSpeakingFocus: boolean;
  detailSpeakingFocus: FormSpeakingFocusPayload;
  setDetailSpeakingFocus: Dispatch<SetStateAction<FormSpeakingFocusPayload>>;
  resetForm: boolean;
  setResetForm: Dispatch<SetStateAction<boolean>>;
  handleDeleteSpeakingFocus: (id: string) => void;
  isLoadingDeleteSpeakingFocus: boolean;
};

export const initialQueryResponse: QueryResponseContextProps<any> = {
  isLoadingFetchData: false,
  showModal: false,
  setShowModal: () => {},
  detailSpeakingFocus: {
    id: null,
    nama: null,
  },
  setDetailSpeakingFocus: () => {},
  handleDeleteSpeakingFocus: () => {},
  isLoadingDeleteSpeakingFocus: false,
  handleGetDetailSpeakingFocus: () => {},
  isLoadingGetDetailSpeakingFocus: false,
  handlePostSpeakingFocus: () => {},
  isLoadingPostSpeakingFocus: false,
  resetForm: false,
  setResetForm: () => {},
  submitting: false,
  setSubmitting: () => {},
};
