import axios, { AxiosResponse } from "axios";
import { client, client_sso } from "../apiClient";
import { ApiConfig } from "../apiConfig";

const getDetailReportHasilKinerja = async (
  nik_gtk: string,
  tahun: number,
  tw: number,
  status_dokumen?: number
): Promise<any> => {
  return client
    .get(
      `${
        ApiConfig.GET_DETAIL_RAPORT_HASIL_KINERJA_URL
      }?nik_gtk=${nik_gtk}&tahun=${tahun}&tw=${tw}&status_dokumen=${
        status_dokumen ?? ""
      }`
    )
    .then((response: AxiosResponse<any>) => response.data);
};

const postRaportHasilKinerja = async (data: FormData): Promise<any> => {
  return client
    .post(`${ApiConfig.POST_RAPORT_HASIL_KINERJA_URL}`, data)
    .then((response: AxiosResponse<any>) => response.data);
};

const approveRaportHasilKinerja = async (id: string): Promise<any> => {
  return client
    .put(`${ApiConfig.APPROVE_RAPORT_HASIL_KINERJA_URL}/${id}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const exportPDFRaportHasilKinerja = async (
  nik_gtk: string,
  tahun: number,
  tw: number
): Promise<any> => {
  return axios({
    url: `${ApiConfig.EXPORT_PDF_RAPORT_HASIL_KINERJA_URL}?nik_gtk=${nik_gtk}&tahun=${tahun}&tw=${tw}`, //your url
    method: "GET",
    responseType: "blob", // important
    baseURL: process.env.REACT_APP_SIMBI_ENDPOINT_URL,
    timeout: 60000,
    withCredentials: true,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  }).then((response: AxiosResponse<any>) => {
    //console.log(response.data);
    const href = URL.createObjectURL(response.data);

    // create "a" HTML element with href to file & click
    const link = document.createElement("a");
    link.href = href;
    link.setAttribute(
      "download",
      `Raport Hasil Kinerja ${nik_gtk} Triwulan ${tw}.pdf`
    ); //or any other extension
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  });
};

export {
  getDetailReportHasilKinerja,
  postRaportHasilKinerja,
  exportPDFRaportHasilKinerja,
  approveRaportHasilKinerja,
};
