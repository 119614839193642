import { Dispatch, SetStateAction } from "react";
import { Response } from "../../global";
import { FormKelompokBInggrisPayload } from "../kelompok-b-inggris/_queryResponse";

export type FormAnggotaKelompokBInggrisPayload = {
  id: number | null;
  id_kelompok_b_inggris: string | null;
  nik_gtk: string | null;
  nik_tutor_b_inggris: string | null;
};

export type DetailAnggotaKelompokBInggris = {
  id: number | null;
  id_kelompok_b_inggris: string | null;
  nik_gtk: string | null;
  nik_tutor_b_inggris: string | null;
  gtk: any;
};

export type QueryResponseContextProps<T> = {
  query?: string;
  listData?: Response<Array<T>>;
  isLoadingFetchData: boolean;
  refetchListData?: () => void;
  handlePostAnggotaKelompokBInggris?: (data: FormData) => void;
  isLoadingPostAnggotaKelompokBInggris: boolean;
  resetForm: boolean;
  setResetForm: Dispatch<SetStateAction<boolean>>;
  handleDeleteAnggotaKelompokBInggris?: (id: string) => void;
  isLoadingDeleteAnggotaKelompokBInggris?: boolean;
  handleGetDetailAnggotaKelompokBInggris?: (id: string) => void;
  isLoadingGetDetailAnggotaKelompokBInggris: boolean;
  detailAnggotaKelompokBInggris?: FormAnggotaKelompokBInggrisPayload;
  setDetailAnggotaKelompokBInggris: Dispatch<
    SetStateAction<FormAnggotaKelompokBInggrisPayload>
  >;
  resetDetailAnggotaKelompokBInggris: () => void;
  handleGetAllKelompokBInggris?: (nik_gtk: string, type?: string) => void;
  isLoadingGetAllKelompokBInggris: boolean;
  allKelompokBInggris: Array<FormKelompokBInggrisPayload>;
  setAllKelompokBInggris: Dispatch<
    SetStateAction<Array<FormKelompokBInggrisPayload>>
  >;
  anggotaKelompokBInggris?: Array<DetailAnggotaKelompokBInggris>;
  setAnggotaKelompokBInggris: Dispatch<
    SetStateAction<Array<DetailAnggotaKelompokBInggris>>
  >;
  handleGetAnggotaKelompokBInggris: (id: string) => void;
  isLoadingGetAnggotaKelompokBInggris: boolean;
};

export const initialQueryResponse: QueryResponseContextProps<any> = {
  isLoadingFetchData: false,
  isLoadingPostAnggotaKelompokBInggris: false,
  resetForm: false,
  setResetForm: () => {},
  isLoadingGetDetailAnggotaKelompokBInggris: false,
  isLoadingDeleteAnggotaKelompokBInggris: false,
  setDetailAnggotaKelompokBInggris: () => {},
  resetDetailAnggotaKelompokBInggris: () => {},
  isLoadingGetAllKelompokBInggris: false,
  setAllKelompokBInggris: () => {},
  allKelompokBInggris: [],
  setAnggotaKelompokBInggris: () => {},
  handleGetAnggotaKelompokBInggris: () => {},
  isLoadingGetAnggotaKelompokBInggris: false,
};
