import qs from "qs";

function stringifyRequestQuery(state: any, type: string): string {
  //console.log(type);
  let pagination: any;
  const sort = qs.stringify(state, {
    filter: ["sort", "order"],
    skipNulls: true,
  });

  const filter = qs.stringify(state.filter, {
    skipNulls: true,
  });

  switch (type) {
    case "default-query":
      pagination = qs.stringify(state, {
        filter: [
          "search",
          "size",
          "page",
          "sort_by",
          "sort_type",
          "col_header",
          "filter",
        ],
        skipNulls: true,
      });
      break;
    case "status-aktivasi-aktif":
      pagination = qs.stringify(state, {
        filter: [
          "search",
          "size",
          "page",
          "sort_by",
          "sort_type",
          "col_header",
          "filter",
        ],
        skipNulls: true,
      });
      break;
    case "status-aktivasi-tidak-aktif":
      pagination = qs.stringify(state, {
        filter: [
          "search",
          "size",
          "page",
          "sort_by",
          "sort_type",
          "col_header",
          "filter",
        ],
        skipNulls: true,
      });
      break;

    case "data-gtk":
      pagination = qs.stringify(state, {
        filter: [
          "search",
          "size",
          "page",
          "sort_by",
          "sort_type",
          "col_header",
          "filter",
        ],
        skipNulls: true,
      });
      break;

    case "dashboard":
      pagination = qs.stringify(state, {
        filter: [
          "search",
          "size",
          "page",
          "sort_by",
          "sort_type",
          "col_header",
          "filter",
          "status_bekerja",
          "status_aktivasi",
        ],
        skipNulls: true,
      });
      break;
    case "filter-data":
      pagination = qs.stringify(state, {
        filter: ["table_name", "group_by"],
        skipNulls: false,
      });
      break;
    case "memo":
      pagination = qs.stringify(state, {
        filter: [
          "search",
          "size",
          "page",
          "sort_by",
          "sort_type",
          "col_header",
          "nik_pengirim",
          "nik_tujuan",
        ],
        skipNulls: true,
      });
      break;

    case "absensi-bpi":
      pagination = qs.stringify(state, {
        filter: [
          "search",
          "size",
          "page",
          "sort_by",
          "sort_type",
          "col_header",
          "filter",
          "nik_gtk_tutor",
          "bulan",
          "tahun",
          "id_kelompok_bpi",
        ],
        skipNulls: true,
      });
      break;

    case "kelompok-bpi":
      pagination = qs.stringify(state, {
        filter: [
          "search",
          "size",
          "page",
          "sort_by",
          "sort_type",
          "col_header",
          "nik_gtk",
        ],
        skipNulls: true,
      });
      break;

    case "anggota-kelompok-bpi":
      pagination = qs.stringify(state, {
        filter: [
          "search",
          "size",
          "page",
          "sort_by",
          "sort_type",
          "col_header",
          "nik_tutor_bpi",
        ],
        skipNulls: true,
      });
      break;

    case "kehadiran-bpi":
      pagination = qs.stringify(state, {
        filter: [
          "search",
          "size",
          "page",
          "sort_by",
          "sort_type",
          "col_header",
          "nik_tutor_bpi",
          "id_kelompok_bpi",
          "id_riwayat_bpi",
        ],
        skipNulls: true,
      });
      break;

    case "absensi-lt3q":
      pagination = qs.stringify(state, {
        filter: [
          "search",
          "size",
          "page",
          "sort_by",
          "sort_type",
          "col_header",
          "filter",
          "nik_gtk_tutor",
          "bulan",
          "tahun",
          "id_kelompok_lt3q",
        ],
        skipNulls: true,
      });
      break;

    case "kelompok-lt3q":
      pagination = qs.stringify(state, {
        filter: [
          "search",
          "size",
          "page",
          "sort_by",
          "sort_type",
          "col_header",
          "nik_gtk",
        ],
        skipNulls: true,
      });
      break;

    case "anggota-kelompok-lt3q":
      pagination = qs.stringify(state, {
        filter: [
          "search",
          "size",
          "page",
          "sort_by",
          "sort_type",
          "col_header",
          "nik_tutor_lt3q",
        ],
        skipNulls: true,
      });
      break;

    case "kehadiran-lt3q":
      pagination = qs.stringify(state, {
        filter: [
          "search",
          "size",
          "page",
          "sort_by",
          "sort_type",
          "col_header",
          "nik_tutor_lt3q",
          "id_kelompok_lt3q",
          "id_riwayat_lt3q",
        ],
        skipNulls: true,
      });
      break;

    case "nilai-tahsin-lt3q":
      pagination = qs.stringify(state, {
        filter: [
          "search",
          "size",
          "page",
          "sort_by",
          "sort_type",
          "col_header",
          "nik_tutor_lt3q",
          "id_kelompok_lt3q",
        ],
        skipNulls: true,
      });
      break;

    case "absensi-b-inggris":
      pagination = qs.stringify(state, {
        filter: [
          "search",
          "size",
          "page",
          "sort_by",
          "sort_type",
          "col_header",
          "filter",
          "nik_gtk_tutor",
          "bulan",
          "tahun",
          "id_kelompok_b_inggris",
        ],
        skipNulls: true,
      });
      break;

    case "kelompok-b-inggris":
      pagination = qs.stringify(state, {
        filter: [
          "search",
          "size",
          "page",
          "sort_by",
          "sort_type",
          "col_header",
          "nik_gtk",
        ],
        skipNulls: true,
      });
      break;

    case "anggota-kelompok-b-inggris":
      pagination = qs.stringify(state, {
        filter: [
          "search",
          "size",
          "page",
          "sort_by",
          "sort_type",
          "col_header",
          "nik_tutor_b_inggris",
        ],
        skipNulls: true,
      });
      break;

    case "kehadiran-b-inggris":
      pagination = qs.stringify(state, {
        filter: [
          "search",
          "size",
          "page",
          "sort_by",
          "sort_type",
          "col_header",
          "nik_tutor_b_inggris",
          "id_kelompok_b_inggris",
          "id_riwayat_b_inggris",
        ],
        skipNulls: true,
      });
      break;

    case "menus":
      pagination = qs.stringify(state, {
        filter: ["app_name"],
        skipNulls: false,
      });
      break;

    case "hak-akses":
      pagination = qs.stringify(state, {
        filter: [
          "search",
          "size",
          "page",
          "sort_by",
          "sort_type",
          "col_header",
        ],
        skipNulls: false,
      });
      break;

    case "hak-akses-menu":
      pagination = qs.stringify(state, {
        filter: [
          "search",
          "size",
          "page",
          "sort_by",
          "sort_type",
          "col_header",
          "app_name",
          "rolesId",
        ],
        skipNulls: false,
      });
      break;

    case "default-query-nik-gtk":
      pagination = qs.stringify(state, {
        filter: [
          "search",
          "size",
          "page",
          "sort_by",
          "sort_type",
          "col_header",
          "nik_gtk",
        ],
        skipNulls: true,
      });
      break;

    case "laporan-absensi-bulanan":
      pagination = qs.stringify(state, {
        filter: [
          "search",
          "size",
          "page",
          "sort_by",
          "sort_type",
          "col_header",
          "nik_gtk",
          "bulan",
          "tahun",
        ],
        skipNulls: true,
      });
      break;

    case "laporan-absensi-harian":
      pagination = qs.stringify(state, {
        filter: [
          "search",
          "size",
          "page",
          "sort_by",
          "sort_type",
          "col_header",
          "filter_tanggal",
        ],
        skipNulls: true,
      });
      break;

    case "gtk-tidak-hadir":
      pagination = qs.stringify(state, {
        filter: [
          "search",
          "size",
          "page",
          "sort_by",
          "sort_type",
          "col_header",
          "tanggal",
        ],
        skipNulls: true,
      });
      break;

    case "master-denda-presensi-gtk":
      pagination = qs.stringify(state, {
        filter: [
          "search",
          "size",
          "page",
          "sort_by",
          "sort_type",
          "col_header",
        ],
        skipNulls: true,
      });
      break;

    case "presensi-bpi-saya":
      pagination = qs.stringify(state, {
        filter: ["tanggal_awal", "tanggal_akhir", "nik_gtk"],
        skipNulls: true,
      });
      break;

    case "presensi-lt3q-saya":
      pagination = qs.stringify(state, {
        filter: ["tanggal_awal", "tanggal_akhir", "nik_gtk"],
        skipNulls: true,
      });
      break;
    case "presensi-emc-saya":
      pagination = qs.stringify(state, {
        filter: ["tanggal_awal", "tanggal_akhir", "nik_gtk"],
        skipNulls: true,
      });
      break;

    case "presensi-gtk-saya":
      pagination = qs.stringify(state, {
        filter: ["tanggal_awal", "tanggal_akhir", "nik_gtk"],
        skipNulls: true,
      });
      break;

    case "rekap-absensi-gtk":
      pagination = qs.stringify(state, {
        filter: [
          "search",
          "size",
          "page",
          "sort_by",
          "sort_type",
          "col_header",
          "tanggal_awal",
          "tanggal_akhir",
        ],
        skipNulls: true,
      });
      break;

    case "rekap-absensi-bpi":
      pagination = qs.stringify(state, {
        filter: [
          "search",
          "size",
          "page",
          "sort_by",
          "sort_type",
          "col_header",
          "tanggal_awal",
          "tanggal_akhir",
          "nik_tutor_bpi",
        ],
        skipNulls: true,
      });
      break;

    case "rekap-absensi-b-inggris":
      pagination = qs.stringify(state, {
        filter: [
          "search",
          "size",
          "page",
          "sort_by",
          "sort_type",
          "col_header",
          "tanggal_awal",
          "tanggal_akhir",
          "nik_tutor_b_inggris",
        ],
        skipNulls: true,
      });
      break;

    default:
      pagination = qs.stringify(state, { filter: [], skipNulls: true });
  }

  return [pagination, sort, filter].filter((f) => f).join("&");
  //.toLowerCase();
}

const semuaBulan: Array<any> = [
  {
    label: "Januari",
    value: "01",
  },
  {
    label: "Februari",
    value: "02",
  },
  {
    label: "Maret",
    value: "03",
  },
  {
    label: "April",
    value: "04",
  },
  {
    label: "Mei",
    value: "05",
  },
  {
    label: "Juni",
    value: "06",
  },
  {
    label: "Juli",
    value: "07",
  },
  {
    label: "Agustus",
    value: "08",
  },
  {
    label: "September",
    value: "09",
  },
  {
    label: "Oktober",
    value: "10",
  },
  {
    label: "November",
    value: "11",
  },
  {
    label: "Desember",
    value: "12",
  },
];

const semuaTahun = class {
  data: any = [];
  constructor() {
    const data: any = [];
    const current_year = new Date().getFullYear();
    for (let i = parseInt(current_year.toString()); i >= 1990; i--) {
      data.push({
        label: i,
        value: i,
      });
    }
    this.data = data;
  }
};

const formatRupiah = (angka: string, prefix?: string) => {
  var number_string = angka.replace(/[^,\d]/g, "").toString(),
    split = number_string.split(","),
    sisa = split[0].length % 3,
    rupiah = split[0].substr(0, sisa),
    ribuan = split[0].substr(sisa).match(/\d{3}/gi);
  let separator;
  // tambahkan titik jika yang di input sudah menjadi angka ribuan
  if (ribuan) {
    separator = sisa ? "." : "";
    rupiah += separator + ribuan.join(".");
  }

  rupiah = split[1] != undefined ? rupiah + "," + split[1] : rupiah;
  return prefix == undefined ? rupiah : rupiah ? "Rp. " + rupiah : "";
};

const getChild = (parent_id: string, menus: Array<any>) => {
  let child: any = [];
  menus?.map((val, idx) => {
    if (val.parent_id.toString() === parent_id.toString()) {
      child.push(val);
    }
  });
  return child;
};

export {
  stringifyRequestQuery,
  semuaBulan,
  semuaTahun,
  formatRupiah,
  getChild,
};
