import { Table } from "react-bootstrap";
import NumberInputComponent from "../../../../hope-ui/components/number-input";
import { useQueryResponse } from "../../../../provider/query-response/raport-hasil-kinerja";
import { initialQueryResponse } from "../../../../models/raport-hasil-kinerja/_queryResponse";
import TextareaComponent from "../../../../hope-ui/components/textarea-input";

const EvaluasiKuantitatif = () => {
  const { formikRaportHasilKinerja, submitting } = useQueryResponse();
  const persentaseTingkatKehadiranGTK =
    formikRaportHasilKinerja?.getFieldProps("total_kehadiran_gtk").value > 0
      ? (
          (formikRaportHasilKinerja?.getFieldProps("total_kehadiran_gtk")
            .value /
            formikRaportHasilKinerja?.getFieldProps("jumlah_hari_kerja")
              .value) *
          100
        ).toFixed(2)
      : 0;

  const persentaseTingkatKetepatanHadir =
    formikRaportHasilKinerja?.getFieldProps("total_ketepatan_hadir").value > 0
      ? (
          (formikRaportHasilKinerja?.getFieldProps("total_ketepatan_hadir")
            .value /
            formikRaportHasilKinerja?.getFieldProps("jumlah_hari_kerja")
              .value) *
          100
        ).toFixed(2)
      : 0;

  const persentaseTingkatKehadiranLT3Q =
    formikRaportHasilKinerja?.getFieldProps("total_kehadiran_lt3q").value > 0
      ? (
          (formikRaportHasilKinerja?.getFieldProps("total_kehadiran_lt3q")
            .value /
            formikRaportHasilKinerja?.getFieldProps("jumlah_hari_kerja")
              .value) *
          100
        ).toFixed(2)
      : 0;

  const persentaseTingkatKehadiranBPI =
    formikRaportHasilKinerja?.getFieldProps("total_kehadiran_bpi").value > 0
      ? (
          (formikRaportHasilKinerja?.getFieldProps("total_kehadiran_bpi")
            .value /
            formikRaportHasilKinerja?.getFieldProps("jumlah_hari_kerja")
              .value) *
          100
        ).toFixed(2)
      : 0;

  const persentaseTingkatKehadiranEMC =
    formikRaportHasilKinerja?.getFieldProps("total_kehadiran_emc").value > 0
      ? (
          (formikRaportHasilKinerja?.getFieldProps("total_kehadiran_lt3q")
            .value /
            formikRaportHasilKinerja?.getFieldProps("jumlah_hari_kerja")
              .value) *
          100
        ).toFixed(2)
      : 0;

  let total_skor = 0;
  if (
    persentaseTingkatKehadiranGTK >=
    formikRaportHasilKinerja?.getFieldProps("min_tingkat_kehadiran").value
  ) {
    total_skor++;
  }
  if (
    persentaseTingkatKetepatanHadir >=
    formikRaportHasilKinerja?.getFieldProps("min_tingkat_ketepatan_hadir").value
  ) {
    total_skor++;
  }
  if (
    persentaseTingkatKehadiranGTK >=
    formikRaportHasilKinerja?.getFieldProps("min_tingkat_kehadiran_lt3q").value
  ) {
    total_skor++;
  }
  if (
    persentaseTingkatKehadiranGTK >=
    formikRaportHasilKinerja?.getFieldProps("min_tingkat_kehadiran_bpi").value
  ) {
    total_skor++;
  }
  if (
    persentaseTingkatKehadiranGTK >=
    formikRaportHasilKinerja?.getFieldProps("min_tingkat_kehadiran_emc").value
  ) {
    total_skor++;
  }

  return (
    <div className="p-3">
      <h5>Evaluasi Kuantitatif</h5>
      <hr className="bg-light mb-0" />

      <Table responsive bordered className="fs-6 mt-3">
        <thead>
          <tr>
            <th className="text-center">No.</th>
            <th className="text-center">Alat Ukur</th>
            <th className="text-center">Target</th>
            <th className="text-center">Nilai</th>
            <th className="text-center">Skor</th>
            <th className="text-center">Ket.</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td style={{ minWidth: 250 }}>Tingkat Kehadiran</td>
            <td style={{ minWidth: 150 }}>
              <NumberInputComponent
                required
                formik={formikRaportHasilKinerja?.getFieldProps(
                  "min_tingkat_kehadiran"
                )}
                isSuffix
                suffixText="%"
                value={
                  formikRaportHasilKinerja?.getFieldProps(
                    "min_tingkat_kehadiran"
                  ).value ?? ""
                }
                placeholder={`Default ${initialQueryResponse.detailRaportHasilKinerja.min_tingkat_kehadiran}`}
                disabled={
                  formikRaportHasilKinerja?.getFieldProps("status_dokumen")
                    .value === 2
                }
              />
              {submitting &&
                formikRaportHasilKinerja?.errors.min_tingkat_kehadiran && (
                  <div className="fs-6 text-danger mt-2">
                    {formikRaportHasilKinerja?.errors.min_tingkat_kehadiran}
                  </div>
                )}
            </td>
            <td>{persentaseTingkatKehadiranGTK}%</td>
            <td>
              {persentaseTingkatKehadiranGTK >=
              formikRaportHasilKinerja?.getFieldProps("min_tingkat_kehadiran")
                .value
                ? 1
                : 0}
            </td>
            <td style={{ minWidth: 400 }}>
              <TextareaComponent
                rows={3}
                formik={formikRaportHasilKinerja?.getFieldProps(
                  "ket_tingkat_kehadiran"
                )}
                value={
                  formikRaportHasilKinerja?.getFieldProps(
                    "ket_tingkat_kehadiran"
                  ).value ?? ""
                }
                disabled={
                  formikRaportHasilKinerja?.getFieldProps("status_dokumen")
                    .value === 2
                }
              />
            </td>
          </tr>

          <tr>
            <td>2</td>
            <td>Tingkat Ketepatan Hadir</td>
            <td>
              <NumberInputComponent
                required
                formik={formikRaportHasilKinerja?.getFieldProps(
                  "min_tingkat_ketepatan_hadir"
                )}
                isSuffix
                suffixText="%"
                value={
                  formikRaportHasilKinerja?.getFieldProps(
                    "min_tingkat_ketepatan_hadir"
                  ).value
                }
                disabled={
                  formikRaportHasilKinerja?.getFieldProps("status_dokumen")
                    .value === 2
                }
                placeholder={`Default ${initialQueryResponse.detailRaportHasilKinerja.min_tingkat_ketepatan_hadir}`}
              />
              {submitting &&
                formikRaportHasilKinerja?.errors
                  .min_tingkat_ketepatan_hadir && (
                  <div className="fs-6 text-danger mt-2">
                    {
                      formikRaportHasilKinerja?.errors
                        .min_tingkat_ketepatan_hadir
                    }
                  </div>
                )}
            </td>
            <td>{persentaseTingkatKetepatanHadir}%</td>
            <td>
              {persentaseTingkatKetepatanHadir >=
              formikRaportHasilKinerja?.getFieldProps(
                "min_tingkat_ketepatan_hadir"
              ).value
                ? 1
                : 0}
            </td>
            <td>
              <TextareaComponent
                rows={3}
                formik={formikRaportHasilKinerja?.getFieldProps(
                  "ket_tingkat_ketepatan_hadir"
                )}
                value={
                  formikRaportHasilKinerja?.getFieldProps(
                    "ket_tingkat_ketepatan_hadir"
                  ).value ?? ""
                }
                disabled={
                  formikRaportHasilKinerja?.getFieldProps("status_dokumen")
                    .value === 2
                }
              />
            </td>
          </tr>

          <tr>
            <td>3</td>
            <td>Tingkat Kehadiran TTQ</td>
            <td>
              <NumberInputComponent
                required
                formik={formikRaportHasilKinerja?.getFieldProps(
                  "min_tingkat_kehadiran_lt3q"
                )}
                isSuffix
                suffixText="%"
                value={
                  formikRaportHasilKinerja?.getFieldProps(
                    "min_tingkat_kehadiran_lt3q"
                  ).value ?? ""
                }
                disabled={
                  formikRaportHasilKinerja?.getFieldProps("status_dokumen")
                    .value === 2
                }
                placeholder={`Default ${initialQueryResponse.detailRaportHasilKinerja.min_tingkat_kehadiran_lt3q}`}
              />
              {submitting &&
                formikRaportHasilKinerja?.errors.min_tingkat_kehadiran_lt3q && (
                  <div className="fs-6 text-danger mt-2">
                    {
                      formikRaportHasilKinerja?.errors
                        .min_tingkat_kehadiran_lt3q
                    }
                  </div>
                )}
            </td>
            <td>{persentaseTingkatKehadiranLT3Q}%</td>
            <td>
              {persentaseTingkatKehadiranLT3Q >=
              formikRaportHasilKinerja?.getFieldProps(
                "min_tingkat_kehadiran_lt3q"
              ).value
                ? 1
                : 0}
            </td>
            <td>
              <TextareaComponent
                rows={3}
                formik={formikRaportHasilKinerja?.getFieldProps(
                  "ket_tingkat_kehadiran_lt3q"
                )}
                value={
                  formikRaportHasilKinerja?.getFieldProps(
                    "ket_tingkat_kehadiran_lt3q"
                  ).value ?? ""
                }
                disabled={
                  formikRaportHasilKinerja?.getFieldProps("status_dokumen")
                    .value === 2
                }
              />
            </td>
          </tr>

          <tr>
            <td>4</td>
            <td>Tingkat Kehadiran BPI</td>
            <td>
              <NumberInputComponent
                required
                formik={formikRaportHasilKinerja?.getFieldProps(
                  "min_tingkat_kehadiran_bpi"
                )}
                isSuffix
                suffixText="%"
                value={
                  formikRaportHasilKinerja?.getFieldProps(
                    "min_tingkat_kehadiran_bpi"
                  ).value ?? ""
                }
                disabled={
                  formikRaportHasilKinerja?.getFieldProps("status_dokumen")
                    .value === 2
                }
                placeholder={`Default ${initialQueryResponse.detailRaportHasilKinerja.min_tingkat_kehadiran_bpi}`}
              />
              {submitting &&
                formikRaportHasilKinerja?.errors.min_tingkat_kehadiran_bpi && (
                  <div className="fs-6 text-danger mt-2">
                    {formikRaportHasilKinerja?.errors.min_tingkat_kehadiran_bpi}
                  </div>
                )}
            </td>
            <td>{persentaseTingkatKehadiranBPI}%</td>
            <td>
              {persentaseTingkatKehadiranBPI >=
              formikRaportHasilKinerja?.getFieldProps(
                "min_tingkat_kehadiran_bpi"
              ).value
                ? 1
                : 0}
            </td>
            <td>
              <TextareaComponent
                rows={3}
                formik={formikRaportHasilKinerja?.getFieldProps(
                  "ket_tingkat_kehadiran_bpi"
                )}
                value={
                  formikRaportHasilKinerja?.getFieldProps(
                    "ket_tingkat_kehadiran_bpi"
                  ).value ?? ""
                }
                disabled={
                  formikRaportHasilKinerja?.getFieldProps("status_dokumen")
                    .value === 2
                }
              />
            </td>
          </tr>

          <tr>
            <td>5</td>
            <td>Tingkat Kehadiran EMC</td>
            <td>
              <NumberInputComponent
                required
                formik={formikRaportHasilKinerja?.getFieldProps(
                  "min_tingkat_kehadiran_emc"
                )}
                isSuffix
                suffixText="%"
                value={
                  formikRaportHasilKinerja?.getFieldProps(
                    "min_tingkat_kehadiran_emc"
                  ).value ?? ""
                }
                disabled={
                  formikRaportHasilKinerja?.getFieldProps("status_dokumen")
                    .value === 2
                }
                placeholder={`Default ${initialQueryResponse.detailRaportHasilKinerja.min_tingkat_kehadiran_emc}`}
              />
              {submitting &&
                formikRaportHasilKinerja?.errors.min_tingkat_kehadiran_emc && (
                  <div className="fs-6 text-danger mt-2">
                    {formikRaportHasilKinerja?.errors.min_tingkat_kehadiran_emc}
                  </div>
                )}
            </td>
            <td>{persentaseTingkatKehadiranEMC}%</td>
            <td>
              {persentaseTingkatKehadiranEMC >=
              formikRaportHasilKinerja?.getFieldProps(
                "min_tingkat_kehadiran_emc"
              ).value
                ? 1
                : 0}
            </td>
            <td>
              <TextareaComponent
                rows={3}
                formik={formikRaportHasilKinerja?.getFieldProps(
                  "ket_tingkat_kehadiran_emc"
                )}
                value={
                  formikRaportHasilKinerja?.getFieldProps(
                    "ket_tingkat_kehadiran_emc"
                  ).value ?? ""
                }
                disabled={
                  formikRaportHasilKinerja?.getFieldProps("status_dokumen")
                    .value === 2
                }
              />
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={3} rowSpan={3}></td>
          </tr>
          <tr>
            <td className="text-center">Jumlah</td>
            <td>{total_skor}</td>
            <td rowSpan={3}></td>
          </tr>
          <tr>
            <td className="text-center">Persen</td>
            <td>{((total_skor / 5) * 100).toFixed(2)}%</td>
          </tr>
        </tfoot>
      </Table>
    </div>
  );
};

export default EvaluasiKuantitatif;
