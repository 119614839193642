import { Fragment, useEffect } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useLayout } from "../../layout/core/LayoutProvider";
import { RaportSayaListWrapper } from "../../modules/raport-saya";

const ReportSaya = () => {
  const { setSetting } = useLayout();
  useEffect(() => {
    document.title = "Sistem Informasi Bina Ilmi | Raport Saya";
    setSetting((prevState) => {
      let jasper = Object.assign({}, prevState);
      jasper.page_breadcrumbs = "Raport Saya";
      return jasper;
    });
  }, []);

  return (
    <Fragment>
      <Row>
        <Col md="12" lg="12">
          <Card>
            <RaportSayaListWrapper />
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default ReportSaya;
