import React, { FC, useEffect, useMemo, useState } from "react";
import { Table, Spinner } from "react-bootstrap";
import Pagination from "@mui/material/Pagination";
import { Column, useTable, ColumnInstance, Row } from "react-table";
import { TableHeader } from "./Header/TableHeader";
import { TableRow } from "./Header/TableRow";
import { initialQueryState } from "../../../models/global";

type Props = {
  header?: ReadonlyArray<Column<any>>;
  data?: Array<any>;
  isLoading: boolean;
  pagination?: () => any;
  queryRequest?: () => any;
  CustomHeader?: React.ReactNode | null;
  CustomBody?: React.ReactNode | null;
  totalHeaders?: number;
  bordered?: boolean;
  border?: number;
  isPagination?: boolean;
  totalRows?: number;
};
const DataTable: FC<Props> = ({
  header = [],
  data = [],
  isLoading = false,
  pagination,
  queryRequest,
  CustomHeader,
  CustomBody,
  totalHeaders,
  totalRows,
  bordered,
  border,
  isPagination = true,
}) => {
  const state = queryRequest ? queryRequest() : initialQueryState;
  const responsePagination = pagination ? pagination() : initialQueryState;

  const page = useMemo(() => {
    if (state && state?.state && state?.state.page) {
      if (state?.state.page > responsePagination.count) {
        return 1;
      } else if (state?.state.page <= responsePagination.count) {
        return state?.state.page;
      }
    } else return 0;
  }, [state?.state, responsePagination]);

  useEffect(() => {
    if (state && state?.updateState) {
      state?.updateState({ page: page });
    }
  }, [page]);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    state?.updateState({ page: value });
  };

  const columns = useMemo(() => header, [header]);
  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  let showTo = responsePagination.offset;
  if (!totalRows) showTo += rows.length;
  else if (totalRows) showTo += totalRows;

  return (
    <div className="row gy-3 mt-0">
      <Table
        responsive
        id="datatable"
        className="fs-6"
        data-toggle="data-table"
        {...getTableProps()}
        bordered={bordered}
        border={border}
      >
        {!CustomHeader && (
          <thead>
            <tr>
              {!CustomHeader &&
                headers.map((column: ColumnInstance<any>) => (
                  <TableHeader key={column.id} column={column} />
                ))}
            </tr>
          </thead>
        )}

        {CustomHeader && CustomHeader}
        {!CustomBody && (
          <tbody {...getTableBodyProps()}>
            {rows.length > 0 &&
              !isLoading &&
              rows.map((row: Row<any>, i) => {
                prepareRow(row);
                return <TableRow key={i} row={row} />;
              })}
            {isLoading && (
              <tr>
                <td colSpan={!CustomHeader ? headers.length : totalHeaders}>
                  <div className="d-flex flex-row justify-content-center my-0">
                    <Spinner
                      animation="border"
                      variant="success"
                      style={{
                        width: 50,
                        height: 50,
                      }}
                    />
                  </div>
                </td>
              </tr>
            )}

            {rows.length === 0 && !isLoading && (
              <tr>
                <td colSpan={!CustomHeader ? headers.length : totalHeaders}>
                  <label className="fs-6 text-danger w-100 text-center">
                    Tidak Ada Data Ditemukan
                  </label>
                </td>
              </tr>
            )}

            {rows.length === 1 && !isLoading && (
              <>
                <tr className="border-0">
                  <td className="border-0" colSpan={headers.length}></td>
                </tr>

                <tr className="border-0">
                  <td className="border-0" colSpan={headers.length}></td>
                </tr>
              </>
            )}
          </tbody>
        )}

        {CustomBody && CustomBody}
      </Table>

      {isPagination && (
        <div className="row col-12 mt-2">
          <span className="col-6 d-flex flex-row align-items-center">
            Showing {responsePagination.offset + 1} to {showTo} from{" "}
            {!totalRows ? rows.length : totalRows} entries
          </span>
          <div className="d-flex flex-row justify-content-end col-12 col-md-6">
            <Pagination
              count={responsePagination.count}
              shape="circular"
              page={page ?? 0}
              onChange={handleChange}
              variant="outlined"
              color="primary"
              className="mt-3 "
              sx={{
                "& .Mui-selected": {
                  backgroundColor: "#098206",
                  color: "white",
                  ":hover": {
                    color: "#098206",
                    backgroundColor: "rgb(9, 130, 6)",
                  },
                },
                "& .MuiPaginationItem-root": {
                  ":hover": {
                    backgroundColor: "rgb(226 232 240)",
                    borderColor: "rgb(226 232 240)",
                  }, // Change normal item color
                },
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export { DataTable };
