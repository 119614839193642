import { Header } from "../../../../../../hope-ui/components/table/Header";
import { useQueryRequest } from "../../../../../../provider/query-request/absensi-b-inggris/rekap-absensi";

const TableHeader = () => {
  return (
    <thead>
      <tr>
        <Header
          headerId={"no"}
          title="No."
          className="ps-3 fs-6 text-center align-middle"
          queryRequest={useQueryRequest}
          rowSpan={2}
          isSorting={false}
        />

        <Header
          headerId={"nik_gtk"}
          title="NIK"
          className="ps-3 fs-6 text-center align-middle"
          queryRequest={useQueryRequest}
          rowSpan={2}
          isSorting
        />

        <Header
          headerId={"gtk.nama_gtk"}
          title="Nama Lengkap"
          className="ps-3 fs-6 text-center align-middle"
          queryRequest={useQueryRequest}
          rowSpan={2}
          isSorting
        />

        <Header
          headerId={
            "riwayat_bina_pribadi_islami.kelompok_bpi.tutor_bpi.nama_gtk"
          }
          title="Nama Tutor"
          className="ps-3 fs-6 text-center align-middle"
          queryRequest={useQueryRequest}
          rowSpan={2}
          isSorting
        />

        <Header
          headerId={"kelompok_bpi.nama_kelompok_bpi"}
          title="Nama Kelompok"
          className="ps-3 fs-6 text-center align-middle"
          queryRequest={useQueryRequest}
          rowSpan={2}
          isSorting
        />

        <Header
          headerId={"keterangan"}
          title="Keterangan"
          className="ps-3 fs-6 text-center align-middle"
          colSpan={4}
        />
      </tr>
      <tr>
        <Header
          headerId={"hadir"}
          title="Hadir"
          className="ps-3 fs-6 text-center align-middle text-center"
        />

        <Header
          headerId={"sakit"}
          title="Sakit"
          className="ps-3 fs-6 text-center align-middle text-center"
        />

        <Header
          headerId={"izin"}
          title="Izin"
          className="ps-3 fs-6 text-center align-middle text-center"
        />

        <Header
          headerId={"alfa"}
          title="Alfa"
          className="ps-3 fs-6 text-center align-middle text-center"
        />
      </tr>
    </thead>
  );
};

export default TableHeader;
