import { Dispatch, SetStateAction } from "react";
import { Response } from "../../global";

export type FormKelompokBInggrisPayload = {
  id: number | null;
  nama_kelompok_b_inggris: string | null;
  nik_gtk: string | null;
};

export type QueryResponseContextProps<T> = {
  query?: string;
  listData?: Response<Array<T>>;
  isLoadingFetchData: boolean;
  handlePostKelompokBInggris?: (data: FormData) => void;
  isLoadingPostKelompokBInggris: boolean;
  resetForm: boolean;
  setResetForm: Dispatch<SetStateAction<boolean>>;
  handleGetDetailKelompokBInggris?: (id: string) => void;
  isLoadingGetDetailKelompokBInggris: boolean;
  handleDeleteKelompokBInggris?: (id: string) => void;
  isLoadingDeleteKelompokBInggris?: boolean;
  detailKelompokBInggris?: FormKelompokBInggrisPayload;
  setDetailKelompokBInggris: Dispatch<
    SetStateAction<FormKelompokBInggrisPayload>
  >;
  resetDetailKelompokBInggris: () => void;
};

export const initialQueryResponse: QueryResponseContextProps<any> = {
  isLoadingFetchData: false,
  isLoadingPostKelompokBInggris: false,
  resetForm: false,
  setResetForm: () => {},
  isLoadingGetDetailKelompokBInggris: false,
  isLoadingDeleteKelompokBInggris: false,
  setDetailKelompokBInggris: () => {},
  resetDetailKelompokBInggris: () => {},
};
