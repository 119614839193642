import { Card } from "react-bootstrap";
import {
  QueryResponseProvider,
  useQueryResponse,
} from "../../../provider/query-response/raport-hasil-kinerja";

import {
  QueryResponseProvider as QueryResponseProverDataGTK,
  useQueryResponse as useQueryResponseDataGTK,
  useQueryResponsePagination,
} from "../../../provider/query-response/data-gtk";
import {
  QueryRequestProvider,
  useQueryRequest,
} from "../../../provider/query-request/data-gtk";
import { DataTable } from "../../../hope-ui/components/table/DataTable";
import { Columns } from "./columns";
import Toolbar from "./toolbar";
import ModalRaportHasilKinerja from "../entry/modal";

const RaportHasilKinerjaList = () => {
  const { listData, isLoadingFetchData } = useQueryResponseDataGTK();
  const Data_table = listData?.data;
  return (
    <>
      <Card.Body className="pt-3">
        <ModalRaportHasilKinerja />
        <Toolbar />
        <DataTable
          isLoading={isLoadingFetchData}
          header={Columns}
          data={Data_table}
          pagination={useQueryResponsePagination}
          queryRequest={useQueryRequest}
        />
      </Card.Body>
    </>
  );
};

export const RaportHasilKinerjaListWrapper = () => (
  <QueryResponseProvider>
    <QueryRequestProvider>
      <QueryResponseProverDataGTK>
        <RaportHasilKinerjaList />
      </QueryResponseProverDataGTK>
    </QueryRequestProvider>
  </QueryResponseProvider>
);
