export abstract class ApiConfig {
  private static SSO_URL = process.env.REACT_APP_SIMBI_SSO_URL;
  private static API_URL = process.env.REACT_APP_SIMBI_ENDPOINT_URL;

  //AUTH
  // begin::Login
  public static LOGIN_URL = `${this.API_URL}user/login-sso`;
  public static REFRESH_TOKEN_URL = `${this.API_URL}user/refresh`;
  public static GET_CURRENT_USER_URL = `${this.SSO_URL}auth/detail`;
  public static POST_AUTHENTICATE_URL = `${this.SSO_URL}auth/authenticate`;
  public static LOGOUT = `${this.SSO_URL}auth/logout`;
  public static GET_CHECK_MEMO_URL = `${this.API_URL}memo/check-memo`;
  // end::Login

  //GTK
  //UNIT KERJA
  public static GET_UNIT_KERJA_URL = `${this.API_URL}gtk/unit-kerja`;
  //UNIT KERJA

  //KEPEGAWAIAN
  public static GET_KEPEGAWAIAN_URL = `${this.API_URL}gtk/kepegawaian`;
  public static GET_LIST_KEPEGAWAIAN_URL = `${this.API_URL}kepegawaian`;
  //KEPEGAWAIAN

  //GTK

  //STATUS AKTIVASI
  //AKTIF
  public static GET_STATUS_AKTIVASI_AKTIF_URL = `${this.API_URL}status-aktivasi/aktif`;
  public static POST_AKTIFKAN_GTK_URL = `${this.API_URL}status-aktivasi/aktifkan`;
  //AKTIF
  //TIDAK AKTIF
  public static GET_STATUS_AKTIVASI_TIDAK_AKTIF_URL = `${this.API_URL}status-aktivasi/tidak-aktif`;
  public static POST_NON_AKTIFKAN_GTK_URL = `${this.API_URL}status-aktivasi/non-aktifkan`;
  //TIDAK AKTIF
  //STATUS AKTIVASI

  //DATA GTK
  public static GET_DATA_GTK_URL = `${this.API_URL}data-gtk`;
  public static GET_ALL_DATA_GTK_URL = `${this.API_URL}data-gtk/all`;
  public static GET_DATA_GTK_CHECKLIST_FILTER_URL = `${this.API_URL}data-gtk/checklist-filter`;
  public static POST_DATA_GTK_URL = `${this.API_URL}data-gtk/store`;
  public static PUT_DATA_GTK_URL = `${this.API_URL}data-gtk/update`;
  public static POST_IMPORT_DATA_GTK_URL = `${this.API_URL}data-gtk/import`;
  public static DOWNLOAD_TEMPLATE_IMPORT_DATA_GTK_URL = `${this.API_URL}data-gtk/download-template-import`;
  public static GET_ALL_TABLE_FILTER_GTK_URL = `${this.API_URL}data-gtk/get-all-table-filter-gtk`;
  public static EXPORT_EXCEL_DATA_GTK_URL = `${this.API_URL}data-gtk/export-excel`;
  public static EXPORT_PDF_DATA_GTK_URL = `${this.API_URL}data-gtk/export-pdf`;
  public static GET_ALL_SEBAB_NON_AKTIF_URL = `${this.API_URL}data-gtk/sebab-non-aktif`;
  public static POST_SEBAB_NON_AKTIF_URL = `${this.API_URL}data-gtk/sebab-non-aktif/store`;
  public static PUT_RESET_PASSWORD_GTK_URL = `${this.SSO_URL}users/reset-password`;
  public static GET_DASHBOARD_TOTAL_GTK_URL = `${this.API_URL}data-gtk/dashboard-total`;
  public static GET_DASHBOARD_PRESENSI_GTK = `${this.API_URL}AbsensiGTK/dashboard`;
  //DATA GTK

  //DETAIL GTK
  public static GET_DETAIL_GTK_URL = `${this.API_URL}detail-gtk`;
  public static POST_DETAIL_GTK_URL = `${this.API_URL}detail-gtk/store`;
  //DETAIL GTK

  //FILTER DATA
  public static GET_FILTER_DATA_URL = `${this.API_URL}filter-data`;
  //FILTER DATA

  //TOTAL JENJANG BPI
  public static GET_TOTAL_JENJANG_BPI_URL = `${this.API_URL}anggota-kelompok-bpi/total-jenjang-bpi`;
  //TOTAL JENJANG BPI

  //KEPEGAWAIAN
  public static GET_PERUBAHAN_STATUS_KEPEGAWAIAN_URL = `${this.API_URL}kepegawaian/perubahan-status-kepegawaian`;
  public static GET_DETAIL_PERUBAHAN_STATUS_KEPEGAWAIAN_URL = `${this.API_URL}kepegawaian/detail-perubahan-status-kepegawaian`;
  public static GET_DETAIL_KEPEGAWAIAN_URL = `${this.API_URL}kepegawaian/detail-kepegawaian`;
  public static POST_KEPEGAWAIAN_URL = `${this.API_URL}kepegawaian/store`;
  public static DELETE_KEPEGAWAIAN_URL = `${this.API_URL}kepegawaian/delete`;
  //KEPEGAWAIAN

  //MEMO
  public static GET_LIST_MEMO_URL = `${this.API_URL}memo`;
  public static POST_MEMO_URL = `${this.API_URL}memo/store`;
  public static GET_DETAIL_MEMO_URL = `${this.API_URL}memo/detail`;
  public static GET_DETAIL_MEMO_NOTIF_URL = `${this.API_URL}memo/detail-notif`;
  //MEMO

  //ABSENSI BPI
  public static GET_ABSENSI_BPI_URL = `${this.API_URL}absensi-bpi`;
  public static GET_LAPORAN_ABSENSI_BPI_URL = `${this.API_URL}absensi-bpi/laporan`;
  public static GET_KELOMPOK_BPI_URL = `${this.API_URL}absensi-bpi/kelompok-bpi`;
  public static GET_ALL_KELOMPOK_BPI_URL = `${this.API_URL}absensi-bpi/kelompok-bpi/all`;
  public static POST_KELOMPOK_BPI_URL = `${this.API_URL}absensi-bpi/kelompok-bpi/store`;
  public static GET_DETAIL_KELOMPOK_BPI_URL = `${this.API_URL}absensi-bpi/kelompok-bpi/detail`;
  public static DELETE_KELOMPOK_BPI_URL = `${this.API_URL}absensi-bpi/kelompok-bpi/delete`;
  public static GET_ANGGOTA_KELOMPOK_BPI_URL = `${this.API_URL}absensi-bpi/anggota-kelompok-bpi`;
  public static POST_ANGGOTA_KELOMPOK_BPI_URL = `${this.API_URL}absensi-bpi/anggota-kelompok-bpi/store`;
  public static GET_DETAIL_ANGGOTA_KELOMPOK_BPI_URL = `${this.API_URL}absensi-bpi/anggota-kelompok-bpi/detail`;
  public static DELETE_ANGGOTA_KELOMPOK_BPI_URL = `${this.API_URL}absensi-bpi/anggota-kelompok-bpi/delete`;
  public static GET_ALL_MC_URL = `${this.API_URL}absensi-bpi/all-mc`;
  public static GET_KEHADIRAN_BPI_URL = `${this.API_URL}absensi-bpi/kehadiran-bpi`;
  public static POST_BUAT_ABSENSI_BPI_URL = `${this.API_URL}absensi-bpi/store`;
  public static GET_DETAIL_ABSENSI_BPI_URL = `${this.API_URL}absensi-bpi/detail`;
  public static GET_TOTAL_INFAQ_URL = `${this.API_URL}absensi-bpi/total-infaq`;
  public static DELETE_ABSENSI_BPI_URL = `${this.API_URL}absensi-bpi/delete`;
  public static GET_REKAP_ABSENSI_BPI_URL = `${this.API_URL}absensi-bpi/rekap-absensi-bpi`;
  public static EXPORT_REKAP_ABSENSI_BPI_URL = `${this.API_URL}absensi-bpi/rekap-absensi-bpi/excel`;
  public static POST_PINDAH_TUTOR_BPI_URL = `${this.API_URL}absensi-bpi/pindah-tutor/store`;
  public static GET_PINDAH_TUTOR_BPI_URL = `${this.API_URL}absensi-bpi/pindah-tutor`;
  public static GET_TUTOR_BPI_URL = `${this.API_URL}absensi-bpi/pindah-tutor/all`;
  //ABSENSI BPI

  //ABSENSI GTK
  public static GET_ABSENSI_HARI_INI_URL = `${this.API_URL}AbsensiGTK/absensi-hari-ini`;
  public static GET_GTK_TIDAK_HADIR_URL = `${this.API_URL}AbsensiGTK/gtk-tidak-hadir`;
  public static GET_DETAIL_ABSENSI_GTK_URL = `${this.API_URL}AbsensiGTK/detail-absensi-gtk`;
  public static GET_DETAIL_CATATAN_ABSENSI_GTK_URL = `${this.API_URL}AbsensiGTK/detail-catatan-absensi-gtk`;
  public static PUT_CATATAN_GTK_URL = `${this.API_URL}AbsensiGTK/catatan-absensi-gtk`;
  public static POST_CATATAN_GTK_URL = `${this.API_URL}AbsensiGTK/store-catatan-gtk`;
  public static GET_LAPORAN_ABSENSI_HARIAN_URL = `${this.API_URL}AbsensiGTK/laporan-absensi-harian`;
  public static GET_LAPORAN_ABSENSI_BULANAN_URL = `${this.API_URL}AbsensiGTK/laporan-absensi-bulanan`;
  public static GET_EXPORT_INPUT_EXCEL_URL = `${this.API_URL}AbsensiGTK/export-input-excel`;
  public static POST_IMPORT_PRESENSI_GTK_URL = `${this.API_URL}AbsensiGTK/import-presensi-gtk`;
  public static GET_REKAP_ABSENSI_GTK_URL = `${this.API_URL}AbsensiGTK/rekap-absensi-gtk`;
  public static EXPORT_REKAP_ABSENSI_GTK_URL = `${this.API_URL}AbsensiGTK/rekap-absensi-gtk/excel`;
  //ABSENSI GTK

  //PRIHAL ALASAN ABSENSI GTK
  public static GET_LIST_DATA_PRIHAL_ALASAN_URL = `${this.API_URL}PrihalAlasan/list-data`;
  public static GET_READ_PRIHAL_ALASAN_URL = `${this.API_URL}PrihalAlasan/read-prihal-alasan`;
  public static POST_STORE_PRIHAL_ALASAN_URL = `${this.API_URL}PrihalAlasan/store`;
  public static GET_DETAIL_PRIHAL_ALASAN_URL = `${this.API_URL}PrihalAlasan/detail`;
  public static POST_DELETE_PRIHAL_ALASAN_URL = `${this.API_URL}PrihalAlasan/delete`;
  //PRIHAL ALASAN ABSENSI GTK

  //DENDA ABSENSI GTK
  public static GET_LIST_DENDA_PERSENSI_GTK_URL = `${this.API_URL}DendaAbsensiGTK`;
  public static POST_SAVE_DENDA_PERSENSI_GTK_URL = `${this.API_URL}DendaAbsensiGTK/store`;
  public static DETAIL_DENDA_PERSENSI_GTK_URL = `${this.API_URL}DendaAbsensiGTK/detail`;
  public static DELETE_DENDA_PERSENSI_GTK_URL = `${this.API_URL}DendaAbsensiGTK/delete`;
  //DENDA ABSENSI GTK

  //ABSENSI LT3Q
  public static GET_ABSENSI_LT3Q_URL = `${this.API_URL}absensi-lt3q`;
  public static GET_LAPORAN_ABSENSI_LT3Q_URL = `${this.API_URL}absensi-lt3q/laporan`;
  public static GET_KELOMPOK_LT3Q_URL = `${this.API_URL}absensi-lt3q/kelompok-lt3q`;
  public static GET_ALL_KELOMPOK_LT3Q_URL = `${this.API_URL}absensi-lt3q/kelompok-lt3q/all`;
  public static POST_KELOMPOK_LT3Q_URL = `${this.API_URL}absensi-lt3q/kelompok-lt3q/store`;
  public static GET_DETAIL_KELOMPOK_LT3Q_URL = `${this.API_URL}absensi-lt3q/kelompok-lt3q/detail`;
  public static DELETE_KELOMPOK_LT3Q_URL = `${this.API_URL}absensi-lt3q/kelompok-lt3q/delete`;
  public static GET_ANGGOTA_KELOMPOK_LT3Q_URL = `${this.API_URL}absensi-lt3q/anggota-kelompok-lt3q`;
  public static POST_ANGGOTA_KELOMPOK_LT3Q_URL = `${this.API_URL}absensi-lt3q/anggota-kelompok-lt3q/store`;
  public static GET_DETAIL_ANGGOTA_KELOMPOK_LT3Q_URL = `${this.API_URL}absensi-lt3q/anggota-kelompok-lt3q/detail`;
  public static DELETE_ANGGOTA_KELOMPOK_LT3Q_URL = `${this.API_URL}absensi-lt3q/anggota-kelompok-lt3q/delete`;
  public static GET_KEHADIRAN_LT3Q_URL = `${this.API_URL}absensi-lt3q/kehadiran-lt3q`;
  public static POST_BUAT_ABSENSI_LT3Q_URL = `${this.API_URL}absensi-lt3q/store`;
  public static GET_DETAIL_ABSENSI_LT3Q_URL = `${this.API_URL}absensi-lt3q/detail`;
  public static DELETE_ABSENSI_LT3Q_URL = `${this.API_URL}absensi-lt3q/delete`;
  public static POST_PINDAH_TUTOR_LT3Q_URL = `${this.API_URL}absensi-lt3q/pindah-tutor/store`;
  public static GET_PINDAH_TUTOR_LT3Q_URL = `${this.API_URL}absensi-lt3q/pindah-tutor`;
  public static GET_TUTOR_LT3Q_URL = `${this.API_URL}absensi-lt3q/pindah-tutor/all`;
  public static GET_ALL_NILAI_TAHSIN_URL = `${this.API_URL}nilai-tahsin-lt3q/all`;
  public static POST_NILAI_TAHSIN_URL = `${this.API_URL}nilai-tahsin-lt3q/store`;
  //ABSENSI LT3Q

  //ABSENSI B INGGRIS
  public static GET_ABSENSI_B_INGGRIS_URL = `${this.API_URL}absensi-b-inggris`;
  public static GET_LAPORAN_ABSENSI_B_INGGRIS_URL = `${this.API_URL}absensi-b-inggris/laporan`;
  public static GET_KELOMPOK_B_INGGRIS_URL = `${this.API_URL}absensi-b-inggris/kelompok-b-inggris`;
  public static GET_ALL_KELOMPOK_B_INGGRIS_URL = `${this.API_URL}absensi-b-inggris/kelompok-b-inggris/all`;
  public static POST_KELOMPOK_B_INGGRIS_URL = `${this.API_URL}absensi-b-inggris/kelompok-b-inggris/store`;
  public static GET_DETAIL_KELOMPOK_B_INGGRIS_URL = `${this.API_URL}absensi-b-inggris/kelompok-b-inggris/detail`;
  public static DELETE_KELOMPOK_B_INGGRIS_URL = `${this.API_URL}absensi-b-inggris/kelompok-b-inggris/delete`;
  public static GET_ANGGOTA_KELOMPOK_B_INGGRIS_URL = `${this.API_URL}absensi-b-inggris/anggota-kelompok-b-inggris`;
  public static POST_ANGGOTA_KELOMPOK_B_INGGRIS_URL = `${this.API_URL}absensi-b-inggris/anggota-kelompok-b-inggris/store`;
  public static GET_DETAIL_ANGGOTA_KELOMPOK_B_INGGRIS_URL = `${this.API_URL}absensi-b-inggris/anggota-kelompok-b-inggris/detail`;
  public static DELETE_ANGGOTA_KELOMPOK_B_INGGRIS_URL = `${this.API_URL}absensi-b-inggris/anggota-kelompok-b-inggris/delete`;
  public static GET_KEHADIRAN_B_INGGRIS_URL = `${this.API_URL}absensi-b-inggris/kehadiran-b-inggris`;
  public static POST_BUAT_ABSENSI_B_INGGRIS_URL = `${this.API_URL}absensi-b-inggris/store`;
  public static GET_DETAIL_ABSENSI_B_INGGRIS_URL = `${this.API_URL}absensi-b-inggris/detail`;
  public static DELETE_ABSENSI_B_INGGRIS_URL = `${this.API_URL}absensi-b-inggris/delete`;
  public static POST_PINDAH_TUTOR_B_INGGRIS_URL = `${this.API_URL}absensi-b-inggris/pindah-tutor/store`;
  public static GET_PINDAH_TUTOR_B_INGGRIS_URL = `${this.API_URL}absensi-b-inggris/pindah-tutor`;
  public static GET_TUTOR_B_INGGRIS_URL = `${this.API_URL}absensi-b-inggris/pindah-tutor/all`;
  public static GET_REKAP_ABSENSI_B_INGGRIS_URL = `${this.API_URL}absensi-b-inggris/rekap-absensi-b-inggris`;
  public static EXPORT_REKAP_ABSENSI_B_INGGRIS_URL = `${this.API_URL}absensi-b-inggris/rekap-absensi-b-inggris/excel`;
  //ABSENSI B INGGRIS

  //MASTER DATA
  public static GET_JENIS_PTK_URL = `${this.API_URL}master-data/jenis-ptk`;
  public static GET_STATUS_GOLONGAN_KEPEGAWAIAN_URL = `${this.API_URL}master-data/status-golongan-kepegawaian`;
  public static GET_KOMPETENSI_KHUSUS_URL = `${this.API_URL}master-data/kompetensi-khusus`;
  public static GET_JENIS_KEPEGAWAIAN_URL = `${this.API_URL}master-data/jenis-kepegawaian`;
  public static GET_MASTER_UNIT_KERJA_URL = `${this.API_URL}master-data/unit-kerja`;
  public static GET_STATUS_PERKAWINAN_URL = `${this.API_URL}master-data/status-perkawinan`;
  public static GET_LEVEL_BPI_URL = `${this.API_URL}master-data/level-bpi`;
  //MASTER DATA

  //PENGATURAN
  public static GET_MENU_URL = `pengaturan/menu`;
  public static POST_MENU_URL = `pengaturan/store`;
  public static GET_HAK_AKSES_URL = `pengaturan/hak-akses`;
  public static POST_HAK_AKSES_URL = `pengaturan/hak-akses/store`;
  public static GET_DETAIL_HAK_AKSES_URL = `pengaturan/hak-akses/detail`;
  public static DELETE_HAK_AKSES_URL = `pengaturan/hak-akses/delete`;
  public static GET_HAK_AKSES_MENU_URL = `pengaturan/hak-akses/menu`;
  public static POST_HAK_AKSES_MENU_URL = `pengaturan/hak-akses/menu/store`;
  public static GET_DETAIL_HAK_AKSES_MENU_URL = `pengaturan/hak-akses/menu/detail`;
  public static DELETE_HAK_AKSES_MENU_URL = `pengaturan/hak-akses/menu/delete`;
  public static GET_ALL_APP_NAME_URL = `pengaturan/hak-akses/app-name`;
  public static GET_ALL_HAK_AKSES_URL = `pengaturan/hak-akses/all`;
  public static GET_ALL_MENU_URL = `pengaturan/menu/all`;
  public static POST_ROLES_MENU_URL = `pengaturan/hak-akses/store-roles-menu`;
  //PENGATURAN

  //USER
  public static GET_USER_DETAIL_URL = `users/detail`;
  public static POST_USER_HAK_AKSES_URL = `users/store`;
  //USER

  //INPASSING
  public static POST_INPASSING_URL = `inpassing/store`;

  //RIWAYAT_SERTIFIKASI
  public static POST_RIWAYAT_SERTIFIKASI_URL = `riwayat-sertifikasi/store`;

  //RIWAYAT_SERTIFIKASI
  public static POST_RIWAYAT_GAJI_BERKALA_URL = `riwayat-gaji-berkala/store`;

  //RIWAYAT PANGKAT
  public static POST_RIWAYAT_PANGKAT_URL = `riwayat-pangkat/store`;

  //MUTASI
  public static GET_LIST_MUTASI_URL = `mutasi`;
  public static POST_MUTASI_URL = `mutasi/store`;
  public static DELETE_MUTASI_URL = `mutasi/delete`;
  public static GET_DETAIL_MUTASI_URL = `mutasi/detail`;
  //MUTASI

  //ANAK
  public static GET_LIST_ANAK_URL = `anak`;
  public static POST_ANAK_URL = `anak/store`;
  public static DELETE_ANAK_URL = `anak/delete`;
  public static GET_DETAIL_ANAK_URL = `anak/detail`;
  public static GET_ALL_JENJANG_PENDIDIKAN_URL = `anak/all-jenjang-pendidikan`;
  //ANAK

  //KARYA_BUKU
  public static GET_LIST_KARYA_BUKU_URL = `karya-buku`;
  public static POST_KARYA_BUKU_URL = `karya-buku/store`;
  public static DELETE_KARYA_BUKU_URL = `karya-buku/delete`;
  public static GET_DETAIL_KARYA_BUKU_URL = `karya-buku/detail`;
  //KARYA_BUKU

  //RIWAYAT_PEKERJAAN
  public static GET_LIST_RIWAYAT_PEKERJAAN_URL = `riwayat-pekerjaan`;
  public static POST_RIWAYAT_PEKERJAAN_URL = `riwayat-pekerjaan/store`;
  public static DELETE_RIWAYAT_PEKERJAAN_URL = `riwayat-pekerjaan/delete`;
  public static GET_DETAIL_RIWAYAT_PEKERJAAN_URL = `riwayat-pekerjaan/detail`;
  //RIWAYAT_PEKERJAAN

  //PENDIDIKAN_PELATIHAN
  public static GET_LIST_PENDIDIKAN_PELATIHAN_URL = `pendidikan-pelatihan`;
  public static POST_PENDIDIKAN_PELATIHAN_URL = `pendidikan-pelatihan/store`;
  public static DELETE_PENDIDIKAN_PELATIHAN_URL = `pendidikan-pelatihan/delete`;
  public static GET_DETAIL_PENDIDIKAN_PELATIHAN_URL = `pendidikan-pelatihan/detail`;
  //PENDIDIKAN_PELATIHAN

  //RIWAYAT_PENDIDIKAN
  public static GET_LIST_RIWAYAT_PENDIDIKAN_URL = `riwayat-pendidikan`;
  public static POST_RIWAYAT_PENDIDIKAN_URL = `riwayat-pendidikan/store`;
  public static DELETE_RIWAYAT_PENDIDIKAN_URL = `riwayat-pendidikan/delete`;
  public static GET_DETAIL_RIWAYAT_PENDIDIKAN_URL = `riwayat-pendidikan/detail`;
  //RIWAYAT_PENDIDIKAN

  //KARYA_TULIS
  public static GET_LIST_KARYA_TULIS_URL = `karya-tulis`;
  public static POST_KARYA_TULIS_URL = `karya-tulis/store`;
  public static DELETE_KARYA_TULIS_URL = `karya-tulis/delete`;
  public static GET_DETAIL_KARYA_TULIS_URL = `karya-tulis/detail`;
  //KARYA_TULIS

  //KESEJAHTERAAN
  public static GET_LIST_KESEJAHTERAAN_URL = `kesejahteraan`;
  public static POST_KESEJAHTERAAN_URL = `kesejahteraan/store`;
  public static DELETE_KESEJAHTERAAN_URL = `kesejahteraan/delete`;
  public static GET_DETAIL_KESEJAHTERAAN_URL = `kesejahteraan/detail`;
  //KESEJAHTERAAN

  //PENGHARGAAN
  public static GET_LIST_PENGHARGAAN_URL = `penghargaan`;
  public static POST_PENGHARGAAN_URL = `penghargaan/store`;
  public static DELETE_PENGHARGAAN_URL = `penghargaan/delete`;
  public static GET_DETAIL_PENGHARGAAN_URL = `penghargaan/detail`;
  //PENGHARGAAN

  //PENILAIAN
  public static GET_LIST_PENILAIAN_URL = `penilaian`;
  public static POST_PENILAIAN_URL = `penilaian/store`;
  public static DELETE_PENILAIAN_URL = `penilaian/delete`;
  public static GET_DETAIL_PENILAIAN_URL = `penilaian/detail`;
  //PENILAIAN

  //TUGAS_TAMBAHAN
  public static GET_LIST_TUGAS_TAMBAHAN_URL = `tugas-tambahan`;
  public static POST_TUGAS_TAMBAHAN_URL = `tugas-tambahan/store`;
  public static DELETE_TUGAS_TAMBAHAN_URL = `tugas-tambahan/delete`;
  public static GET_DETAIL_TUGAS_TAMBAHAN_URL = `tugas-tambahan/detail`;
  //TUGAS_TAMBAHAN

  //MAPEL
  public static GET_LIST_MAPEL_URL = `mapel`;
  public static GET_ALL_MAPEL_URL = `mapel/all`;
  public static POST_MAPEL_URL = `mapel/store`;
  public static DELETE_MAPEL_URL = `mapel/delete`;
  public static GET_DETAIL_MAPEL_URL = `mapel/detail`;
  //MAPEL

  //MASTER MAPEL
  public static GET_LIST_MASTER_MAPEL_URL = `master-data/mapel`;
  public static GET_ALL_MASTER_MAPEL_URL = `master-data/mapel/all`;
  public static POST_MASTER_MAPEL_URL = `master-data/mapel/store`;
  public static DELETE_MASTER_MAPEL_URL = `master-data/mapel/delete`;
  public static GET_DETAIL_MASTER_MAPEL_URL = `master-data/mapel/detail`;
  //MASTER MAPEL

  //DAFTAR_UNIT_KERJA
  public static GET_LIST_DAFTAR_UNIT_KERJA_URL = `daftar-unit-kerja`;
  public static GET_ALL_DAFTAR_UNIT_KERJA_URL = `daftar-unit-kerja/all`;
  public static POST_DAFTAR_UNIT_KERJA_URL = `daftar-unit-kerja/store`;
  public static DELETE_DAFTAR_UNIT_KERJA_URL = `daftar-unit-kerja/delete`;
  public static GET_DETAIL_DAFTAR_UNIT_KERJA_URL = `daftar-unit-kerja/detail`;
  //DAFTAR_UNIT_KERJA

  //FILTER_UNIT_KERJA
  public static GET_LIST_FILTER_UNIT_KERJA_URL = `filter-unit-kerja`;
  public static GET_ALL_FILTER_UNIT_KERJA_URL = `filter-unit-kerja/all`;
  public static POST_FILTER_UNIT_KERJA_URL = `filter-unit-kerja/store`;
  public static DELETE_FILTER_UNIT_KERJA_URL = `filter-unit-kerja/delete`;
  public static GET_DETAIL_FILTER_UNIT_KERJA_URL = `filter-unit-kerja/detail`;
  //FILTER_UNIT_KERJA

  //SPEAKING_FOCUS
  public static GET_LIST_SPEAKING_FOCUS_URL = `speaking-focus`;
  public static GET_ALL_SPEAKING_FOCUS_URL = `speaking-focus/all`;
  public static POST_SPEAKING_FOCUS_URL = `speaking-focus/store`;
  public static DELETE_SPEAKING_FOCUS_URL = `speaking-focus/delete`;
  public static GET_DETAIL_SPEAKING_FOCUS_URL = `speaking-focus/detail`;
  //SPEAKING_FOCUS

  //PROFIL
  public static POST_PROFIL_URL = `users/store`;

  //HISTORY BACKUP
  public static GET_HISTORY_BACKUP = `history-backup`;
  public static POST_HISTORY_BACKUP = `history-backup/store`;

  //PRESENSI SAYA
  public static GET_LIST_PRESENSI_BPI_SAYA = `presensi-saya/bpi`;
  public static GET_LIST_PRESENSI_LT3Q_SAYA = `presensi-saya/lt3q`;
  public static GET_LIST_PRESENSI_GTK_SAYA = `presensi-saya/gtk`;
  public static GET_LIST_PRESENSI_EMC_SAYA = `presensi-saya/emc`;

  public static GET_DETAIL_PRESENSI_GTK_DASHBOARD_URL = `${this.API_URL}AbsensiGTK/dashboard/detail-presensi-gtk`;
  public static EXPORT_EXCEL_PRESENSI_GTK_URL = `${this.API_URL}AbsensiGTK/dashboard/excel`;

  //SERTIFIKASI KETERAMPILAN KHUSUS
  public static POST_SERTIFIKASI_KETERAMPILAN_KHUSUS_URL = `sertifikasi-keterampilan-khusus/store`;

  //RAPORT HASIL KINERJA
  public static POST_RAPORT_HASIL_KINERJA_URL = `raport-hasil-kinerja/store`;
  public static APPROVE_RAPORT_HASIL_KINERJA_URL = `raport-hasil-kinerja/approve`;
  public static GET_DETAIL_RAPORT_HASIL_KINERJA_URL = `raport-hasil-kinerja/detail`;
  public static EXPORT_PDF_RAPORT_HASIL_KINERJA_URL = `raport-hasil-kinerja/export-pdf`;
}
