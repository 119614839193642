import { AxiosResponse } from "axios";
import { client, client_sso } from "../apiClient";
import { ApiConfig } from "../apiConfig";

const getCurrentUser = async (): Promise<any> => {
  return client_sso
    .get(`${ApiConfig.GET_CURRENT_USER_URL}?app_name=GTK`)
    .then((response: AxiosResponse<any>) => response.data);
};

const getCheckMemo = async (nik_gtk: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_CHECK_MEMO_URL}?nik_gtk=${nik_gtk}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const postAuthenticate = async (data: FormData): Promise<any> => {
  return client_sso
    .post(`${ApiConfig.POST_AUTHENTICATE_URL}`, data)
    .then((response: AxiosResponse<any>) => response.data);
};

const logout = async (): Promise<any> => {
  return client_sso
    .get(`${ApiConfig.LOGOUT}`)
    .then((response: AxiosResponse<any>) => response.data);
};

export { getCurrentUser, postAuthenticate, logout, getCheckMemo };
