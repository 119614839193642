import { Dispatch, SetStateAction } from "react";
import { Response } from "../global";
import { Dayjs } from "dayjs";
import { FormikProps } from "formik";
import { FormDataGTKPayload } from "../data-gtk/_queryResponse";

export type FormRaportHasilKinerjaPayload = {
  id: string | null;
  nik_gtk: string | null;
  min_tingkat_kehadiran: number | null;
  min_tingkat_ketepatan_hadir: number | null;
  min_tingkat_kehadiran_lt3q: number | null;
  min_tingkat_kehadiran_bpi: number | null;
  min_tingkat_kehadiran_emc: number | null;
  ket_tingkat_kehadiran: number | null;
  ket_tingkat_ketepatan_hadir: number | null;
  ket_tingkat_kehadiran_lt3q: number | null;
  ket_tingkat_kehadiran_bpi: number | null;
  ket_tingkat_kehadiran_emc: number | null;
  catatan: string | null;
  tw: number | null;
  tahun: number | null;
  status_dokumen: number;
  approval: Array<ApprovalRaportHasilKinerja>;
  total_kehadiran_gtk: number | null;
  total_ketepatan_hadir: number | null;
  total_kehadiran_lt3q: number | null;
  total_kehadiran_bpi: number | null;
  total_kehadiran_emc: number | null;
  jumlah_hari_kerja: number | null;
  evaluasi_kualitatif: Array<EvaluasiKualitatif>;
  persentase_tingkat_kehadiran?: number | null;
  persentase_tingkat_ketepatan_hadir?: number | null;
  persentase_tingkat_kehadiran_lt3q?: number | null;
  persentase_tingkat_kehadiran_bpi?: number | null;
  persentase_tingkat_kehadiran_emc?: number | null;
  total_skor?: number | null;
  gtk?: FormDataGTKPayload;
};

export type ApprovalRaportHasilKinerja = {
  id: number | null;
  id_raport_hasil_kinerja: number | null;
  nama_lengkap: string | null;
  jabatan: string | null;
  niy: string | null;
};

export type EvaluasiKualitatif = {
  id: number | null;
  id_raport_hasil_kinerja: number | null;
  alat_ukur: string | null;
  SB: string | number | null;
  B: string | number | null;
  C: string | number | null;
  K: string | number | null;
  KS: string | number | null;
  keterangan: string | null;
};

export type QueryResponseContextProps<T> = {
  formikRaportHasilKinerja?: FormikProps<FormRaportHasilKinerjaPayload>;
  formikRaportHasilKinerjaValidationSchema?: any;
  submitting: boolean;
  setSubmitting: Dispatch<SetStateAction<boolean>>;
  handlePostRaportHasilKinerja: (data: FormData) => void;
  isLoadingPostRaportHasilKinerja: boolean;
  handleGetDetailRaportHasilKinerja: (
    nik_gtk: string,
    tahun: number,
    tw: number,
    status_dokumen?: number
  ) => void;
  isLoadingGetDetailRaportHasilKinerja: boolean;
  detailRaportHasilKinerja: FormRaportHasilKinerjaPayload;
  setDetailRaportHasilKinerja: Dispatch<
    SetStateAction<FormRaportHasilKinerjaPayload>
  >;
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  activeTab: number;
  setActiveTab: Dispatch<SetStateAction<number>>;
  resetForm: boolean;
  setResetForm: Dispatch<SetStateAction<boolean>>;
  handleExportPDFRaportHasilKinerja: (
    nik_gtk: string,
    tahun: number,
    tw: number
  ) => void;
  isLoadingExportPDFRaportHasilKinerja: boolean;
  handleApproveRaportHasilKinerja: (id: string) => void;
  isLoadingApproveRaportHasilKinerja: boolean;
};

export const initialQueryResponse: QueryResponseContextProps<any> = {
  submitting: false,
  setSubmitting: () => {},
  handleGetDetailRaportHasilKinerja: () => {},
  isLoadingGetDetailRaportHasilKinerja: false,
  handlePostRaportHasilKinerja: () => {},
  isLoadingPostRaportHasilKinerja: false,
  detailRaportHasilKinerja: {
    id: null,
    nik_gtk: null,
    approval: [
      {
        id: null,
        id_raport_hasil_kinerja: null,
        nama_lengkap: "",
        jabatan: "",
        niy: "",
      },
      {
        id: null,
        id_raport_hasil_kinerja: null,
        nama_lengkap: "",
        jabatan: "",
        niy: "",
      },
    ],
    catatan: null,
    min_tingkat_kehadiran: 85,
    min_tingkat_kehadiran_bpi: 85,
    min_tingkat_kehadiran_emc: 75,
    min_tingkat_kehadiran_lt3q: 75,
    min_tingkat_ketepatan_hadir: 75,
    ket_tingkat_kehadiran: null,
    ket_tingkat_kehadiran_bpi: null,
    ket_tingkat_kehadiran_emc: null,
    ket_tingkat_kehadiran_lt3q: null,
    ket_tingkat_ketepatan_hadir: null,
    status_dokumen: 0,
    tahun: null,
    tw: 1,
    total_kehadiran_gtk: 0,
    total_ketepatan_hadir: 0,
    total_kehadiran_lt3q: 0,
    total_kehadiran_bpi: 0,
    total_kehadiran_emc: 0,
    jumlah_hari_kerja: 0,
    evaluasi_kualitatif: [
      {
        id: null,
        id_raport_hasil_kinerja: null,
        alat_ukur: "Supervisi penerapan metode pembelajaran terpadu",
        B: "",
        C: "",
        K: "",
        KS: "",
        SB: "",
        keterangan: "",
      },
      {
        id: null,
        id_raport_hasil_kinerja: null,
        alat_ukur: "Pelaksanaan tugas-tugas dan laporan",
        B: "",
        C: "",
        K: "",
        KS: "",
        SB: "",
        keterangan: "",
      },
    ],
  },
  setDetailRaportHasilKinerja: () => {},
  showModal: false,
  setShowModal: () => {},
  activeTab: 1,
  setActiveTab: () => {},
  resetForm: false,
  setResetForm: () => {},
  handleExportPDFRaportHasilKinerja: () => {},
  isLoadingExportPDFRaportHasilKinerja: false,
  handleApproveRaportHasilKinerja: () => {},
  isLoadingApproveRaportHasilKinerja: false,
};
