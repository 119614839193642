import { Col, Row } from "react-bootstrap";
import { useQueryResponse } from "../../../../provider/query-response/raport-hasil-kinerja";

const ApprovalSaya = () => {
  const { detailRaportHasilKinerja } = useQueryResponse();
  return (
    <Row className="mt-3 fs-6">
      <Col xs="6" md="4">
        <div>Mengetahui,</div>
        <div>{detailRaportHasilKinerja?.approval[0].jabatan}</div>
        <div style={{ marginTop: 70 }}>
          {detailRaportHasilKinerja?.approval[0].nama_lengkap}
        </div>
        {detailRaportHasilKinerja?.approval[0].niy && (
          <div>NIY : {detailRaportHasilKinerja?.approval[0].niy}</div>
        )}
      </Col>

      <Col xs="6" md="4">
        <div style={{ color: "transparent" }}>A</div>
        <div>{detailRaportHasilKinerja?.approval[1].jabatan}</div>
        <div style={{ marginTop: 70 }}>
          {detailRaportHasilKinerja?.approval[1].nama_lengkap}
        </div>
        {detailRaportHasilKinerja?.approval[1].niy && (
          <div>NIY : {detailRaportHasilKinerja?.approval[1].niy}</div>
        )}
      </Col>

      <Col sm="6" md="4">
        <div style={{ color: "transparent" }}>A</div>
        <div>PTK,</div>
        <div style={{ marginTop: 70 }}>
          {detailRaportHasilKinerja?.gtk?.nama_gtk}
        </div>
        {detailRaportHasilKinerja?.gtk &&
          detailRaportHasilKinerja?.gtk.kepegawaian &&
          detailRaportHasilKinerja?.gtk?.kepegawaian[0].niy_nigk && (
            <div>
              NIY : {detailRaportHasilKinerja?.gtk?.kepegawaian[0].niy_nigk}
            </div>
          )}
      </Col>
    </Row>
  );
};

export default ApprovalSaya;
