import axios, { AxiosResponse } from "axios";
import { client, client_sso } from "../apiClient";
import { ApiConfig } from "../apiConfig";

const getListPresensiBPISaya = async (query: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_LIST_PRESENSI_BPI_SAYA}?${query}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const getListPresensiLT3QSaya = async (query: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_LIST_PRESENSI_LT3Q_SAYA}?${query}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const getListPresensiGTKSaya = async (query: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_LIST_PRESENSI_GTK_SAYA}?${query}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const getListPresensiEMCSaya = async (query: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_LIST_PRESENSI_EMC_SAYA}?${query}`)
    .then((response: AxiosResponse<any>) => response.data);
};

export {
  getListPresensiBPISaya,
  getListPresensiLT3QSaya,
  getListPresensiGTKSaya,
  getListPresensiEMCSaya,
};
