import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  FormRaportHasilKinerjaPayload,
  initialQueryResponse,
} from "../../../models/raport-hasil-kinerja/_queryResponse";
import { WithChildren } from "../../../helpers/react18MigrationHelpers";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { QUERIES } from "../../../helpers/crud-helpers/const";
import { SweetAlert } from "../../../hope-ui/components/sweet-alert";
import { stringifyRequestQuery } from "../../../helpers/helpers";
import {
  approveRaportHasilKinerja,
  exportPDFRaportHasilKinerja,
  getDetailReportHasilKinerja,
  postRaportHasilKinerja,
} from "../../../api/raport-hasil-kinerja/_request";
import { initialQueryState } from "../../../models/global";
import { useFormik } from "formik";
import * as Yup from "yup";

const QueryResponseContext = createContext(initialQueryResponse);
const QueryResponseProvider: FC<WithChildren> = ({ children }) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<number>(1);
  const queryClient = useQueryClient();
  const [detailRaportHasilKinerja, setDetailRaportHasilKinerja] =
    useState<FormRaportHasilKinerjaPayload>(
      initialQueryResponse.detailRaportHasilKinerja
    );
  const [resetForm, setResetForm] = useState<boolean>(false);

  const [nikGTK, setNikGTK] = useState<string>("");
  const [tahun, setTahun] = useState<number>(0);
  const [tw, setTw] = useState<number>(0);

  useEffect(() => {
    if (resetForm) {
      setResetForm(false);
      setSubmitting(false);
    }
  }, [resetForm]);

  useEffect(() => {
    if (!showModal) {
      setDetailRaportHasilKinerja(
        initialQueryResponse.detailRaportHasilKinerja
      );
      formikRaportHasilKinerja.setValues(
        initialQueryResponse.detailRaportHasilKinerja
      );
      setSubmitting(false);
      setResetForm(false);
      setActiveTab(1);
      setShowModal(false);
    }
  }, [showModal]);

  const {
    mutateAsync: GetDetailRaportHasilKinerja,
    isLoading: isLoadingGetDetailRaportHasilKinerja,
  } = useMutation({
    mutationKey: `${QUERIES.GET_DETAIL_RAPORT_HASIL_KINERJA}`,
    mutationFn: ({
      nik_gtk,
      tahun,
      tw,
      status_dokumen,
    }: {
      nik_gtk: string;
      tahun: number;
      tw: number;
      status_dokumen?: number;
    }) => getDetailReportHasilKinerja(nik_gtk, tahun, tw, status_dokumen),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([
          `${QUERIES.GET_DETAIL_RAPORT_HASIL_KINERJA}`,
        ]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleGetDetailRaportHasilKinerja = useCallback(
    async (
      nik_gtk: string,
      tahun: number,
      tw: number,
      status_dokumen?: number
    ) => {
      setNikGTK(nik_gtk);
      setTahun(tahun);
      setTw(tw);
      await GetDetailRaportHasilKinerja({ nik_gtk, tahun, tw, status_dokumen })
        .then((res) => {
          setDetailRaportHasilKinerja(res.data);
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [GetDetailRaportHasilKinerja]
  );

  const {
    mutateAsync: PostRaportHasilKinerja,
    isLoading: isLoadingPostRaportHasilKinerja,
  } = useMutation({
    mutationKey: `${QUERIES.POST_RAPORT_HASIL_KINERJA}`,
    mutationFn: ({ payload }: { payload: FormData }) =>
      postRaportHasilKinerja(payload),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([
          `${QUERIES.POST_RAPORT_HASIL_KINERJA}`,
        ]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handlePostRaportHasilKinerja = useCallback(
    async (payload: any) => {
      await PostRaportHasilKinerja({ payload })
        .then((res) => {
          SweetAlert({
            icon: res.success ? "success" : "warning",
            title: "Info",
            text: res.message,
            allowOutsideClick: false,
          }).then((swal: any) => {
            if (swal.isConfirmed && res.success) {
              handleGetDetailRaportHasilKinerja(nikGTK, tahun, tw);
              setResetForm(true);
            }
          });
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [PostRaportHasilKinerja]
  );

  const formikRaportHasilKinerjaValidationSchema = Yup.object().shape({
    nik_gtk: Yup.string().required("NIK Tidak Boleh Kosong"),
    tahun: Yup.string().required("Tahun Tidak Boleh Kosong"),
    tw: Yup.string().required("Triwulan Tidak Boleh Kosong"),
    approval: Yup.array().of(
      Yup.object().shape({
        nama_lengkap: Yup.string().required("Nama Lengkap Tidak Boleh Kosong"),
        jabatan: Yup.string().required("Jabatan Tidak Boleh Kosong"),
      })
    ),
    min_tingkat_kehadiran: Yup.string().required(
      "Target Tingkat Kehadiran Tidak Boleh Kosong"
    ),
    min_tingkat_ketepatan_hadir: Yup.string().required(
      "Target Ketepatan Hadir Tidak Boleh Kosong"
    ),
    min_tingkat_kehadiran_lt3q: Yup.string().required(
      "Target Kehadiran LT3Q Tidak Boleh Kosong"
    ),
    min_tingkat_kehadiran_bpi: Yup.string().required(
      "Target Kehadiran BPI Tidak Boleh Kosong"
    ),
    min_tingkat_kehadiran_emc: Yup.string().required(
      "Target Kehadiran EMC Tidak Boleh Kosong"
    ),
    status_dokumen: Yup.string().required("Status Dokumen Tidak Boleh Kosong"),
  });

  const formikRaportHasilKinerja = useFormik<FormRaportHasilKinerjaPayload>({
    initialValues: initialQueryResponse.detailRaportHasilKinerja,
    enableReinitialize: true,
    validationSchema: formikRaportHasilKinerjaValidationSchema,
    onSubmit: (values: any) => {
      console.log(values);
      const payload = new FormData();
      Object.keys(values).map((key, idx) => {
        if (typeof values[key] === "object")
          values[key] = JSON.stringify(values[key]);
        payload.append(key, values[key]);
      });
      handlePostRaportHasilKinerja(payload);
    },
  });

  const {
    mutateAsync: ExportPDFRaportHasilKinerja,
    isLoading: isLoadingExportPDFRaportHasilKinerja,
  } = useMutation({
    mutationKey: `${QUERIES.EXPORT_EXCEL_DATA_GTK}`,
    mutationFn: ({
      nik_gtk,
      tahun,
      tw,
    }: {
      nik_gtk: string;
      tahun: number;
      tw: number;
    }) => exportPDFRaportHasilKinerja(nik_gtk, tahun, tw),
    onSuccess: async (res: any, variables) => {
      try {
        await queryClient.invalidateQueries([
          `${QUERIES.EXPORT_EXCEL_DATA_GTK}`,
        ]);
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleExportPDFRaportHasilKinerja = useCallback(
    async (nik_gtk: string, tahun: number, tw: number) => {
      await ExportPDFRaportHasilKinerja({ nik_gtk, tahun, tw }).catch(
        (error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        }
      );
    },
    [ExportPDFRaportHasilKinerja]
  );

  const {
    mutateAsync: ApproveRaportHasilKinerja,
    isLoading: isLoadingApproveRaportHasilKinerja,
  } = useMutation({
    mutationKey: `${QUERIES.APPROVE_RAPORT_HASIL_KINERJA}`,
    mutationFn: ({ id }: { id: string }) => approveRaportHasilKinerja(id),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([
          `${QUERIES.APPROVE_RAPORT_HASIL_KINERJA}`,
        ]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleApproveRaportHasilKinerja = useCallback(
    async (id: string) => {
      await ApproveRaportHasilKinerja({ id })
        .then((res) => {
          SweetAlert({
            icon: res.success ? "success" : "warning",
            title: "Info",
            text: res.message,
            allowOutsideClick: false,
          }).then((swal: any) => {
            if (swal.isConfirmed && res.success) {
              handleGetDetailRaportHasilKinerja(nikGTK, tahun, tw);
              setResetForm(true);
            }
          });
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [ApproveRaportHasilKinerja]
  );

  const value = useMemo(
    () => ({
      formikRaportHasilKinerja,
      formikRaportHasilKinerjaValidationSchema,
      detailRaportHasilKinerja,
      setDetailRaportHasilKinerja,
      handleGetDetailRaportHasilKinerja,
      isLoadingGetDetailRaportHasilKinerja,
      showModal,
      setShowModal,
      handlePostRaportHasilKinerja,
      isLoadingPostRaportHasilKinerja,
      activeTab,
      setActiveTab,
      submitting,
      setSubmitting,
      resetForm,
      setResetForm,
      handleExportPDFRaportHasilKinerja,
      isLoadingExportPDFRaportHasilKinerja,
      handleApproveRaportHasilKinerja,
      isLoadingApproveRaportHasilKinerja,
    }),
    [
      formikRaportHasilKinerja,
      formikRaportHasilKinerjaValidationSchema,
      detailRaportHasilKinerja,
      setDetailRaportHasilKinerja,
      handleGetDetailRaportHasilKinerja,
      isLoadingGetDetailRaportHasilKinerja,
      showModal,
      setShowModal,
      handlePostRaportHasilKinerja,
      isLoadingPostRaportHasilKinerja,
      activeTab,
      setActiveTab,
      submitting,
      setSubmitting,
      resetForm,
      setResetForm,
      handleExportPDFRaportHasilKinerja,
      isLoadingExportPDFRaportHasilKinerja,
      handleApproveRaportHasilKinerja,
      isLoadingApproveRaportHasilKinerja,
    ]
  );

  return (
    <QueryResponseContext.Provider value={value}>
      {children}
    </QueryResponseContext.Provider>
  );
};

const useQueryResponse = () => useContext(QueryResponseContext);
export { QueryResponseProvider, useQueryResponse };
