import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { initialQueryResponse } from "../../../../models/presensi-saya/presensi-emc/_queryResponse";

import { WithChildren } from "../../../../helpers/react18MigrationHelpers";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { QUERIES } from "../../../../helpers/crud-helpers/const";

import { useAuth } from "../../../core/auth";
import { stringifyRequestQuery } from "../../../../helpers/helpers";
import { useQueryRequest } from "../../../query-request/presensi-saya/presensi-emc";
import { getListPresensiEMCSaya } from "../../../../api/presensi-saya";

const QueryResponseContext = createContext(initialQueryResponse);
const QueryResponseProvider: FC<WithChildren> = ({ children }) => {
  const { state } = useQueryRequest();

  const { currentUser } = useAuth();
  const nik_gtk: any = currentUser?.data?.username;
  state.nik_gtk = nik_gtk;

  const [query, setQuery] = useState<string>(
    stringifyRequestQuery(state, "presensi-emc-saya")
  );
  const updatedQuery = useMemo(
    () => stringifyRequestQuery(state, "presensi-emc-saya"),
    [state]
  );

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery);
    }
  }, [query, updatedQuery]);

  const {
    data: listData,
    isFetching: isLoadingFetchData,
    refetch: refetchListData,
  } = useQuery(
    `${QUERIES.GET_PRESENSI_EMC_SAYA}-${query}`,
    () => getListPresensiEMCSaya(query),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onError: (err) => {
        console.error(err);
      },
      enabled: query !== "?undefined",
    }
  );

  const value = useMemo(
    () => ({ query, listData, isLoadingFetchData, refetchListData }),
    [query, listData, isLoadingFetchData, refetchListData]
  );
  return (
    <QueryResponseContext.Provider value={value}>
      {children}
    </QueryResponseContext.Provider>
  );
};

const useQueryResponse = () => useContext(QueryResponseContext);
export { QueryResponseProvider, useQueryResponse };
