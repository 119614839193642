import { FieldInputProps } from "formik";
import { ChangeEventHandler, FC } from "react";
import { Form, InputGroup } from "react-bootstrap";

type Props = {
  label?: string;
  value?: any;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  placeholder?: string;
  required?: boolean;
  formik?: FieldInputProps<any>;
  ref?: any;
  disabled?: boolean;
  style?: React.CSSProperties;
  readOnly?: boolean;
  isSuffix?: boolean;
  suffixIcon?: any;
  suffixText?: string;
};

const NumberInputComponent: FC<Props> = ({
  label,
  value,
  onChange,
  placeholder,
  required,
  formik,
  ref,
  disabled,
  style,
  readOnly,
  isSuffix,
  suffixIcon,
  suffixText,
}) => {
  return (
    <>
      {label && (
        <Form.Label>
          {label} {required && <span className="text-danger"> * </span>}
        </Form.Label>
      )}
      <InputGroup>
        <Form.Control
          type="number"
          value={value}
          className="form-control"
          onChange={onChange}
          placeholder={placeholder}
          {...formik}
          ref={ref}
          onKeyDown={(e) => {
            if ([69].includes(e.keyCode)) {
              e.preventDefault();
            }
          }}
          onFocus={(e) =>
            e.target.addEventListener("wheel", function (e) {
              e.preventDefault();
            })
          }
          disabled={disabled}
          style={style}
          readOnly={readOnly}
        />
        {isSuffix && (
          <span className="input-group-text">
            {suffixText ? suffixText : suffixIcon ? suffixIcon : ""}
          </span>
        )}
      </InputGroup>
    </>
  );
};

export default NumberInputComponent;
