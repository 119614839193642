import TextareaComponent from "../../../../../../hope-ui/components/textarea-input";
import { useQueryResponse } from "../../../../../../provider/query-response/absensi-bpi/buat-absensi";

import { FC } from "react";

type Props = {
  index: number;
  data: any;
};

export const KeteranganCell: FC<Props> = ({ index, data }) => {
  const { formikBuatAbsensi, currentOffset } = useQueryResponse();
  const value = formikBuatAbsensi?.getFieldProps("kehadiran_bpi").value;

  const find = value?.find((element) => element.id === data.nik_gtk);
  if (find) {
    index = value.indexOf(find);
  }

  //index = index + currentOffset;

  return (
    <div className="px-3">
      <TextareaComponent
        placeholder="Masukkan Keterangan"
        value={
          formikBuatAbsensi?.getFieldProps(`kehadiran_bpi[${index}].keterangan`)
            .value ?? ""
        }
        formik={formikBuatAbsensi?.getFieldProps(
          `kehadiran_bpi[${index}].keterangan`
        )}
        style={{
          minWidth: "250px",
        }}
      />
    </div>
  );
};
