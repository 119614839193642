import { Column } from "react-table";
import { Header } from "../../../hope-ui/components/table/Header";
import { useQueryRequest } from "../../../provider/query-request/denda-persensi-gtk";
import AksiDandaPersensiGTK from "./aksi";
import { formatRupiah } from "../../../helpers/helpers";

export const Columns: ReadonlyArray<Column<any>> = [
  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="No."
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
      />
    ),
    id: "no",
    Cell: ({ ...props }) => (
      <label className="fw-normal text-dark pe-3 text-center w-100">
        {props.row.index + 1}
      </label>
    ),
  },
  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="Jenis Denda"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={true}
      />
    ),
    id: "jenis_denda",
    Cell: ({ ...props }) => (
      <label>{props.data[props.row.index].jenis_denda}</label>
    ),
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="Minimal (Menit)"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={true}
      />
    ),
    id: "minimal",
    Cell: ({ ...props }) => (
      <label>{props.data[props.row.index].minimal}</label>
    ),
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="Maksimal (Menit)"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={true}
      />
    ),
    id: "maksimal",
    Cell: ({ ...props }) => (
      <label>{props.data[props.row.index].maksimal}</label>
    ),
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="Jumlah Nominal"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={true}
      />
    ),
    id: "jumlah_nominal",
    Cell: ({ ...props }) => (
      <label>
        {formatRupiah(props.data[props.row.index].jumlah_nominal, "Rp")}
      </label>
    ),
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="Keterangan"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={true}
      />
    ),
    id: "keterangan",
    Cell: ({ ...props }) => (
      <label>{props.data[props.row.index].keterangan}</label>
    ),
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="Aksi"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
      />
    ),
    id: "action",
    Cell: ({ ...props }) => (
      <AksiDandaPersensiGTK data={props.data[props.row.index]} />
    ),
  },
];
