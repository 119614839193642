import { FC, useEffect, useState } from "react";
import { Card, Col, Form } from "react-bootstrap";
import TextInputComponent from "../../../../../hope-ui/components/text-input/TextInputComponent";
import { DataTable } from "../../../../../hope-ui/components/table/DataTable";
import {
  useQueryResponse,
  useQueryResponsePagination,
} from "../../../../../provider/query-response/absensi-lt3q/kelompok-lt3q/kelola-kelompok";
import { useQueryRequest } from "../../../../../provider/query-request/absensi-lt3q/kelompok-lt3q/kelola-kelompok";
import { Columns } from "./list/columns/column";
import Toolbar from "./toolbar";
import { useFormik } from "formik";
import { FormKelompokLT3QPayload } from "../../../../../models/absensi-lt3q/kelompok-lt3q/_queryResponse";
import * as Yup from "yup";
import FetchLoading from "../../../../../hope-ui/components/fetch-loading";
import { useAuth } from "../../../../../provider/core/auth";
type Props = {
  activeTab: number;
};

const KelolaKelompokLT3Q: FC<Props> = ({ activeTab }) => {
  const { currentUser } = useAuth();
  const {
    listData,
    isLoadingFetchData,
    handlePostKelompokLT3Q,
    isLoadingPostKelompokLT3Q,
    resetForm,
    setResetForm,
    detailKelompokLT3Q,
    isLoadingGetDetailKelompokLT3Q,
    isLoadingDeleteKelompokLT3Q,
    resetDetailKelompokLT3Q,
  } = useQueryResponse();
  const Data_table = listData?.data;
  const formikKelolaKelompokValidationSchema = Yup.object().shape({
    nama_kelompok_lt3q: Yup.string().required(
      "Nama Kelompok Tidak Boleh Kosong"
    ),
  });
  const [submitting, setSubmitting] = useState(false);
  const formikKelolaKelompok = useFormik<FormKelompokLT3QPayload>({
    initialValues: {
      id: null,
      nama_kelompok_lt3q: "",
      nik_gtk: "",
    },
    enableReinitialize: false,
    validationSchema: formikKelolaKelompokValidationSchema,
    onSubmit: (values: any) => {
      const payload = new FormData();
      Object.keys(values).map((key, idx) => {
        payload.append(key, values[key]);
      });
      if (handlePostKelompokLT3Q) handlePostKelompokLT3Q(payload);
    },
  });

  useEffect(() => {
    if (resetForm) {
      formikKelolaKelompok.setFieldValue("nama_kelompok_lt3q", "");
      formikKelolaKelompok.setFieldValue("id", null);
      setSubmitting(false);
      setResetForm(false);
      resetDetailKelompokLT3Q();
    }
  }, [resetForm]);

  useEffect(() => {
    if (currentUser && currentUser.data) {
      const nik_gtk = currentUser.data.username;
      formikKelolaKelompok.setFieldValue("nik_gtk", nik_gtk);
    }
  }, [currentUser]);

  useEffect(() => {
    if (detailKelompokLT3Q && detailKelompokLT3Q.id) {
      formikKelolaKelompok.setValues(detailKelompokLT3Q);
    }
  }, [detailKelompokLT3Q]);

  return (
    <Col
      style={{
        display: activeTab === 1 ? "block" : "none",
      }}
      className="p-4"
    >
      {(isLoadingPostKelompokLT3Q ||
        isLoadingDeleteKelompokLT3Q ||
        isLoadingGetDetailKelompokLT3Q) && <FetchLoading />}
      <Form
        onSubmit={(e) => {
          setSubmitting(true);
          formikKelolaKelompok.submitForm();
          e.preventDefault();
        }}
      >
        <Col>
          <h6>
            {detailKelompokLT3Q?.id ? "Edit" : "Tambah"} Data Kelompok TTQ
          </h6>
          <hr className="bg-light" />
          <Form.Group className="mb-3">
            <TextInputComponent
              label="Nama Kelompok"
              placeholder="Masukkan Nama Kelompok"
              isRequired
              formik={formikKelolaKelompok.getFieldProps("nama_kelompok_lt3q")}
              value={
                formikKelolaKelompok.getFieldProps("nama_kelompok_lt3q")
                  .value ?? ""
              }
            />
            {submitting && formikKelolaKelompok.errors.nama_kelompok_lt3q && (
              <div className="text-danger mt-2">
                {formikKelolaKelompok.errors.nama_kelompok_lt3q}
              </div>
            )}
          </Form.Group>
        </Col>

        <Card.Footer
          className={`p-3 d-flex flex-row justify-content-${
            detailKelompokLT3Q?.id ? "between" : "end"
          }`}
        >
          {detailKelompokLT3Q?.id && (
            <button
              type="button"
              className="btn btn-sm btn-danger"
              onClick={() => setResetForm(true)}
            >
              <i className="fas fa-undo me-2"></i>
              Reset Form
            </button>
          )}

          <button
            type="submit"
            className={`btn btn-${
              detailKelompokLT3Q?.id ? "dark" : "primary"
            } btn-sm `}
          >
            <i
              className={`far fa-${
                detailKelompokLT3Q?.id ? "edit" : "floppy-disk"
              } me-2`}
            ></i>
            {detailKelompokLT3Q?.id ? "Edit" : "Simpan"}
          </button>
        </Card.Footer>
      </Form>

      <Col className="mt-4">
        <h6>Daftar Kelompok TTQ</h6>
        <hr className="bg-light" />
        <Toolbar />
        <DataTable
          isLoading={isLoadingFetchData}
          data={Data_table}
          header={Columns}
          pagination={useQueryResponsePagination}
          queryRequest={useQueryRequest}
        />
      </Col>
    </Col>
  );
};

export default KelolaKelompokLT3Q;
