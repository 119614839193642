import { Card, Col, Row } from "react-bootstrap";
import Datepicker from "../../../../../../hope-ui/components/datepicker";
import { useQueryRequest } from "../../../../../../provider/query-request/absensi-b-inggris/rekap-absensi";
import { useQueryResponse } from "../../../../../../provider/query-response/absensi-b-inggris/rekap-absensi";
import { FC, useState } from "react";
import dayjs from "dayjs";
import { ShowLimitDT } from "../../../../../../hope-ui/components/table/ShowLimit";
import { SearchInputDT } from "../../../../../../hope-ui/components/table/SearchInput";
import { SweetAlert } from "../../../../../../hope-ui/components/sweet-alert";
import FetchLoading from "../../../../../../hope-ui/components/fetch-loading";

type Props = {
  jumlahHari?: number;
};

const Toolbar: FC<Props> = ({ jumlahHari }) => {
  const { state, updateState } = useQueryRequest();
  const [tanggalAwal, setTanggalAwal] = useState(
    state.tanggal_awal?.toString() ?? null
  );
  const [tanggalAkhir, setTanggalAkhir] = useState(
    state.tanggal_akhir?.toString() ?? null
  );
  const {
    handleExportRekapPresensiBInggris,
    isLoadingExportRekapPresensiBInggris,
  } = useQueryResponse();

  return (
    <>
      {isLoadingExportRekapPresensiBInggris && <FetchLoading />}
      <Row className="gx-4 gy-4">
        <Col lg="4">
          <Datepicker
            label="Tanggal Awal"
            required
            value={tanggalAwal}
            onChange={(e) => {
              const value: any = dayjs(e).format("YYYY-MM-DD");
              setTanggalAwal(value);
            }}
          />
        </Col>
        <Col lg="4">
          <Datepicker
            label="Tanggal Akhir"
            required
            value={tanggalAkhir}
            onChange={(e) => {
              const value: any = dayjs(e).format("YYYY-MM-DD");
              setTanggalAkhir(value);
            }}
          />
        </Col>
        <Col lg={3}>
          <div className="h-100 d-flex align-items-end">
            <button
              type="button"
              className="btn btn-sm btn-dark"
              onClick={() => {
                if (tanggalAkhir && tanggalAwal) {
                  if (new Date(tanggalAkhir) < new Date(tanggalAwal)) {
                    return SweetAlert({
                      icon: "error",
                      title: "Terjadi Kesalahan",
                      text: "Tanggal Akhir Tidak Boleh Lebih Kecil Dari Tanggal Awal",
                    });
                  }
                }
                updateState({
                  tanggal_awal: tanggalAwal,
                  tanggal_akhir: tanggalAkhir,
                });
              }}
            >
              Terapkan
              <i className="fa fa-sliders ms-2"></i>
            </button>
          </div>
        </Col>
      </Row>

      <div className="d-flex flex-row justify-content-between align-items-end">
        <h6 className="pt-5">
          Berikut Hasil Dari Presensi{" "}
          <span className="fw-bold text-danger">{jumlahHari}</span> Hari
        </h6>
        <div>
          <button
            type="button"
            className="btn btn-sm btn-primary"
            onClick={() => {
              handleExportRekapPresensiBInggris();
            }}
          >
            Export Excel
            <i className="fa fa-download ms-2"></i>
          </button>
        </div>
      </div>

      <hr className="bg-light" />

      <div className="row pt-0 px-4 mb-0 pb-0">
        <div className="col-md-6 col-12">
          <ShowLimitDT
            onChange={(e) => updateState({ size: parseInt(e.target.value) })}
            limit={state.size}
          />
        </div>
        <div className="col-md-6 col-12 ">
          <SearchInputDT
            state={(keyword: string) => {
              updateState({ search: keyword });
            }}
            value={state.search ? state.search : ""}
          />
        </div>
      </div>
    </>
  );
};

export default Toolbar;
