import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { WithChildren } from "../../../../../helpers/react18MigrationHelpers";
import { useQueryRequest } from "../../../../query-request/absensi-b-inggris/kelompok-b-inggris/kelola-kelompok";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { stringifyRequestQuery } from "../../../../../helpers/helpers";
import { QUERIES } from "../../../../../helpers/crud-helpers/const";
import { initialQueryState } from "../../../../../models/global";
import { SweetAlert } from "../../../../../hope-ui/components/sweet-alert";

import {
  FormKelompokBInggrisPayload,
  initialQueryResponse,
} from "../../../../../models/absensi-b-inggris/kelompok-b-inggris/_queryResponse";
import {
  getKelompokBInggris,
  postKelompokBInggris,
  getDetailKelompokBInggris,
  deleteKelompokBInggris,
} from "../../../../../api/absensi-b-inggris/_request";

import { useQueryResponse as useQueryResponseAbsensiBInggris } from "../../../absensi-b-inggris";
import { useAuth } from "../../../../core/auth";

const QueryResponseContext = createContext(initialQueryResponse);

const QueryResponseProvider: FC<WithChildren> = ({ children }) => {
  const { isShowModalTentukanKelompok } = useQueryResponseAbsensiBInggris();
  const queryClient = useQueryClient();
  const { state } = useQueryRequest();
  state.col_header = `["nama_kelompok_b_inggris"]`;
  const { currentUser } = useAuth();
  state.nik_gtk = currentUser?.data?.username;

  const [resetForm, setResetForm] = useState<boolean>(false);
  const [detailKelompokBInggris, setDetailKelompokBInggris] = useState<any>();

  const [query, setQuery] = useState<string>(
    stringifyRequestQuery(state, "kelompok-b-inggris")
  );
  const updatedQuery = useMemo(
    () => stringifyRequestQuery(state, "kelompok-b-inggris"),
    [state]
  );

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery);
    }
  }, [query, updatedQuery]);

  const {
    mutateAsync: PostKelompokBInggris,
    isLoading: isLoadingPostKelompokBInggris,
  } = useMutation({
    mutationKey: `${QUERIES.POST_KELOMPOK_B_INGGRIS}`,
    mutationFn: ({ payload }: { payload: FormData }) =>
      postKelompokBInggris(payload),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([
          `${QUERIES.POST_KELOMPOK_B_INGGRIS}`,
        ]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handlePostKelompokBInggris = useCallback(
    async (payload: any) => {
      await PostKelompokBInggris({ payload })
        .then((res) => {
          SweetAlert({
            icon: res.success ? "success" : "warning",
            title: "Info",
            text: res.message,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
              if (refetchListData) {
                refetchListData();
                setResetForm(true);
              }
            }
          });
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [PostKelompokBInggris]
  );

  const {
    mutateAsync: GetDetailKelompokBInggris,
    isLoading: isLoadingGetDetailKelompokBInggris,
  } = useMutation({
    mutationKey: `${QUERIES.GET_DETAIL_KELOMPOK_B_INGGRIS}`,
    mutationFn: ({ id }: { id: string }) => getDetailKelompokBInggris(id),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([
          `${QUERIES.GET_DETAIL_KELOMPOK_B_INGGRIS}`,
        ]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleGetDetailKelompokBInggris = useCallback(
    async (id: string) => {
      await GetDetailKelompokBInggris({ id })
        .then((res) => {
          if (res.success) setDetailKelompokBInggris(res.data);
          else {
            SweetAlert({
              icon: res.success ? "success" : "warning",
              title: "Info",
              text: res.message,
              allowOutsideClick: false,
            }).then((swal: any) => {
              if (swal.isConfirmed) {
                //setDetailKelompokBInggris(res.data);
              }
            });
          }
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [GetDetailKelompokBInggris]
  );

  const {
    mutateAsync: DeleteKelompokBInggris,
    isLoading: isLoadingDeleteKelompokBInggris,
  } = useMutation({
    mutationKey: `${QUERIES.GET_DETAIL_KELOMPOK_B_INGGRIS}`,
    mutationFn: ({ id }: { id: string }) => deleteKelompokBInggris(id),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([
          `${QUERIES.GET_DETAIL_KELOMPOK_B_INGGRIS}`,
        ]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleDeleteKelompokBInggris = useCallback(
    async (id: string) => {
      SweetAlert({
        icon: "warning",
        title: "Info",
        text: "Apakah Anda Yakin Ingin Menghapus Data?",
        confirmButtonText: "Lanjutkan",
        cancelButtonText: "Batal",
        isShowCancelButton: true,
      }).then(async (res) => {
        if (res.isConfirmed) {
          await DeleteKelompokBInggris({ id })
            .then((res) => {
              SweetAlert({
                icon: res.success ? "success" : "warning",
                title: "Info",
                text: res.message,
                allowOutsideClick: false,
              }).then((swal: any) => {
                if (swal.isConfirmed && res.success) {
                  if (refetchListData) refetchListData();
                }
              });
            })
            .catch((error) => {
              console.log(error);
              SweetAlert({
                icon: "error",
                title: "Terjadi Kesalahan",
                text: error.data ? error.data.message : error,
                allowOutsideClick: false,
              }).then((res: any) => {
                if (res.isConfirmed) {
                }
              });
            });
        }
      });
    },
    [DeleteKelompokBInggris]
  );

  const {
    data: listData,
    isFetching: isLoadingFetchData,
    refetch: refetchListData,
  } = useQuery(
    `${QUERIES.GET_KELOMPOK_B_INGGRIS}-${query}`,
    () => getKelompokBInggris(query),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onError: (err) => {
        console.error(err);
      },
      enabled: isShowModalTentukanKelompok,
    }
  );

  const resetDetailKelompokBInggris = () => {
    setDetailKelompokBInggris({
      id: null,
      nama_kelompok_b_inggris: null,
      nik_gtk: null,
    });
  };
  const value = useMemo(
    () => ({
      listData,
      refetchListData,
      isLoadingFetchData,
      query,
      handlePostKelompokBInggris,
      isLoadingPostKelompokBInggris,
      resetForm,
      setResetForm,
      handleGetDetailKelompokBInggris,
      isLoadingGetDetailKelompokBInggris,
      detailKelompokBInggris,
      setDetailKelompokBInggris,
      resetDetailKelompokBInggris,
      handleDeleteKelompokBInggris,
      isLoadingDeleteKelompokBInggris,
    }),
    [
      listData,
      refetchListData,
      isLoadingFetchData,
      query,
      handlePostKelompokBInggris,
      isLoadingPostKelompokBInggris,
      resetForm,
      setResetForm,
      handleGetDetailKelompokBInggris,
      isLoadingGetDetailKelompokBInggris,
      detailKelompokBInggris,
      setDetailKelompokBInggris,
      resetDetailKelompokBInggris,
      handleDeleteKelompokBInggris,
      isLoadingDeleteKelompokBInggris,
    ]
  );

  return (
    <QueryResponseContext.Provider value={value}>
      {children}
    </QueryResponseContext.Provider>
  );
};

const useQueryResponse = () => useContext(QueryResponseContext);

const useQueryResponsePagination = () => {
  const defaultPaginationState = {
    ...initialQueryState,
  };

  const { listData } = useQueryResponse();

  return listData ?? defaultPaginationState;
};

export { QueryResponseProvider, useQueryResponse, useQueryResponsePagination };
