import { Card, Col, Row } from "react-bootstrap";
import { Fragment } from "react/jsx-runtime";
import { useLayout } from "../../layout/core/LayoutProvider";
import { useEffect } from "react";
import { SpeakingFocusListWrapper } from "../../modules/speaking-focus";

const SpeakingFocus = () => {
  const { setSetting } = useLayout();
  useEffect(() => {
    document.title = "Sistem Informasi Bina Ilmi | Speaking Focus";
    setSetting((prevState) => {
      let jasper = Object.assign({}, prevState);
      jasper.page_breadcrumbs = "Speaking Focus";
      return jasper;
    });
  }, []);

  return (
    <Fragment>
      <Row>
        <Col md="12" lg="12">
          <Card>
            <SpeakingFocusListWrapper />
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default SpeakingFocus;
