import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import {
  FormDendaPresensiGTKPayload,
  initialQueryResponse,
} from "../../../models/denda-persensi-gtk/_queryResponse";
import { WithChildren } from "../../../helpers/react18MigrationHelpers";
import { Query, useMutation, useQuery, useQueryClient } from "react-query";
import { QUERIES } from "../../../helpers/crud-helpers/const";
import {
  postSaveDendaPersensiGTK,
  getListDendaPersensiGTK,
  getDetailDendaPersensiGTK,
  deleteDendaPersensiGTK,
} from "../../../api/denda-persensi-gtk/_request";
import { SweetAlert } from "../../../hope-ui/components/sweet-alert";
import { useLocation } from "react-router-dom";
import { stringifyRequestQuery } from "../../../helpers/helpers";
import { useQueryRequest } from "../../query-request/denda-persensi-gtk";
import { initialQueryState } from "../../../models/global";
import { useFormik } from "formik";
import * as Yup from "yup";

const QueryResponseContext = createContext(initialQueryResponse);
const QueryResponseProvider: FC<WithChildren> = ({ children }) => {
  const queryClient = useQueryClient();
  const { state, updateState } = useQueryRequest();

  const [showModal, setShowModal] = useState<boolean>(false);

  state.col_header = `["jenis_denda", "maksimal", "minimal", "jumlah_nominal", "keterangan", "parameter"]`;

  const [query, setQuery] = useState<string>(
    stringifyRequestQuery(state, "master-denda-presensi-gtk")
  );
  const updatedQuery = useMemo(
    () => stringifyRequestQuery(state, "master-denda-presensi-gtk"),
    [state]
  );

  const [submitting, setSubmitting] = useState<boolean>(false);
  const [resetForm, setResetForm] = useState<boolean>(false);

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery);
    }
  }, [query, updatedQuery]);

  const [detailDendaPresensiGTK, setDetailDendaPresensiGTK] =
    useState<FormDendaPresensiGTKPayload>(
      initialQueryResponse.detailDendaPresensiGTK
    );

  useEffect(() => {
    if (resetForm) {
      formikDendaPresensiGTK.setValues(
        initialQueryResponse.detailDendaPresensiGTK
      );
      setDetailDendaPresensiGTK(initialQueryResponse.detailDendaPresensiGTK);
      setSubmitting(false);
      setResetForm(false);
    }
  }, [resetForm]);

  const formikDendaPresensiGTKValidationSchema = Yup.object().shape({
    jenis_denda: Yup.string().required("Jenis Denda Kerja Tidak Boleh Kosong"),
    minimal: Yup.string().required("Minimal Tidak Boleh Kosong"),
    maksimal: Yup.string().required("Maksimal Tidak Boleh Kosong"),
    jumlah_nominal: Yup.string().required("Jumlah Nominal Tidak Boleh Kosong"),
  });

  const formikDendaPresensiGTK = useFormik<FormDendaPresensiGTKPayload>({
    initialValues: initialQueryResponse.detailDendaPresensiGTK,
    enableReinitialize: false,
    validationSchema: formikDendaPresensiGTKValidationSchema,
    onSubmit: (values: any) => {
      const payload = new FormData();
      Object.keys(values).map((key, idx) => {
        payload.append(key, values[key]);
      });
      handlePostDendaPresensiGTK(payload);
    },
  });

  const {
    data: listData,
    isFetching: isLoadingFetchData,
    refetch: refetchListData,
  } = useQuery(
    `${QUERIES.GET_LIST_DENDA_ABSENSI_GTK}-${query}`,
    () => getListDendaPersensiGTK(query),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onError: (err) => {
        console.error(err);
      },
      enabled: query !== "?undefined",
    }
  );

  const {
    mutateAsync: PostDendaPresensiGTK,
    isLoading: isLoadingPostDendaPresensiGTK,
  } = useMutation({
    mutationKey: `${QUERIES.POST_SAVE_DENDA_ABSENSI_GTK}`,
    mutationFn: ({ payload }: { payload: FormData }) =>
      postSaveDendaPersensiGTK(payload),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([
          `${QUERIES.POST_SAVE_DENDA_ABSENSI_GTK}`,
        ]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handlePostDendaPresensiGTK = useCallback(
    async (payload: any) => {
      await PostDendaPresensiGTK({ payload })
        .then((res) => {
          SweetAlert({
            icon: res.success ? "success" : "warning",
            title: "Info",
            text: res.message,
            allowOutsideClick: false,
          }).then((swal: any) => {
            if (swal.isConfirmed && res.success) {
              if (refetchListData) {
                setShowModal(false);
                setResetForm(true);
                refetchListData();
              }
            }
          });
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [PostDendaPresensiGTK]
  );

  const {
    mutateAsync: GetDetailDendaPresensiGTK,
    isLoading: isLoadingGetDetailDendaPresensiGTK,
  } = useMutation({
    mutationKey: `${QUERIES.GET_DETAIL_DENDA_ABSENSI_GTK}`,
    mutationFn: ({ id }: { id: string }) => getDetailDendaPersensiGTK(id),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([
          `${QUERIES.GET_DETAIL_DENDA_ABSENSI_GTK}`,
        ]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleGetDetailDendaPresensiGTK = useCallback(
    async (id: string) => {
      await GetDetailDendaPresensiGTK({ id })
        .then((res) => {
          setDetailDendaPresensiGTK(res.data);
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [GetDetailDendaPresensiGTK]
  );

  const {
    mutateAsync: DeleteDendaPresensiGTK,
    isLoading: isLoadingDeleteDendaPresensiGTK,
  } = useMutation({
    mutationKey: `${QUERIES.DELETE_DENDA_PRESENSI_GTK}`,
    mutationFn: ({ id }: { id: string }) => deleteDendaPersensiGTK(id),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([
          `${QUERIES.DELETE_DENDA_PRESENSI_GTK}`,
        ]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleDeleteDendaPresensiGTK = useCallback(
    async (id: string) => {
      SweetAlert({
        icon: "warning",
        title: "Info",
        text: "Apakah Anda Yakin Ingin Menghapus Data?",
        allowOutsideClick: false,
        confirmButtonText: "Lanjutkan",
        cancelButtonText: "Batal",
        isShowCancelButton: true,
      }).then(async (swal: any) => {
        if (swal.isConfirmed) {
          await DeleteDendaPresensiGTK({ id })
            .then((res) => {
              SweetAlert({
                icon: res.success ? "success" : "warning",
                title: "Info",
                text: res.message,
                allowOutsideClick: false,
              }).then((swal: any) => {
                if (swal.isConfirmed) {
                  if (refetchListData) refetchListData();
                }
              });
            })
            .catch((error) => {
              SweetAlert({
                icon: "error",
                title: "Terjadi Kesalahan",
                text: error.data ? error.data.message : error,
                allowOutsideClick: false,
              }).then((res: any) => {
                if (res.isConfirmed) {
                }
              });
            });
        }
      });
    },
    [DeleteDendaPresensiGTK]
  );

  const value = useMemo(
    () => ({
      showModal,
      setShowModal,
      query,
      isLoadingFetchData,
      listData,
      refetchListData,
      handlePostDendaPresensiGTK,
      isLoadingPostDendaPresensiGTK,
      handleGetDetailDendaPresensiGTK,
      isLoadingGetDetailDendaPresensiGTK,
      handleDeleteDendaPresensiGTK,
      isLoadingDeleteDendaPresensiGTK,
      submitting,
      setSubmitting,
      resetForm,
      setResetForm,
      detailDendaPresensiGTK,
      setDetailDendaPresensiGTK,
      formikDendaPresensiGTK,
      formikDendaPresensiGTKValidationSchema,
    }),
    [
      showModal,
      setShowModal,
      query,
      isLoadingFetchData,
      listData,
      refetchListData,
      handlePostDendaPresensiGTK,
      isLoadingPostDendaPresensiGTK,
      handleGetDetailDendaPresensiGTK,
      isLoadingGetDetailDendaPresensiGTK,
      handleDeleteDendaPresensiGTK,
      isLoadingDeleteDendaPresensiGTK,
      submitting,
      setSubmitting,
      resetForm,
      setResetForm,
      detailDendaPresensiGTK,
      setDetailDendaPresensiGTK,
      formikDendaPresensiGTK,
      formikDendaPresensiGTKValidationSchema,
    ]
  );

  return (
    <QueryResponseContext.Provider value={value}>
      {children}
    </QueryResponseContext.Provider>
  );
};

const useQueryResponse = () => useContext(QueryResponseContext);
const useQueryResponsePagination = () => {
  const defaultPaginationState = {
    ...initialQueryState,
  };

  const { listData } = useQueryResponse();

  return listData ?? defaultPaginationState;
};
export { QueryResponseProvider, useQueryResponse, useQueryResponsePagination };
