import { FC, useEffect, useState } from "react";
import { Card, Col, Form } from "react-bootstrap";
import TextInputComponent from "../../../../../hope-ui/components/text-input/TextInputComponent";
import { DataTable } from "../../../../../hope-ui/components/table/DataTable";
import {
  useQueryResponse,
  useQueryResponsePagination,
} from "../../../../../provider/query-response/absensi-b-inggris/kelompok-b-inggris/kelola-kelompok";
import { useQueryRequest } from "../../../../../provider/query-request/absensi-b-inggris/kelompok-b-inggris/kelola-kelompok";
import { Columns } from "./list/columns/column";
import Toolbar from "./toolbar";
import { useFormik } from "formik";
import { FormKelompokBInggrisPayload } from "../../../../../models/absensi-b-inggris/kelompok-b-inggris/_queryResponse";
import * as Yup from "yup";
import FetchLoading from "../../../../../hope-ui/components/fetch-loading";
import { useAuth } from "../../../../../provider/core/auth";
type Props = {
  activeTab: number;
};

const KelolaKelompokBInggris: FC<Props> = ({ activeTab }) => {
  const { currentUser } = useAuth();
  const {
    listData,
    isLoadingFetchData,
    handlePostKelompokBInggris,
    isLoadingPostKelompokBInggris,
    resetForm,
    setResetForm,
    detailKelompokBInggris,
    isLoadingGetDetailKelompokBInggris,
    isLoadingDeleteKelompokBInggris,
    resetDetailKelompokBInggris,
  } = useQueryResponse();
  const Data_table = listData?.data;
  const formikKelolaKelompokValidationSchema = Yup.object().shape({
    nama_kelompok_b_inggris: Yup.string().required(
      "Nama Kelompok Tidak Boleh Kosong"
    ),
  });
  const [submitting, setSubmitting] = useState(false);
  const formikKelolaKelompok = useFormik<FormKelompokBInggrisPayload>({
    initialValues: {
      id: null,
      nama_kelompok_b_inggris: "",
      nik_gtk: "",
    },
    enableReinitialize: false,
    validationSchema: formikKelolaKelompokValidationSchema,
    onSubmit: (values: any) => {
      const payload = new FormData();
      Object.keys(values).map((key, idx) => {
        payload.append(key, values[key]);
      });
      if (handlePostKelompokBInggris) handlePostKelompokBInggris(payload);
    },
  });

  useEffect(() => {
    if (resetForm) {
      formikKelolaKelompok.setFieldValue("nama_kelompok_b_inggris", "");
      formikKelolaKelompok.setFieldValue("id", null);
      setSubmitting(false);
      setResetForm(false);
      resetDetailKelompokBInggris();
    }
  }, [resetForm]);

  useEffect(() => {
    if (currentUser && currentUser.data) {
      const nik_gtk = currentUser.data.username;
      formikKelolaKelompok.setFieldValue("nik_gtk", nik_gtk);
    }
  }, [currentUser]);

  useEffect(() => {
    if (detailKelompokBInggris && detailKelompokBInggris.id) {
      formikKelolaKelompok.setValues(detailKelompokBInggris);
    }
  }, [detailKelompokBInggris]);

  return (
    <Col
      style={{
        display: activeTab === 1 ? "block" : "none",
      }}
      className="p-4"
    >
      {(isLoadingPostKelompokBInggris ||
        isLoadingDeleteKelompokBInggris ||
        isLoadingGetDetailKelompokBInggris) && <FetchLoading />}
      <Form
        onSubmit={(e) => {
          setSubmitting(true);
          formikKelolaKelompok.submitForm();
          e.preventDefault();
        }}
      >
        <Col>
          <h6>
            {detailKelompokBInggris?.id ? "Edit" : "Tambah"} Data Kelompok EMC
          </h6>
          <hr className="bg-light" />
          <Form.Group className="mb-3">
            <TextInputComponent
              label="Nama Kelompok"
              placeholder="Masukkan Nama Kelompok"
              isRequired
              formik={formikKelolaKelompok.getFieldProps(
                "nama_kelompok_b_inggris"
              )}
              value={
                formikKelolaKelompok.getFieldProps("nama_kelompok_b_inggris")
                  .value ?? ""
              }
            />
            {submitting &&
              formikKelolaKelompok.errors.nama_kelompok_b_inggris && (
                <div className="text-danger mt-2">
                  {formikKelolaKelompok.errors.nama_kelompok_b_inggris}
                </div>
              )}
          </Form.Group>
        </Col>

        <Card.Footer
          className={`p-3 d-flex flex-row justify-content-${
            detailKelompokBInggris?.id ? "between" : "end"
          }`}
        >
          {detailKelompokBInggris?.id && (
            <button
              type="button"
              className="btn btn-sm btn-danger"
              onClick={() => setResetForm(true)}
            >
              <i className="fas fa-undo me-2"></i>
              Reset Form
            </button>
          )}

          <button
            type="submit"
            className={`btn btn-${
              detailKelompokBInggris?.id ? "dark" : "primary"
            } btn-sm `}
          >
            <i
              className={`far fa-${
                detailKelompokBInggris?.id ? "edit" : "floppy-disk"
              } me-2`}
            ></i>
            {detailKelompokBInggris?.id ? "Edit" : "Simpan"}
          </button>
        </Card.Footer>
      </Form>

      <Col className="mt-4">
        <h6>Daftar Kelompok EMC</h6>
        <hr className="bg-light" />
        <Toolbar />
        <DataTable
          isLoading={isLoadingFetchData}
          data={Data_table}
          header={Columns}
          pagination={useQueryResponsePagination}
          queryRequest={useQueryRequest}
        />
      </Col>
    </Col>
  );
};

export default KelolaKelompokBInggris;
