import { Column } from "react-table";
import { Header } from "../../../../../../hope-ui/components/table/Header";
import { useQueryRequest } from "../../../../../../provider/query-request/absensi-b-inggris/daftar-absensi";
import moment from "moment";
import AksiCell from "./AksiCell";
import { formatRupiah } from "../../../../../../helpers/helpers";

export const Columns: ReadonlyArray<Column<any>> = [
  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="No."
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
      />
    ),
    id: "no",
    Cell: ({ ...props }) => (
      <label className="fw-normal text-dark pe-3 text-center w-100">
        {props.row.index + 1}
      </label>
    ),
  },
  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="Aksi"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={false}
      />
    ),
    id: "action",
    Cell: ({ ...props }) => <AksiCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="Tanggal"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={true}
      />
    ),
    id: "riwayat_tanggal",
    Cell: ({ ...props }) => (
      <label className="fw-normal text-dark pe-3 text-center w-100">
        <i className="far fa-calendar me-2"></i>
        {moment(props.data[props.row.index].riwayat_tanggal).format(
          "DD MMM YYYY"
        )}
      </label>
    ),
  },
  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="Nama Kelompok"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={true}
      />
    ),
    id: "kelompok_b_inggris.nama_kelompok_b_inggris",
    Cell: ({ ...props }) => (
      <label className="fw-normal text-dark pe-3 text-center w-100">
        {" "}
        {props.data[props.row.index].kelompok_b_inggris.nama_kelompok_b_inggris}
      </label>
    ),
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="Speaking Focus"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={true}
      />
    ),
    id: "m_speaking_focus.nama",
    Cell: ({ ...props }) => (
      <label className="fw-normal text-dark pe-3 text-center w-100">
        {" "}
        {props.data[props.row.index].m_speaking_focus?.nama}
      </label>
    ),
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="Tempat"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={true}
      />
    ),
    id: "tempat",
    Cell: ({ ...props }) => (
      <label className="fw-normal text-dark pe-3 text-center w-100">
        {" "}
        {props.data[props.row.index].tempat}
      </label>
    ),
  },
];
