import { Fragment, useEffect } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useLayout } from "../../layout/core/LayoutProvider";
import { AbsensiBInggrisListWrapper } from "../../modules/absensi-b-inggris/list";

const PresensiBahasaInggris = () => {
  const { setSetting } = useLayout();
  useEffect(() => {
    document.title = "Sistem Informasi Bina Ilmi | Presensi EMC";
    setSetting((prevState) => {
      let jasper = Object.assign({}, prevState);
      jasper.page_breadcrumbs = "Presensi EMC";
      return jasper;
    });
  }, []);

  return (
    <Fragment>
      <Row>
        <Col md="12" lg="12">
          <Card>
            <AbsensiBInggrisListWrapper />
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default PresensiBahasaInggris;
