import { Card, Col, Form, Modal, Row } from "react-bootstrap";
import { useQueryResponse } from "../../../provider/query-response/denda-persensi-gtk";
import { useEffect, useState } from "react";
import FetchLoading from "../../../hope-ui/components/fetch-loading";
import TextInputComponent from "../../../hope-ui/components/text-input/TextInputComponent";
import NumberInputComponent from "../../../hope-ui/components/number-input";
import TextareaComponent from "../../../hope-ui/components/textarea-input";

const ModalDaftarDenda = () => {
  const {
    showModal,
    setShowModal,
    setResetForm,
    formikDendaPresensiGTK,
    submitting,
    setSubmitting,
    isLoadingPostDendaPresensiGTK,
    isLoadingGetDetailDendaPresensiGTK,
    isLoadingDeleteDendaPresensiGTK,
    detailDendaPresensiGTK,
  } = useQueryResponse();

  useEffect(() => {
    if (detailDendaPresensiGTK.id) {
      formikDendaPresensiGTK?.setValues(detailDendaPresensiGTK);
    }
  }, [detailDendaPresensiGTK]);

  return (
    <>
      <button
        type="button"
        className="btn btn-sm btn-info float-start"
        onClick={() => {
          setShowModal(true);
        }}
      >
        <i className="fa fa-users me-2"></i> Tambah Denda
      </button>

      <Modal
        backdrop="static"
        show={showModal}
        onBackdropClick={() => {
          setShowModal(false);
          setResetForm(true);
        }}
        size="xl"
        onHide={() => {
          setShowModal(false);
          setResetForm(true);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {detailDendaPresensiGTK.id ? "Edit" : "Tambah"} Daftar Denda
          </Modal.Title>
        </Modal.Header>

        {(isLoadingPostDendaPresensiGTK ||
          isLoadingGetDetailDendaPresensiGTK ||
          isLoadingDeleteDendaPresensiGTK) && <FetchLoading />}
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            setSubmitting(true);
            formikDendaPresensiGTK?.submitForm();
          }}
        >
          <Modal.Body>
            <Card.Body className="pt-3">
              <Row className="gy-4">
                <Col lg="6">
                  <TextInputComponent
                    label="Jenis Denda"
                    isRequired
                    placeholder="Masukkan Jenis Denda"
                    formik={formikDendaPresensiGTK?.getFieldProps(
                      "jenis_denda"
                    )}
                  />
                  {submitting && formikDendaPresensiGTK?.errors.jenis_denda && (
                    <div className="fs-6 text-danger mt-2">
                      {formikDendaPresensiGTK?.errors.jenis_denda}
                    </div>
                  )}
                </Col>

                <Col lg="6">
                  <TextInputComponent
                    label="Minimal (Menit)"
                    isRequired
                    placeholder="Masukkan Minimal (Menit)"
                    formik={formikDendaPresensiGTK?.getFieldProps("minimal")}
                  />
                  {submitting && formikDendaPresensiGTK?.errors.minimal && (
                    <div className="fs-6 text-danger mt-2">
                      {formikDendaPresensiGTK?.errors.minimal}
                    </div>
                  )}
                </Col>

                <Col lg="6">
                  <TextInputComponent
                    label="Maksimal (Menit)"
                    isRequired
                    placeholder="Masukkan Maksimal (Menit)"
                    formik={formikDendaPresensiGTK?.getFieldProps("maksimal")}
                  />
                  {submitting && formikDendaPresensiGTK?.errors.maksimal && (
                    <div className="fs-6 text-danger mt-2">
                      {formikDendaPresensiGTK?.errors.maksimal}
                    </div>
                  )}
                </Col>

                <Col lg="6">
                  <NumberInputComponent
                    label="Jumlah Nominal Denda"
                    required
                    placeholder="Masukkan Jumlah Nominal Denda"
                    formik={formikDendaPresensiGTK?.getFieldProps(
                      "jumlah_nominal"
                    )}
                  />
                  {submitting &&
                    formikDendaPresensiGTK?.errors.jumlah_nominal && (
                      <div className="fs-6 text-danger mt-2">
                        {formikDendaPresensiGTK?.errors.jumlah_nominal}
                      </div>
                    )}
                </Col>

                <Col sm="12">
                  <TextareaComponent
                    label="Keterangan Denda"
                    placeholder="Masukkan Keterangan Denda"
                    formik={formikDendaPresensiGTK?.getFieldProps("keterangan")}
                    value={
                      formikDendaPresensiGTK?.getFieldProps("keterangan")
                        .value ?? null
                    }
                  />
                </Col>
              </Row>
            </Card.Body>
          </Modal.Body>
          <Modal.Footer className="p-3 d-flex flex-row justify-content-end">
            <button className="btn btn-primary btn-sm">
              <i className="fa fa-floppy-disk me-2"></i>
              Simpan
            </button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default ModalDaftarDenda;
