import { Alert, Badge, Offcanvas } from "react-bootstrap";
import { useQueryResponse } from "../../../../provider/query-response/notifikasi";
import EmptyMail from "../../../../../assets/images/icons/empty-mail2.svg";
import { useAuth } from "../../../../provider/core/auth";
import { useEffect } from "react";
import FetchLoadingCompanyLogo from "../../../../hope-ui/components/fetch-loading-with-company-logo";

const OffCanvas = () => {
  const {
    isShowNotifikasi,
    setShowNotifikasi,
    listPesan,
    setShowDetailMemo,
    handleGetDetailMemo,
    handleCheckMemo,
    isLoadingGetCheckMemo,
  } = useQueryResponse();

  const { currentUser } = useAuth();

  useEffect(() => {
    if (currentUser && currentUser.data && currentUser.data.username) {
      handleCheckMemo(currentUser.data.username);
    }
  }, [currentUser]);

  return (
    <Offcanvas
      show={isShowNotifikasi}
      onHide={() => {
        setShowNotifikasi(false);
      }}
      placement={`end`}
      backdrop={false}
      scroll={true}
      className="live-customizer"
    >
      <Offcanvas.Header closeButton>
        <div className="d-flex align-items-center">
          <h4>Pesan Memo</h4>
        </div>
      </Offcanvas.Header>
      <Offcanvas.Body>
        {!isLoadingGetCheckMemo && listPesan && listPesan.length === 0 && (
          <div className="d-flex flex-column align-items-center justify-content-center">
            <img
              src={EmptyMail}
              alt=""
              width={"100%"}
              height={300}
              style={{
                objectFit: "cover",
              }}
            />
            <Alert key={"success"} variant="success" className="fs-6 py-1 px-2">
              Tidak ada pesan
            </Alert>
          </div>
        )}

        {isLoadingGetCheckMemo && <FetchLoadingCompanyLogo />}

        {!isLoadingGetCheckMemo &&
          listPesan &&
          listPesan.map((val) => {
            return (
              <>
                <div
                  className="mb-2 d-flex profile-media align-items-top pb-0"
                  style={{
                    maxHeight: "fit-content",
                  }}
                >
                  <div
                    className="mt-1 profile-dots-pills border-primary"
                    style={{
                      minWidth: "20px",
                    }}
                  ></div>
                  <div className="ms-4 fs-6 w-100">
                    <div
                      className="d-flex justify-content-end"
                      style={{ width: "100%" }}
                    >
                      <Badge
                        bg={
                          val.status_pesan === "Dilihat" ? "primary" : "danger"
                        }
                        style={{
                          bottom: 20,
                          right: -5,
                          fontSize: 11,
                        }}
                      >
                        {val.status_pesan?.toString()}
                      </Badge>
                    </div>
                    <div className="mb-1 text-muted ">
                      Subject :{" "}
                      <span className="text-dark fw-bold">
                        {val.judul_pesan}
                      </span>
                    </div>
                    {/* <div
                      className="mb-1 text-muted ellipsis"
                      style={{
                        maxWidth: 250,
                      }}
                    >
                      Pesan :{" "}
                      <span className="text-dark ">{val.isi_pesan}</span>
                    </div> */}

                    <span
                      className="mb-0"
                      style={{
                        fontSize: 12,
                      }}
                    >
                      Dikirim oleh {val.gtk_pengirim.nama_gtk}, {val.createdAt}
                    </span>
                    <div
                      className="d-flex justify-content-end"
                      style={{ width: "100%" }}
                    >
                      <span
                        className="text-dark fs-6 cursor-pointer-only hover-primary"
                        onClick={() => {
                          const id: any = val.id;
                          setShowDetailMemo(true);
                          handleGetDetailMemo(id);
                        }}
                      >
                        Lihat Detail Pesan
                      </span>
                    </div>

                    <hr className="bg-light" />
                  </div>
                </div>
              </>
            );
          })}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default OffCanvas;
