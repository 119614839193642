import { Button, ButtonGroup, Dropdown } from "react-bootstrap";

import { useQueryResponse } from "../../../provider/query-response/denda-persensi-gtk";
import { useQueryRequest } from "../../../provider/query-request/denda-persensi-gtk";
import { FC } from "react";

type Props = {
  data: any;
};

const AksiDandaPersensiGTK: FC<Props> = ({ data }) => {
  const {
    showModal,
    setShowModal,
    handleGetDetailDendaPresensiGTK,
    handleDeleteDendaPresensiGTK,
  } = useQueryResponse();

  return (
    <>
      <div className="px-4 py-5 ">
        <Dropdown as={ButtonGroup}>
          <Button type="button" size="sm" variant={"primary"}>
            Pilih Aksi
          </Button>
          <Dropdown.Toggle type="button" split variant={"primary"}>
            <span className="visually-hidden">Pilih Aksi</span>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              eventKey="1"
              onClick={(e) => {
                setShowModal(true);
                handleGetDetailDendaPresensiGTK(data.id);
              }}
              className="cursor-pointer"
            >
              <div className="fs-6">
                Edit <i className="fa fa-pencil text-info ms-1"></i>
              </div>
            </Dropdown.Item>
            <Dropdown.Item
              eventKey="2"
              onClick={(e) => {
                handleDeleteDendaPresensiGTK(data.id);
              }}
              className="cursor-pointer"
            >
              <div className="fs-6">
                Hapus <i className="fa fa-trash text-danger ms-1"></i>
              </div>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </>
  );
};

export default AksiDandaPersensiGTK;
