import { Col, Form, Modal, Row } from "react-bootstrap";
import { useQueryResponse } from "../../../../provider/query-response/raport-hasil-kinerja";
import { useQueryResponse as useQueryResponseDetailGTK } from "../../../../provider/query-response/detail-gtk";
import FetchLoading from "../../../../hope-ui/components/fetch-loading";
import { useEffect } from "react";
import GTKCard from "../../../../hope-ui/components/gtk-card";
import EvaluasiKuantitatif from "../evaluasi-kuantitatif";
import Select2 from "../../../../hope-ui/components/select-2";
import FetchLoadingOnly from "../../../../hope-ui/components/fetch-loading-only";
import FetchLoadingCompanyLogo from "../../../../hope-ui/components/fetch-loading-with-company-logo";
import ApprovalRaportHasilKinerja from "../approval";
import { initialQueryResponse } from "../../../../models/raport-hasil-kinerja/_queryResponse";
import EvaluasiKualitatif from "../evaluasi-kualitatif";
import TextareaComponent from "../../../../hope-ui/components/textarea-input";
import CheckInputComponent from "../../../../hope-ui/components/check-input/CheckInputComponent";
import { SweetAlert } from "../../../../hope-ui/components/sweet-alert";

const ModalRaportHasilKinerja = () => {
  const {
    activeTab,
    setActiveTab,
    showModal,
    setShowModal,
    formikRaportHasilKinerja,
    handleGetDetailRaportHasilKinerja,
    isLoadingGetDetailRaportHasilKinerja,
    setResetForm,
    setSubmitting,
    isLoadingPostRaportHasilKinerja,
    detailRaportHasilKinerja,
    handleExportPDFRaportHasilKinerja,
    isLoadingExportPDFRaportHasilKinerja,
    handleApproveRaportHasilKinerja,
    isLoadingApproveRaportHasilKinerja,
  } = useQueryResponse();

  const { handleGetDetailGTK, isLoadingGetDetailGTK, detailGTK } =
    useQueryResponseDetailGTK();

  const tw = ["I", "II", "III", "IV"];

  useEffect(() => {
    if (
      showModal &&
      formikRaportHasilKinerja &&
      formikRaportHasilKinerja.getFieldProps("nik_gtk").value
    ) {
      handleGetDetailGTK(
        formikRaportHasilKinerja?.getFieldProps("nik_gtk").value
      );
    }
  }, [showModal]);

  let i;
  let optionsTahun: any = [];
  const year = new Date().getFullYear();
  for (i = year; i >= 2000; i--) {
    optionsTahun.push({
      value: i,
      label: `${i} / ${i + 1}`,
    });
  }

  useEffect(() => {
    if (
      formikRaportHasilKinerja?.getFieldProps("tahun").value &&
      formikRaportHasilKinerja?.getFieldProps("tw").value &&
      detailGTK?.nik_gtk
    ) {
      const tahun = formikRaportHasilKinerja?.getFieldProps("tahun").value;
      const tw = formikRaportHasilKinerja?.getFieldProps("tw").value;

      handleGetDetailRaportHasilKinerja(detailGTK.nik_gtk, tahun, tw);
    }
  }, [
    formikRaportHasilKinerja?.getFieldProps("tahun").value,
    formikRaportHasilKinerja?.getFieldProps("tw").value,
    detailGTK,
  ]);

  useEffect(() => {
    if (detailRaportHasilKinerja && detailRaportHasilKinerja.id) {
      formikRaportHasilKinerja?.setValues(detailRaportHasilKinerja);
    } else if (
      !detailRaportHasilKinerja ||
      (detailRaportHasilKinerja && !detailRaportHasilKinerja.id)
    ) {
      formikRaportHasilKinerja?.setFieldValue(
        "id",
        initialQueryResponse.detailRaportHasilKinerja.id
      );
      formikRaportHasilKinerja?.setFieldValue(
        "min_tingkat_kehadiran",
        initialQueryResponse.detailRaportHasilKinerja.min_tingkat_kehadiran
      );
      formikRaportHasilKinerja?.setFieldValue(
        "min_tingkat_ketepatan_hadir",
        initialQueryResponse.detailRaportHasilKinerja
          .min_tingkat_ketepatan_hadir
      );
      formikRaportHasilKinerja?.setFieldValue(
        "min_tingkat_kehadiran_lt3q",
        initialQueryResponse.detailRaportHasilKinerja.min_tingkat_kehadiran_lt3q
      );
      formikRaportHasilKinerja?.setFieldValue(
        "min_tingkat_kehadiran_bpi",
        initialQueryResponse.detailRaportHasilKinerja.min_tingkat_kehadiran_bpi
      );
      formikRaportHasilKinerja?.setFieldValue(
        "min_tingkat_kehadiran_emc",
        initialQueryResponse.detailRaportHasilKinerja.min_tingkat_kehadiran_emc
      );
      formikRaportHasilKinerja?.setFieldValue(
        "ket_tingkat_kehadiran",
        initialQueryResponse.detailRaportHasilKinerja.ket_tingkat_kehadiran
      );
      formikRaportHasilKinerja?.setFieldValue(
        "ket_tingkat_ketepatan_hadir",
        initialQueryResponse.detailRaportHasilKinerja
          .ket_tingkat_ketepatan_hadir
      );
      formikRaportHasilKinerja?.setFieldValue(
        "ket_tingkat_kehadiran_lt3q",
        initialQueryResponse.detailRaportHasilKinerja.ket_tingkat_kehadiran_lt3q
      );
      formikRaportHasilKinerja?.setFieldValue(
        "ket_tingkat_kehadiran_bpi",
        initialQueryResponse.detailRaportHasilKinerja.ket_tingkat_kehadiran_bpi
      );
      formikRaportHasilKinerja?.setFieldValue(
        "ket_tingkat_kehadiran_emc",
        initialQueryResponse.detailRaportHasilKinerja.ket_tingkat_kehadiran_emc
      );
      formikRaportHasilKinerja?.setFieldValue(
        "approval",
        initialQueryResponse.detailRaportHasilKinerja.approval
      );
      formikRaportHasilKinerja?.setFieldValue(
        "evaluasi_kualitatif",
        initialQueryResponse.detailRaportHasilKinerja.evaluasi_kualitatif
      );
      formikRaportHasilKinerja?.setFieldValue(
        "catatan",
        initialQueryResponse.detailRaportHasilKinerja.catatan
      );
      formikRaportHasilKinerja?.setFieldValue(
        "status_dokumen",
        initialQueryResponse.detailRaportHasilKinerja.status_dokumen
      );
    }
    formikRaportHasilKinerja?.setFieldValue(
      "total_kehadiran_gtk",
      detailRaportHasilKinerja.total_kehadiran_gtk
    );
    formikRaportHasilKinerja?.setFieldValue(
      "total_kehadiran_lt3q",
      detailRaportHasilKinerja.total_kehadiran_lt3q
    );
    formikRaportHasilKinerja?.setFieldValue(
      "total_kehadiran_bpi",
      detailRaportHasilKinerja.total_kehadiran_bpi
    );
    formikRaportHasilKinerja?.setFieldValue(
      "total_kehadiran_emc",
      detailRaportHasilKinerja.total_kehadiran_emc
    );
    formikRaportHasilKinerja?.setFieldValue(
      "total_ketepatan_hadir",
      detailRaportHasilKinerja.total_ketepatan_hadir
    );
    formikRaportHasilKinerja?.setFieldValue(
      "jumlah_hari_kerja",
      detailRaportHasilKinerja.jumlah_hari_kerja
    );
  }, [detailRaportHasilKinerja]);

  const nik_gtk: any = detailGTK?.nik_gtk?.toString();

  return (
    <>
      <Modal
        backdrop="static"
        show={showModal}
        size="xl"
        onHide={() => {
          setShowModal(false);
          setResetForm(true);
        }}
      >
        {(isLoadingGetDetailGTK ||
          isLoadingPostRaportHasilKinerja ||
          isLoadingExportPDFRaportHasilKinerja ||
          isLoadingApproveRaportHasilKinerja) && <FetchLoading />}
        <Form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Kelola Raport Hasil Kinerja</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <GTKCard data={detailGTK} />
            <Col lg="3">
              <Select2
                isRequired
                label="Tahun Pelajaran"
                options={optionsTahun}
                onChange={(e) => {
                  const value: any = e ? e["value"] : null;
                  formikRaportHasilKinerja?.setFieldValue("tahun", value);
                }}
                value={formikRaportHasilKinerja?.getFieldProps("tahun").value}
              />
            </Col>
            <hr className="bg-light" />
            {formikRaportHasilKinerja?.getFieldProps("tahun").value ? (
              <div>
                <div
                  className="d-flex flex-row w-100 justify-content-center overflow-auto"
                  style={{
                    paddingLeft: 150,
                  }}
                >
                  {tw.map((val, idx) => {
                    idx = idx + 1;
                    return (
                      <button
                        type="button"
                        className={` ${
                          activeTab === idx
                            ? "bg-primary text-white border border-primary"
                            : "bg-white text-dark border border-white"
                        } cursor-pointer btn-md me-2 py-2 px-4 rounded-pill border border-white`}
                        onClick={() => {
                          setActiveTab(idx);
                          formikRaportHasilKinerja?.setFieldValue("tw", idx);
                        }}
                      >
                        Triwulan {val}
                      </button>
                    );
                  })}
                </div>
                {isLoadingGetDetailRaportHasilKinerja ? (
                  <div className="p-4">
                    <FetchLoadingCompanyLogo />
                  </div>
                ) : (
                  <>
                    <div className="d-flex flex-row w-100 justify-content-between align-items-center mt-3">
                      <div>
                        {formikRaportHasilKinerja?.getFieldProps(
                          "status_dokumen"
                        ).value !== 0 && (
                          <button
                            type="button"
                            className="btn btn-sm btn-info"
                            onClick={() =>
                              handleExportPDFRaportHasilKinerja(
                                nik_gtk,
                                formikRaportHasilKinerja?.getFieldProps("tahun")
                                  .value,
                                formikRaportHasilKinerja?.getFieldProps("tw")
                                  .value
                              )
                            }
                          >
                            Download PDF
                            <i className="fa fa-download ms-2"></i>
                          </button>
                        )}
                      </div>
                      <div>
                        <label className="me-2 text-dark">
                          Status Raport :{" "}
                        </label>
                        {formikRaportHasilKinerja?.getFieldProps(
                          "status_dokumen"
                        ).value === 0 ? (
                          <label className="bg-danger text-white fs-6 p-1 px-3 rounded-3">
                            Belum Dibuat
                          </label>
                        ) : formikRaportHasilKinerja?.getFieldProps(
                            "status_dokumen"
                          ).value === 1 ? (
                          <label className="bg-warning text-white fs-6 p-1 px-3 rounded-3">
                            Menunggu Tanda Tangan
                          </label>
                        ) : (
                          <label className="bg-info text-white fs-6 p-1 px-3 rounded-3">
                            Selesai
                          </label>
                        )}
                      </div>
                    </div>
                    <EvaluasiKuantitatif />
                    <EvaluasiKualitatif />
                    <ApprovalRaportHasilKinerja />
                    <div className="px-3">
                      <TextareaComponent
                        rows={3}
                        label="Catatan"
                        placeholder="Masukkan Catatan"
                        formik={formikRaportHasilKinerja.getFieldProps(
                          "catatan"
                        )}
                        value={
                          formikRaportHasilKinerja.getFieldProps("catatan")
                            .value ?? ""
                        }
                        disabled={
                          formikRaportHasilKinerja?.getFieldProps(
                            "status_dokumen"
                          ).value === 2
                        }
                      />
                    </div>
                  </>
                )}
              </div>
            ) : (
              <div
                className="d-flex flex-row justify-content-center align-items-center h-full w-100"
                style={{
                  height: "10vh",
                }}
              >
                <label className="text-danger">
                  Harap Untuk Memilih Tahun Pelajaran Terlebih Dahulu
                </label>
              </div>
            )}
          </Modal.Body>
          {formikRaportHasilKinerja?.getFieldProps("tahun").value && (
            <Modal.Footer className="d-flex flex-row justify-content-between gap-2 align-items-center w-100">
              <div>
                {formikRaportHasilKinerja?.getFieldProps("status_dokumen")
                  .value > 0 && (
                  <CheckInputComponent
                    isRequired
                    style={{
                      maxWidth: "300px",
                    }}
                    label="Saya menyatakan bahwa dokumen sudah ditandatangani"
                    id={`status_dokumen${
                      formikRaportHasilKinerja?.getFieldProps("tw").value
                    }`}
                    disabled={
                      formikRaportHasilKinerja?.getFieldProps("status_dokumen")
                        .value === 2
                    }
                    onChange={(e) => {
                      const id: any = detailRaportHasilKinerja?.id;
                      SweetAlert({
                        icon: "info",
                        allowOutsideClick: false,
                        title: "Info",
                        html: "Apakah anda yakin ingin menyatakan dokumen ini sudah ditandatangani?",
                        confirmButtonText: "Lanjutkan",
                        cancelButtonText: "Batal",
                        isShowCancelButton: true,
                      }).then((res) => {
                        if (res.isConfirmed) {
                          handleApproveRaportHasilKinerja(id);
                        }
                      });
                    }}
                    checked={
                      formikRaportHasilKinerja?.getFieldProps("status_dokumen")
                        .value === 2
                    }
                  />
                )}
              </div>
              {formikRaportHasilKinerja?.getFieldProps("status_dokumen").value <
                2 && (
                <button
                  type="button"
                  className="btn btn-primary btn-sm"
                  onClick={() => {
                    setSubmitting(true);
                    formikRaportHasilKinerja?.submitForm();
                  }}
                >
                  Simpan <i className="fa fa-floppy-disk ms-2"></i>
                </button>
              )}
            </Modal.Footer>
          )}
        </Form>
      </Modal>
    </>
  );
};

export default ModalRaportHasilKinerja;
