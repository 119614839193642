import { FC, useEffect } from "react";
import { Button, ButtonGroup, Dropdown, Nav, Tab } from "react-bootstrap";
import { useQueryResponse } from "../../../../../../../provider/query-response/absensi-b-inggris/kelompok-b-inggris/kelola-kelompok";
import { SweetAlert } from "../../../../../../../hope-ui/components/sweet-alert";

type Props = {
  id: string;
  data?: any;
};

export const AksiCell: FC<Props> = ({ id, data }) => {
  const { handleGetDetailKelompokBInggris, handleDeleteKelompokBInggris } =
    useQueryResponse();
  return (
    <Dropdown as={ButtonGroup}>
      <Button type="button" size="sm" variant="info">
        Pilih Aksi
      </Button>
      <Dropdown.Toggle type="button" split variant="info">
        <span className="visually-hidden">Edit</span>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item
          href="#"
          onClick={(e) => {
            if (handleGetDetailKelompokBInggris)
              handleGetDetailKelompokBInggris(id);
          }}
        >
          Edit
        </Dropdown.Item>
        <Dropdown.Item
          href="#"
          onClick={(e) => {
            if (handleDeleteKelompokBInggris) handleDeleteKelompokBInggris(id);
          }}
        >
          Hapus
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};
