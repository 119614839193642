import { Accordion, Col, Row } from "react-bootstrap";
import Select2MultiSelect from "../../../../../hope-ui/components/select-2-multiselect";
import { SearchInputDT } from "../../../../../hope-ui/components/table/SearchInput";
import { ShowLimitDT } from "../../../../../hope-ui/components/table/ShowLimit";
import { useQueryRequest } from "../../../../../provider/query-request/absensi-b-inggris/daftar-absensi";
import Select2 from "../../../../../hope-ui/components/select-2";
import { useQueryResponse } from "../../../../../provider/query-response/absensi-b-inggris/daftar-absensi";
import { semuaBulan, semuaTahun } from "../../../../../helpers/helpers";
import { useQueryResponse as useQueryResponseAnggotaKelompokBInggris } from "../../../../../provider/query-response/absensi-b-inggris/kelompok-b-inggris/kelola-anggota-kelompok";
import { useAuth } from "../../../../../provider/core/auth";
import { useEffect } from "react";

const Toolbar = () => {
  const {
    handleGetAllKelompokBInggris,
    isLoadingGetAllKelompokBInggris,
    allKelompokBInggris,
  } = useQueryResponseAnggotaKelompokBInggris();

  let optionsKelompokBInggris: any = allKelompokBInggris.map((val) => {
    return {
      value: val.id,
      label: val.nama_kelompok_b_inggris,
    };
  });

  const optionsTahun = new semuaTahun();
  const { state, updateState } = useQueryRequest();
  const {
    setTahun,
    tahun,
    bulan,
    setBulan,
    idKelompokBInggris,
    setIdKelompokBInggris,
  } = useQueryResponse();

  const { currentUser } = useAuth();

  useEffect(() => {
    if (currentUser && currentUser.data && handleGetAllKelompokBInggris) {
      const nik_gtk: any = currentUser.data.username;
      handleGetAllKelompokBInggris(nik_gtk);
    }
  }, [currentUser, handleGetAllKelompokBInggris]);

  return (
    <>
      <Accordion className="col-12">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Filter Data</Accordion.Header>
          <Accordion.Body>
            <Row className="gy-3">
              <Col md="6" lg="3">
                <Select2
                  label="Bulan"
                  isClearable
                  options={semuaBulan}
                  onChange={(e) => {
                    const value: any = e ? e["value"] : null;
                    setBulan(value);
                    updateState({
                      bulan: value,
                    });
                  }}
                  value={bulan}
                />
              </Col>

              <Col md="6" lg="3">
                <Select2
                  label="Tahun"
                  isClearable
                  options={optionsTahun.data}
                  onChange={(e) => {
                    const value: any = e ? e["value"] : null;
                    setTahun(value);
                    updateState({
                      tahun: value,
                    });
                  }}
                  value={tahun}
                />
              </Col>

              <Col md="6">
                <Select2MultiSelect
                  label="Kelompok BInggris"
                  isMulti
                  isClearable
                  isLoading={isLoadingGetAllKelompokBInggris}
                  options={optionsKelompokBInggris}
                  onChange={(e: any) => {
                    let value: any = e ? e : null;
                    if (value) {
                      value = value.map((val) => {
                        return val.value;
                      });
                    }
                    setIdKelompokBInggris(e ? e : []);
                    updateState({
                      id_kelompok_lt3q: JSON.stringify(value),
                    });
                  }}
                  height={"fit-content"}
                  value={idKelompokBInggris}
                />
              </Col>
            </Row>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <div className="row pt-4 px-4 mb-0 pb-0">
        <div className="col-md-6 col-12">
          <ShowLimitDT
            onChange={(e) => updateState({ size: parseInt(e.target.value) })}
            limit={state.size}
          />
        </div>
        <div className="col-md-6 col-12 ">
          <SearchInputDT
            state={(keyword: string) => {
              updateState({ search: keyword });
            }}
            value={state.search ? state.search : ""}
          />
        </div>
      </div>
    </>
  );
};

export default Toolbar;
