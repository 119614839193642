import { Card, Col, Form } from "react-bootstrap";
import ModalDaftarDenda from "./modal/modal-daftar";
import {
  QueryResponseProvider,
  useQueryResponse,
} from "../../provider/query-response/denda-persensi-gtk/";
import { QueryRequestProvider as QueryRequestProviderProviderDendaPersensiGTK } from "../../provider/query-request/denda-persensi-gtk";
import { QueryResponseProvider as QueryResponseProviderDendaPersensiGTK } from "../../provider/query-response/denda-persensi-gtk";

import { Columns } from "./list/columns";
import Toolbar from "./list/toolbar";
import { DataTable } from "../../hope-ui/components/table/DataTable";
import { useQueryRequest } from "../../provider/query-request/filter-data";
import { useQueryResponsePagination } from "../../provider/query-response/denda-persensi-gtk";

const MasterDendaGTK = () => {
  const { listData, isLoadingFetchData } = useQueryResponse();

  return (
    <>
      <Card.Body className="pt-3">
        <ModalDaftarDenda />
        <Toolbar />

        <DataTable
          isLoading={isLoadingFetchData}
          queryRequest={useQueryRequest}
          pagination={useQueryResponsePagination}
          header={Columns}
          data={listData?.data}
        />
      </Card.Body>
    </>
  );
};

export const MasterDendaGTKWrapper = () => (
  <QueryResponseProvider>
    <QueryRequestProviderProviderDendaPersensiGTK>
      <QueryResponseProviderDendaPersensiGTK>
        <MasterDendaGTK />
      </QueryResponseProviderDendaPersensiGTK>
    </QueryRequestProviderProviderDendaPersensiGTK>
  </QueryResponseProvider>
);
