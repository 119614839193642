import { FC, createContext, useContext, useMemo, useState } from "react";
import { WithChildren } from "../../../helpers/react18MigrationHelpers";
import { initialQueryState } from "../../../models/global";

import { initialQueryResponse } from "../../../models/absensi-b-inggris/_queryResponse";

const QueryResponseContext = createContext(initialQueryResponse);

const QueryResponseProvider: FC<WithChildren> = ({ children }) => {
  const [isShowModalBuatAbsensi, setIsShowModalBuatAbsensi] =
    useState<boolean>(false);

  const [activeTab, setActiveTab] = useState<number>(1);
  const [isShowModalTentukanKelompok, setIsShowModalTentukanKelompok] =
    useState<boolean>(false);
  const value = useMemo(
    () => ({
      isShowModalTentukanKelompok,
      setIsShowModalTentukanKelompok,
      isShowModalBuatAbsensi,
      setIsShowModalBuatAbsensi,
      activeTab,
      setActiveTab,
    }),
    [
      isShowModalTentukanKelompok,
      setIsShowModalTentukanKelompok,
      isShowModalBuatAbsensi,
      setIsShowModalBuatAbsensi,
      activeTab,
      setActiveTab,
    ]
  );

  return (
    <QueryResponseContext.Provider value={value}>
      {children}
    </QueryResponseContext.Provider>
  );
};

const useQueryResponse = () => useContext(QueryResponseContext);

export { QueryResponseProvider, useQueryResponse };
