import { Col, Form, Modal, Row } from "react-bootstrap";
import { useQueryResponse } from "../../../provider/query-response/speaking-focus";
import TextInputComponent from "../../../hope-ui/components/text-input/TextInputComponent";
import { useEffect } from "react";
import FetchLoading from "../../../hope-ui/components/fetch-loading";

const ModalSpeakingFocus = () => {
  const {
    showModal,
    setShowModal,
    formikSpeakingFocus,
    submitting,
    setSubmitting,
    detailSpeakingFocus,
    setResetForm,
    isLoadingGetDetailSpeakingFocus,
    isLoadingPostSpeakingFocus,
    isLoadingDeleteSpeakingFocus,
  } = useQueryResponse();

  useEffect(() => {
    if (detailSpeakingFocus.id) {
      formikSpeakingFocus?.setValues(detailSpeakingFocus);
    }
  }, [detailSpeakingFocus]);

  return (
    <Modal
      backdrop="static"
      show={showModal}
      onBackdropClick={() => {
        setShowModal(false);
        setResetForm(true);
      }}
      size="lg"
      onHide={() => {
        setShowModal(false);
        setResetForm(true);
      }}
    >
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          setSubmitting(true);
          formikSpeakingFocus?.submitForm();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {detailSpeakingFocus.id ? "Edit" : "Tambah"} Unit Kerja
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {(isLoadingGetDetailSpeakingFocus ||
            isLoadingPostSpeakingFocus ||
            isLoadingDeleteSpeakingFocus) && <FetchLoading />}
          <Row className="gy-4">
            <Col lg="12">
              <TextInputComponent
                label="Speaking Focus"
                placeholder="Masukkan Speaking Focus"
                isRequired
                formik={formikSpeakingFocus?.getFieldProps("nama")}
                value={formikSpeakingFocus?.getFieldProps("nama").value ?? ""}
              />
              {submitting && formikSpeakingFocus?.errors.nama && (
                <div className="fs-6 text-danger mt-2">
                  {formikSpeakingFocus?.errors.nama}
                </div>
              )}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="border d-flex flex-row justify-content-end align-items-center py-3">
          <button type="submit" className="btn btn-sm btn-primary">
            <i className="far fa-floppy-disk me-2"></i>
            Simpan
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default ModalSpeakingFocus;
