import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  FormDaftarUnitKerjaPayload,
  initialQueryResponse,
} from "../../../models/daftar-unit-kerja/_queryResponse";
import { WithChildren } from "../../../helpers/react18MigrationHelpers";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { QUERIES } from "../../../helpers/crud-helpers/const";
import { SweetAlert } from "../../../hope-ui/components/sweet-alert";
import { stringifyRequestQuery } from "../../../helpers/helpers";
import { useQueryRequest } from "../../query-request/daftar-unit-kerja";
import {
  deleteDaftarUnitKerja,
  getDetailDaftarUnitKerja,
  getListDaftarUnitKerja,
  postDaftarUnitKerja,
} from "../../../api/daftar-unit-kerja/_request";
import { initialQueryState } from "../../../models/global";
import { useAuth } from "../../core/auth";
import { useFormik } from "formik";
import * as Yup from "yup";

const QueryResponseContext = createContext(initialQueryResponse);
const QueryResponseProvider: FC<WithChildren> = ({ children }) => {
  const { state } = useQueryRequest();
  const queryClient = useQueryClient();
  state.col_header = `["nama_unit_kerja"]`;

  const [showModal, setShowModal] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [resetForm, setResetForm] = useState<boolean>(false);

  const [query, setQuery] = useState<string>(
    stringifyRequestQuery(state, "default-query-nik-gtk")
  );
  const updatedQuery = useMemo(
    () => stringifyRequestQuery(state, "default-query-nik-gtk"),
    [state]
  );

  const [detailDaftarUnitKerja, setDetailDaftarUnitKerja] =
    useState<FormDaftarUnitKerjaPayload>(
      initialQueryResponse.detailDaftarUnitKerja
    );

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery);
    }
  }, [query, updatedQuery]);

  useEffect(() => {
    if (resetForm) {
      formikDaftarUnitKerja.setValues(
        initialQueryResponse.detailDaftarUnitKerja
      );
      setDetailDaftarUnitKerja(initialQueryResponse.detailDaftarUnitKerja);
      setSubmitting(false);
      setResetForm(false);
    }
  }, [resetForm]);

  const {
    data: listData,
    isFetching: isLoadingFetchData,
    refetch: refetchListData,
  } = useQuery(
    `${QUERIES.GET_LIST_DAFTAR_UNIT_KERJA}-${query}`,
    () => getListDaftarUnitKerja(query),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onError: (err) => {
        console.error(err);
      },
      enabled: query !== "?undefined",
    }
  );

  const formikDaftarUnitKerjaValidationSchema = Yup.object().shape({
    nama_unit_kerja: Yup.string().required(
      "Nama Unit Kerja Tidak Boleh Kosong"
    ),
  });

  const formikDaftarUnitKerja = useFormik<FormDaftarUnitKerjaPayload>({
    initialValues: initialQueryResponse.detailDaftarUnitKerja,
    enableReinitialize: false,
    validationSchema: formikDaftarUnitKerjaValidationSchema,
    onSubmit: (values: any) => {
      const payload = new FormData();
      Object.keys(values).map((key, idx) => {
        payload.append(key, values[key]);
      });
      handlePostDaftarUnitKerja(payload);
    },
  });

  const {
    mutateAsync: PostDaftarUnitKerja,
    isLoading: isLoadingPostDaftarUnitKerja,
  } = useMutation({
    mutationKey: `${QUERIES.POST_DAFTAR_UNIT_KERJA}`,
    mutationFn: ({ payload }: { payload: FormData }) =>
      postDaftarUnitKerja(payload),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([
          `${QUERIES.POST_DAFTAR_UNIT_KERJA}`,
        ]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handlePostDaftarUnitKerja = useCallback(
    async (payload: any) => {
      await PostDaftarUnitKerja({ payload })
        .then((res) => {
          SweetAlert({
            icon: res.success ? "success" : "warning",
            title: "Info",
            text: res.message,
            allowOutsideClick: false,
          }).then((swal: any) => {
            if (swal.isConfirmed && res.success) {
              if (refetchListData) {
                setShowModal(false);
                setResetForm(true);
                refetchListData();
              }
            }
          });
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [PostDaftarUnitKerja]
  );

  const {
    mutateAsync: GetDetailDaftarUnitKerja,
    isLoading: isLoadingGetDetailDaftarUnitKerja,
  } = useMutation({
    mutationKey: `${QUERIES.GET_DETAIL_DAFTAR_UNIT_KERJA}`,
    mutationFn: ({ id }: { id: string }) => getDetailDaftarUnitKerja(id),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([
          `${QUERIES.GET_DETAIL_DAFTAR_UNIT_KERJA}`,
        ]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleGetDetailDaftarUnitKerja = useCallback(
    async (id: string) => {
      await GetDetailDaftarUnitKerja({ id })
        .then((res) => {
          setDetailDaftarUnitKerja(res.data);
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [GetDetailDaftarUnitKerja]
  );

  const {
    mutateAsync: DeleteDaftarUnitKerja,
    isLoading: isLoadingDeleteDaftarUnitKerja,
  } = useMutation({
    mutationKey: `${QUERIES.DELETE_DAFTAR_UNIT_KERJA}`,
    mutationFn: ({ id }: { id: string }) => deleteDaftarUnitKerja(id),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([
          `${QUERIES.DELETE_DAFTAR_UNIT_KERJA}`,
        ]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleDeleteDaftarUnitKerja = useCallback(
    async (id: string) => {
      SweetAlert({
        icon: "warning",
        title: "Info",
        text: "Apakah Anda Yakin Ingin Menghapus Data?",
        allowOutsideClick: false,
        confirmButtonText: "Lanjutkan",
        cancelButtonText: "Batal",
        isShowCancelButton: true,
      }).then(async (swal: any) => {
        if (swal.isConfirmed) {
          await DeleteDaftarUnitKerja({ id })
            .then((res) => {
              SweetAlert({
                icon: res.success ? "success" : "warning",
                title: "Info",
                text: res.message,
                allowOutsideClick: false,
              }).then((swal: any) => {
                if (swal.isConfirmed) {
                  if (refetchListData) refetchListData();
                }
              });
            })
            .catch((error) => {
              SweetAlert({
                icon: "error",
                title: "Terjadi Kesalahan",
                text: error.data ? error.data.message : error,
                allowOutsideClick: false,
              }).then((res: any) => {
                if (res.isConfirmed) {
                }
              });
            });
        }
      });
    },
    [DeleteDaftarUnitKerja]
  );

  const value = useMemo(
    () => ({
      query,
      listData,
      isLoadingFetchData,
      refetchListData,
      showModal,
      setShowModal,
      formikDaftarUnitKerja,
      formikDaftarUnitKerjaValidationSchema,
      submitting,
      setSubmitting,
      handlePostDaftarUnitKerja,
      isLoadingPostDaftarUnitKerja,
      detailDaftarUnitKerja,
      setDetailDaftarUnitKerja,
      handleGetDetailDaftarUnitKerja,
      isLoadingGetDetailDaftarUnitKerja,
      resetForm,
      setResetForm,
      handleDeleteDaftarUnitKerja,
      isLoadingDeleteDaftarUnitKerja,
    }),
    [
      query,
      listData,
      isLoadingFetchData,
      refetchListData,
      showModal,
      setShowModal,
      formikDaftarUnitKerja,
      formikDaftarUnitKerjaValidationSchema,
      submitting,
      setSubmitting,
      handlePostDaftarUnitKerja,
      isLoadingPostDaftarUnitKerja,
      detailDaftarUnitKerja,
      setDetailDaftarUnitKerja,
      handleGetDetailDaftarUnitKerja,
      isLoadingGetDetailDaftarUnitKerja,
      resetForm,
      setResetForm,
      handleDeleteDaftarUnitKerja,
      isLoadingDeleteDaftarUnitKerja,
    ]
  );
  return (
    <QueryResponseContext.Provider value={value}>
      {children}
    </QueryResponseContext.Provider>
  );
};

const useQueryResponse = () => useContext(QueryResponseContext);
const useQueryResponsePagination = () => {
  const defaultPaginationState = {
    ...initialQueryState,
  };

  const { listData } = useQueryResponse();

  return listData ?? defaultPaginationState;
};
export { QueryResponseProvider, useQueryResponse, useQueryResponsePagination };
