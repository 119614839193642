import { Table } from "react-bootstrap";
import { useQueryResponse } from "../../../../provider/query-response/raport-hasil-kinerja";

const EvaluasiKuantitatifSaya = () => {
  const { detailRaportHasilKinerja } = useQueryResponse();
  const total_skor = detailRaportHasilKinerja?.total_skor ?? 0;
  return (
    <div>
      <h5>Evaluasi Kuantitatif</h5>
      <hr className="bg-light mb-0" />

      <Table responsive bordered className="fs-6 mt-3">
        <thead>
          <tr>
            <th className="text-center">No.</th>
            <th className="text-center">Alat Ukur</th>
            <th className="text-center">Target</th>
            <th className="text-center">Nilai</th>
            <th className="text-center">Skor</th>
            <th className="text-center">Ket.</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td style={{ minWidth: 250 }}>Tingkat Kehadiran</td>
            <td className="text-center">
              Min. {detailRaportHasilKinerja?.min_tingkat_kehadiran}%
            </td>
            <td className="text-center">
              {detailRaportHasilKinerja?.persentase_tingkat_kehadiran}%
            </td>
            <td className="text-center">
              {detailRaportHasilKinerja &&
              detailRaportHasilKinerja?.persentase_tingkat_kehadiran &&
              detailRaportHasilKinerja?.min_tingkat_kehadiran &&
              detailRaportHasilKinerja?.persentase_tingkat_kehadiran >
                detailRaportHasilKinerja?.min_tingkat_kehadiran
                ? 1
                : 0}
            </td>
            <td style={{ minWidth: 200 }}>
              {detailRaportHasilKinerja?.ket_tingkat_kehadiran}
            </td>
          </tr>

          <tr>
            <td>2</td>
            <td style={{ minWidth: 250 }}>Tingkat Ketepatan Hadir</td>
            <td className="text-center">
              Min. {detailRaportHasilKinerja?.min_tingkat_ketepatan_hadir}%
            </td>
            <td className="text-center">
              {detailRaportHasilKinerja?.persentase_tingkat_kehadiran}%
            </td>
            <td className="text-center">
              {detailRaportHasilKinerja &&
              detailRaportHasilKinerja?.persentase_tingkat_ketepatan_hadir &&
              detailRaportHasilKinerja?.min_tingkat_ketepatan_hadir &&
              detailRaportHasilKinerja?.persentase_tingkat_ketepatan_hadir >
                detailRaportHasilKinerja?.min_tingkat_ketepatan_hadir
                ? 1
                : 0}
            </td>
            <td style={{ minWidth: 200 }}>
              {detailRaportHasilKinerja?.ket_tingkat_ketepatan_hadir}
            </td>
          </tr>

          <tr>
            <td>3</td>
            <td style={{ minWidth: 250 }}>Tingkat Kehadiran TTQ</td>
            <td className="text-center">
              Min. {detailRaportHasilKinerja?.min_tingkat_kehadiran_lt3q}%
            </td>
            <td className="text-center">
              {detailRaportHasilKinerja?.persentase_tingkat_kehadiran_lt3q}%
            </td>
            <td className="text-center">
              {detailRaportHasilKinerja &&
              detailRaportHasilKinerja?.persentase_tingkat_kehadiran_lt3q &&
              detailRaportHasilKinerja?.min_tingkat_kehadiran_lt3q &&
              detailRaportHasilKinerja?.persentase_tingkat_kehadiran_lt3q >
                detailRaportHasilKinerja?.min_tingkat_kehadiran_lt3q
                ? 1
                : 0}
            </td>
            <td style={{ minWidth: 200 }}>
              {detailRaportHasilKinerja?.ket_tingkat_kehadiran_lt3q}
            </td>
          </tr>

          <tr>
            <td>4</td>
            <td style={{ minWidth: 250 }}>Tingkat Kehadiran BPI</td>
            <td className="text-center">
              Min. {detailRaportHasilKinerja?.min_tingkat_kehadiran_bpi}%
            </td>
            <td className="text-center">
              {detailRaportHasilKinerja?.persentase_tingkat_kehadiran_bpi}%
            </td>
            <td className="text-center">
              {detailRaportHasilKinerja &&
              detailRaportHasilKinerja?.persentase_tingkat_kehadiran_bpi &&
              detailRaportHasilKinerja?.min_tingkat_kehadiran_bpi &&
              detailRaportHasilKinerja?.persentase_tingkat_kehadiran_bpi >
                detailRaportHasilKinerja?.min_tingkat_kehadiran_bpi
                ? 1
                : 0}
            </td>
            <td style={{ minWidth: 200 }}>
              {detailRaportHasilKinerja?.ket_tingkat_kehadiran_bpi}
            </td>
          </tr>

          <tr>
            <td>5</td>
            <td style={{ minWidth: 250 }}>Tingkat Kehadiran EMC</td>
            <td className="text-center">
              Min. {detailRaportHasilKinerja?.min_tingkat_kehadiran_emc}%
            </td>
            <td className="text-center">
              {detailRaportHasilKinerja?.persentase_tingkat_kehadiran_emc}%
            </td>
            <td className="text-center">
              {detailRaportHasilKinerja &&
              detailRaportHasilKinerja?.persentase_tingkat_kehadiran_emc &&
              detailRaportHasilKinerja?.min_tingkat_kehadiran_emc &&
              detailRaportHasilKinerja?.persentase_tingkat_kehadiran_emc >
                detailRaportHasilKinerja?.min_tingkat_kehadiran_emc
                ? 1
                : 0}
            </td>
            <td style={{ minWidth: 200 }}>
              {detailRaportHasilKinerja?.ket_tingkat_kehadiran_emc}
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={3} rowSpan={3}></td>
          </tr>
          <tr>
            <th className="text-center">Jumlah</th>
            <td className="text-center">
              {detailRaportHasilKinerja?.total_skor}
            </td>
            <td rowSpan={3}></td>
          </tr>
          <tr>
            <th className="text-center">Persen</th>
            <td className="text-center">
              {((total_skor / 5) * 100).toFixed(2)}%
            </td>
          </tr>
        </tfoot>
      </Table>
    </div>
  );
};

export default EvaluasiKuantitatifSaya;
