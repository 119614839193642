import { Table } from "react-bootstrap";
import { useQueryResponse } from "../../../../provider/query-response/raport-hasil-kinerja";

const EvaluasiKualitatifSaya = () => {
  const { detailRaportHasilKinerja } = useQueryResponse();
  return (
    <>
      <h5>Evaluasi Kualitatif</h5>
      <hr className="bg-light mb-0" />
      <Table responsive bordered className="fs-6 mt-3">
        <thead>
          <tr>
            <th rowSpan={2} className="text-center">
              No.
            </th>
            <th rowSpan={2} className="text-center">
              Alat Ukur
            </th>
            <th colSpan={5} className="text-center">
              Kriteria
            </th>
            <th rowSpan={2} className="text-center">
              Ket
            </th>
          </tr>
          <tr>
            <th className="text-center">SB</th>
            <th className="text-center">B</th>
            <th className="text-center">C</th>
            <th className="text-center">K</th>
            <th className="text-center">KS</th>
          </tr>
        </thead>
        <tbody>
          {detailRaportHasilKinerja?.evaluasi_kualitatif.map((val, idx) => {
            return (
              <tr>
                <td>{idx + 1}</td>
                <td>{val.alat_ukur}</td>
                <td>{val.SB}</td>
                <td>{val.B}</td>
                <td>{val.C}</td>
                <td>{val.K}</td>
                <td>{val.KS}</td>
                <td style={{ minWidth: 200 }}>{val.keterangan}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};

export default EvaluasiKualitatifSaya;
