import { FC, useEffect } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import Select2 from "../../../../../hope-ui/components/select-2";
import TextareaComponent from "../../../../../hope-ui/components/textarea-input";
import { useQueryResponse as useQueryResponseKelompokLT3Q } from "../../../../../provider/query-response/absensi-lt3q/kelompok-lt3q/kelola-kelompok";
import { useQueryResponse as useQueryResponseAnggotaKelompokLT3Q } from "../../../../../provider/query-response/absensi-lt3q/kelompok-lt3q/kelola-anggota-kelompok";
import { useAuth } from "../../../../../provider/core/auth";
import {
  useQueryResponse,
  useQueryResponsePagination,
} from "../../../../../provider/query-response/absensi-lt3q/kelompok-lt3q/pindah-tutor";
import { useQueryRequest } from "../../../../../provider/query-request/absensi-lt3q/kelompok-lt3q/pindah-tutor";
import { DataTable } from "../../../../../hope-ui/components/table/DataTable";
import { Columns } from "./list/columns/column";
import Toolbar from "./toolbar";
import FetchLoading from "../../../../../hope-ui/components/fetch-loading";

type Props = {
  activeTab: number;
};

const PindahTutor: FC<Props> = ({ activeTab }) => {
  const { currentUser } = useAuth();
  const {
    isLoadingPostPindahTutorLT3Q,
    formikPindahTutorLT3Q,
    setSubmitting,
    submitting,
    handleGetTutorLT3Q,
    isLoadingGetTutorLT3Q,
    allTutorLT3Q,
    allKelompokTutorLT3QTujuan,
    setAllKelompokTutorLT3QTujuan,
    handleGetAllKelompokLT3QTujuan,
    isLoadingGetAllKelompokLT3QTujuan,
    isLoadingFetchData,
    listData,
  } = useQueryResponse();

  const {
    allKelompokLT3Q,
    handleGetAllKelompokLT3Q,
    isLoadingGetAllKelompokLT3Q,
    handleGetAnggotaKelompokLT3Q,
    isLoadingGetAnggotaKelompokLT3Q,
    anggotaKelompokLT3Q,
  } = useQueryResponseAnggotaKelompokLT3Q();

  useEffect(() => {
    if (
      currentUser &&
      currentUser.data &&
      activeTab === 3 &&
      handleGetAllKelompokLT3Q &&
      handleGetTutorLT3Q
    ) {
      const nik: any = currentUser.data.username;
      handleGetAllKelompokLT3Q(nik);
      handleGetTutorLT3Q();
    }
  }, [activeTab, handleGetAllKelompokLT3Q, handleGetTutorLT3Q, currentUser]);

  let optionsKelompokLT3Q: any = allKelompokLT3Q.map((val) => {
    return {
      value: val.id,
      label: val.nama_kelompok_lt3q,
    };
  });

  let optionsAnggotaKelompokLT3Q: any = anggotaKelompokLT3Q?.map((val) => {
    return {
      value: val.id,
      label: `${val.nik_gtk} - ${val.gtk.nama_gtk}`,
    };
  });

  let optionsTutorLT3Q: any = allTutorLT3Q.map((val) => {
    return {
      value: val.nik_gtk,
      label: `${val.nik_gtk} - ${val.nama_gtk}`,
    };
  });

  let optionsKelompokTutorLT3QTujuan: any = allKelompokTutorLT3QTujuan.map(
    (val) => {
      return {
        value: val.id,
        label: `${val.nama_kelompok_lt3q}`,
      };
    }
  );

  return (
    <Col
      style={{
        display: activeTab === 3 ? "block" : "none",
      }}
      className="p-4"
    >
      {isLoadingPostPindahTutorLT3Q && <FetchLoading />}
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          setSubmitting(true);
          formikPindahTutorLT3Q?.submitForm();
        }}
      >
        <Row className="gy-4 mb-4">
          <h6>Pilih Anggota Kelompok</h6>
          <hr className="bg-light mb-0" />
          <Col lg="6">
            <Form.Group>
              <Select2
                isRequired
                label="Kelompok TTQ"
                placeholder="Pilih Kelompok TTQ"
                options={optionsKelompokLT3Q}
                isLoading={isLoadingGetAllKelompokLT3Q}
                onChange={(e: any) => {
                  const id_kelompok_lt3q = e?.value;
                  if (handleGetAnggotaKelompokLT3Q) {
                    handleGetAnggotaKelompokLT3Q(id_kelompok_lt3q);
                    formikPindahTutorLT3Q?.setFieldValue(
                      "id_anggota_kelompok_lt3q",
                      null
                    );
                  }
                  formikPindahTutorLT3Q?.setFieldValue(
                    "id_kelompok_lt3q",
                    id_kelompok_lt3q
                  );
                }}
                value={
                  formikPindahTutorLT3Q?.getFieldProps("id_kelompok_lt3q")
                    .value ?? null
                }
                isClearable
              />
              {submitting && formikPindahTutorLT3Q?.errors.id_kelompok_lt3q && (
                <div className="text-danger mt-2">
                  {formikPindahTutorLT3Q?.errors.id_kelompok_lt3q}
                </div>
              )}
            </Form.Group>
          </Col>

          <Col lg="6">
            <Form.Group>
              <Select2
                label="Anggota Kelompok TTQ"
                placeholder="Pilih Anggota Kelompok TTQ"
                isRequired
                isLoading={isLoadingGetAnggotaKelompokLT3Q}
                options={optionsAnggotaKelompokLT3Q}
                onChange={(e: any) => {
                  const id_anggota_kelompok_lt3q: any = e?.value;
                  formikPindahTutorLT3Q?.setFieldValue(
                    "id_anggota_kelompok_lt3q",
                    id_anggota_kelompok_lt3q
                  );
                }}
                value={
                  formikPindahTutorLT3Q?.getFieldProps(
                    "id_anggota_kelompok_lt3q"
                  ).value ?? null
                }
              />
              {submitting &&
                formikPindahTutorLT3Q?.errors.id_anggota_kelompok_lt3q && (
                  <div className="text-danger mt-2">
                    {formikPindahTutorLT3Q?.errors.id_anggota_kelompok_lt3q}
                  </div>
                )}
            </Form.Group>
          </Col>

          <h6 className="mt-5">Tutor & Kelompok TTQ Tujuan</h6>
          <hr className="bg-light mb-0" />

          <Col lg="6">
            <Form.Group>
              <Select2
                label="Tutor LT3Q Tujuan"
                placeholder="Pilih Tutor LT3Q Tujuan"
                isRequired
                isLoading={isLoadingGetTutorLT3Q}
                onChange={(e: any) => {
                  const nik_gtk = e?.value;
                  if (handleGetAllKelompokLT3QTujuan) {
                    handleGetAllKelompokLT3QTujuan(nik_gtk, "tujuan");
                  }
                  formikPindahTutorLT3Q?.setFieldValue(
                    "nik_tutor_lt3q_tujuan",
                    nik_gtk
                  );
                }}
                value={
                  formikPindahTutorLT3Q?.getFieldProps("nik_tutor_lt3q_tujuan")
                    .value ?? null
                }
                options={optionsTutorLT3Q}
              />
            </Form.Group>
            {submitting &&
              formikPindahTutorLT3Q?.errors.nik_tutor_lt3q_tujuan && (
                <div className="text-danger mt-2">
                  {formikPindahTutorLT3Q?.errors.nik_tutor_lt3q_tujuan}
                </div>
              )}
          </Col>

          <Col lg="6">
            <Form.Group>
              <Select2
                label="Kelompok TTQ Tujuan"
                placeholder="Pilih Kelompok TTQ Tujuan"
                isRequired
                options={optionsKelompokTutorLT3QTujuan}
                isLoading={isLoadingGetAllKelompokLT3QTujuan}
                onChange={(e: any) => {
                  const id_kelompok_lt3q_tujuan: any = e?.value;
                  formikPindahTutorLT3Q?.setFieldValue(
                    "id_kelompok_lt3q_tujuan",
                    id_kelompok_lt3q_tujuan
                  );
                }}
                value={
                  formikPindahTutorLT3Q?.getFieldProps(
                    "id_kelompok_lt3q_tujuan"
                  ).value ?? null
                }
              />
            </Form.Group>
            {submitting &&
              formikPindahTutorLT3Q?.errors.id_kelompok_lt3q_tujuan && (
                <div className="text-danger mt-2">
                  {formikPindahTutorLT3Q?.errors.id_kelompok_lt3q_tujuan}
                </div>
              )}
          </Col>

          <Col lg="12">
            <Form.Group>
              <TextareaComponent
                label="Keterangan"
                placeholder="Masukkan Keterangan"
                rows={3}
                value={
                  formikPindahTutorLT3Q?.getFieldProps(`keterangan`).value ?? ""
                }
                formik={formikPindahTutorLT3Q?.getFieldProps(`keterangan`)}
              />
            </Form.Group>
          </Col>
        </Row>

        <Card.Footer className={`p-3 d-flex flex-row justify-content-end`}>
          <button type="submit" className={`btn btn-primary btn-sm `}>
            <i className={`far fa-floppy-disk me-2`}></i>
            Simpan
          </button>
        </Card.Footer>

        <Col className="mt-4">
          <h6>History Pindah Tutor LT3Q</h6>
          <hr className="bg-light" />
          <Toolbar />
          <DataTable
            isLoading={isLoadingFetchData}
            data={listData?.data}
            header={Columns}
            pagination={useQueryResponsePagination}
            queryRequest={useQueryRequest}
          />
        </Col>
      </Form>
    </Col>
  );
};

export default PindahTutor;
