import { Table } from "react-bootstrap";
import { useQueryResponse } from "../../../../provider/query-response/raport-hasil-kinerja";
import NumberInputComponent from "../../../../hope-ui/components/number-input";
import TextareaComponent from "../../../../hope-ui/components/textarea-input";

const EvaluasiKualitatif = () => {
  const { formikRaportHasilKinerja, submitting } = useQueryResponse();
  let index0, index1;
  let label1: any =
    formikRaportHasilKinerja?.getFieldProps(`evaluasi_kualitatif`).value;
  if (typeof label1 === "object") {
    label1 = label1?.find(
      (e) => e.alat_ukur === "Supervisi penerapan metode pembelajaran terpadu"
    );

    index0 = formikRaportHasilKinerja
      ?.getFieldProps(`evaluasi_kualitatif`)
      .value?.findIndex(
        (e) => e.alat_ukur === "Supervisi penerapan metode pembelajaran terpadu"
      );
  }
  let label2 =
    formikRaportHasilKinerja?.getFieldProps(`evaluasi_kualitatif`).value;
  if (typeof label2 === "object") {
    label2 = label2?.find(
      (e) => e.alat_ukur === "Pelaksanaan tugas-tugas dan laporan"
    );
    index1 = formikRaportHasilKinerja
      ?.getFieldProps(`evaluasi_kualitatif`)
      .value?.findIndex(
        (e) => e.alat_ukur === "Pelaksanaan tugas-tugas dan laporan"
      );
  }

  return (
    <div className="p-3">
      <h5>Evaluasi Kualitatif</h5>
      <hr className="bg-light mb-0" />
      <Table responsive bordered className="fs-6 mt-3">
        <thead>
          <tr>
            <th rowSpan={2} className="text-center">
              No.
            </th>
            <th rowSpan={2} className="text-center">
              Alat Ukur
            </th>
            <th colSpan={5} className="text-center">
              Kriteria
            </th>
            <th rowSpan={2} className="text-center">
              Ket
            </th>
          </tr>
          <tr>
            <th className="text-center">SB</th>
            <th className="text-center">B</th>
            <th className="text-center">C</th>
            <th className="text-center">K</th>
            <th className="text-center">KS</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>{label1?.alat_ukur}</td>
            <td style={{ minWidth: 200 }}>
              <NumberInputComponent
                placeholder="Masukkan SB"
                required
                formik={formikRaportHasilKinerja?.getFieldProps(
                  `evaluasi_kualitatif[${index0}].SB`
                )}
                value={
                  formikRaportHasilKinerja?.getFieldProps(
                    `evaluasi_kualitatif[${index0}].SB`
                  ).value ?? ""
                }
                disabled={
                  formikRaportHasilKinerja?.getFieldProps("status_dokumen")
                    .value === 2
                }
              />
            </td>
            <td style={{ minWidth: 200 }}>
              <NumberInputComponent
                placeholder="Masukkan B"
                required
                formik={formikRaportHasilKinerja?.getFieldProps(
                  `evaluasi_kualitatif[${index0}].B`
                )}
                disabled={
                  formikRaportHasilKinerja?.getFieldProps("status_dokumen")
                    .value === 2
                }
              />
            </td>
            <td style={{ minWidth: 200 }}>
              <NumberInputComponent
                placeholder="Masukkan C"
                required
                formik={formikRaportHasilKinerja?.getFieldProps(
                  `evaluasi_kualitatif[${index0}].C`
                )}
                disabled={
                  formikRaportHasilKinerja?.getFieldProps("status_dokumen")
                    .value === 2
                }
              />
            </td>
            <td style={{ minWidth: 200 }}>
              <NumberInputComponent
                placeholder="Masukkan K"
                required
                formik={formikRaportHasilKinerja?.getFieldProps(
                  `evaluasi_kualitatif[${index0}].K`
                )}
                disabled={
                  formikRaportHasilKinerja?.getFieldProps("status_dokumen")
                    .value === 2
                }
              />
            </td>
            <td style={{ minWidth: 200 }}>
              <NumberInputComponent
                placeholder="Masukkan KS"
                required
                formik={formikRaportHasilKinerja?.getFieldProps(
                  `evaluasi_kualitatif[${index0}].KS`
                )}
                disabled={
                  formikRaportHasilKinerja?.getFieldProps("status_dokumen")
                    .value === 2
                }
              />
            </td>

            <td style={{ minWidth: 300 }}>
              <TextareaComponent
                placeholder="Masukkan Ket"
                required
                formik={formikRaportHasilKinerja?.getFieldProps(
                  `evaluasi_kualitatif[${index0}].keterangan`
                )}
                value={
                  formikRaportHasilKinerja?.getFieldProps(
                    `evaluasi_kualitatif[${index0}].keterangan`
                  ).value ?? ""
                }
                disabled={
                  formikRaportHasilKinerja?.getFieldProps("status_dokumen")
                    .value === 2
                }
              />
            </td>
          </tr>
          <tr>
            <td>2</td>
            <td>{label2?.alat_ukur}</td>
            <td style={{ minWidth: 200 }}>
              <NumberInputComponent
                placeholder="Masukkan SB"
                required
                formik={formikRaportHasilKinerja?.getFieldProps(
                  `evaluasi_kualitatif[${index1}].SB`
                )}
                disabled={
                  formikRaportHasilKinerja?.getFieldProps("status_dokumen")
                    .value === 2
                }
              />
            </td>
            <td style={{ minWidth: 200 }}>
              <NumberInputComponent
                placeholder="Masukkan B"
                required
                formik={formikRaportHasilKinerja?.getFieldProps(
                  `evaluasi_kualitatif[${index1}].B`
                )}
                disabled={
                  formikRaportHasilKinerja?.getFieldProps("status_dokumen")
                    .value === 2
                }
              />
            </td>
            <td style={{ minWidth: 200 }}>
              <NumberInputComponent
                placeholder="Masukkan C"
                required
                formik={formikRaportHasilKinerja?.getFieldProps(
                  `evaluasi_kualitatif[${index1}].C`
                )}
                disabled={
                  formikRaportHasilKinerja?.getFieldProps("status_dokumen")
                    .value === 2
                }
              />
            </td>
            <td style={{ minWidth: 200 }}>
              <NumberInputComponent
                placeholder="Masukkan K"
                required
                formik={formikRaportHasilKinerja?.getFieldProps(
                  `evaluasi_kualitatif[${index1}].K`
                )}
                disabled={
                  formikRaportHasilKinerja?.getFieldProps("status_dokumen")
                    .value === 2
                }
              />
            </td>
            <td style={{ minWidth: 200 }}>
              <NumberInputComponent
                placeholder="Masukkan KS"
                required
                formik={formikRaportHasilKinerja?.getFieldProps(
                  `evaluasi_kualitatif[${index1}].KS`
                )}
                disabled={
                  formikRaportHasilKinerja?.getFieldProps("status_dokumen")
                    .value === 2
                }
              />
            </td>

            <td style={{ minWidth: 300 }}>
              <TextareaComponent
                placeholder="Masukkan Ket"
                required
                formik={formikRaportHasilKinerja?.getFieldProps(
                  `evaluasi_kualitatif[${index1}].keterangan`
                )}
                value={
                  formikRaportHasilKinerja?.getFieldProps(
                    `evaluasi_kualitatif[${index1}].keterangan`
                  ).value ?? ""
                }
                disabled={
                  formikRaportHasilKinerja?.getFieldProps("status_dokumen")
                    .value === 2
                }
              />
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={8}>
              Rentang Nilai : A = 90 - 100, B = 80 - 89, C = 70 - 79, D = 60 -
              69, E {"<"} 59{" "}
            </td>
          </tr>
        </tfoot>
      </Table>
    </div>
  );
};

export default EvaluasiKualitatif;
