import { Form } from "react-bootstrap";
import React, { ChangeEventHandler, FC } from "react";
import { FieldInputProps } from "formik";

type Props = {
  id?: string;
  label?: string;
  type?: string;
  disabled?: boolean;
  formik?: FieldInputProps<any>;
  className?: string;
  isRequired?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  labelClassName?: string;
  checked?: boolean;
  style?: React.CSSProperties;
};
const CheckInputComponent: FC<Props> = ({
  id = "",
  label = null,
  type = "checkbox",
  className = "",
  isRequired = false,
  formik,
  onChange,
  labelClassName,
  checked,
  style = {
    maxWidth: "100%",
  },
  disabled,
}) => {
  return (
    <Form.Check
      className={
        className && className.length === 0
          ? "form-check mb-3"
          : "form-check mb-3" + className
      }
    >
      <Form.Check.Input
        id={id}
        {...formik}
        onChange={onChange}
        checked={checked}
        disabled={disabled}
      />
      <Form.Check.Label htmlFor={id} className={labelClassName} style={style}>
        {label}
      </Form.Check.Label>
    </Form.Check>
  );
};

export default CheckInputComponent;
